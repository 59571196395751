import { isNil, isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import { ToggleVisibilityContainer } from 'common/components/container';
import { Box } from 'common/components/material';
import { getErrorsPerFormField, useSnackbar, useView } from 'common/hooks';
import { StepElementProps } from 'features/create-profile/types/step-element-props.type';
import { OrganizationRoleStep } from './organization-role-step.component';
import { useState } from 'react';
import {
  OrgStepBackButton,
  OrgStepSaveButton,
} from '../styles/organization-role-step-container.styles';

const OrganizationRoleStepContainer: React.FC<StepElementProps> = ({
  step,
  currentStepNumber = 1,
  showBackButton = true,
  disableButton = false,
  back,
  next,
}) => {
  const { palette } = useTheme();
  const { openSnackbar } = useSnackbar();
  const { isMobileView } = useView();
  const latestRoleValueFieldPrefix = 'latest';
  const latestRoleFieldNames = step?.fields?.map(
    (field) => `${latestRoleValueFieldPrefix}${field}`,
  );
  const [requiredTouchedFields, setRequiredTouchedFields] = useState<string[]>([
    ...(step?.requiredTouchedFields || []),
  ]);
  const {
    getValues,
    setValue,
    formState: { dirtyFields, errors },
    trigger,
    clearErrors,
  } = useFormContext();

  const alreadyHasRole = !isEmpty(getValues('organizationRoleId'));

  const [hasNewRole, setHasNewRole] = useState<boolean>(
    alreadyHasRole && !isEmpty(getValues('latestjobTitle')),
  );

  const hasUntouchedFields = requiredTouchedFields.some(
    (field) =>
      (isNil(dirtyFields[field]) || !dirtyFields[field]) && isEmpty(getValues(field)),
  );
  const fieldErrors = getErrorsPerFormField(errors);
  const hasErrors = !!Object.keys(fieldErrors).length;

  const orgRole = <OrganizationRoleStep hidden={hasNewRole} />;
  const latestOrgRole = (
    <OrganizationRoleStep valueFieldPrefix={latestRoleValueFieldPrefix} />
  );

  const goBack = () => {
    if (hasNewRole) {
      setHasNewRole(false);
      setValue('hasNewRole', false);
      setRequiredTouchedFields([...(step?.requiredTouchedFields || [])]);
      latestRoleFieldNames?.forEach((field) => {
        clearErrors(field);
      });
      return;
    }
    back();
  };

  const goNext = async () => {
    let isValid = await trigger(step?.fields);

    if (!hasNewRole) {
      latestRoleFieldNames?.forEach((field) => {
        setValue(field, null);
      });
      setValue('hasNewRole', false);
    } else {
      if (isValid) {
        isValid = await trigger(latestRoleFieldNames);
      }
    }

    if (isValid) {
      next();
    }
  };

  const saveAndAddNew = async () => {
    let isValid = await trigger(step?.fields);
    if (!isValid) {
      return;
    }

    setHasNewRole(true);
    setValue('hasNewRole', true);

    if (requiredTouchedFields.length) {
      const fields = [];
      for (const field of requiredTouchedFields) {
        fields.push(field);
        fields.push(`${latestRoleValueFieldPrefix}${field}`);
      }
      setRequiredTouchedFields(fields);
    }
    openSnackbar('Role has been saved.', 'success');
  };

  const showSaveAndAddButton = alreadyHasRole && !hasNewRole;

  return (
    <>
      <Box>
        {orgRole}
        {hasNewRole && latestOrgRole}
      </Box>

      <Box
        position={{ xs: 'fixed', lg: 'static' }}
        bottom="0"
        left="0"
        display="flex"
        justifyContent="space-between"
        maxWidth="lg"
        padding={{ xs: '1.5rem', lg: 0 }}
        width="100%"
        sx={{ backgroundColor: { xs: palette.Container.Bg, lg: 'none' } }}
        zIndex={10}
      >
        <ToggleVisibilityContainer isVisible={showBackButton && currentStepNumber !== 1}>
          <OrgStepBackButton onClick={goBack} size={isMobileView ? 'small' : undefined}>
            Back
          </OrgStepBackButton>
        </ToggleVisibilityContainer>
        <Box sx={{ marginBottom: 1 }} display="flex" justifyContent="right" maxWidth="lg">
          {showSaveAndAddButton && (
            <OrgStepSaveButton
              btntype="secondary"
              disabled={disableButton || hasUntouchedFields || hasErrors}
              onClick={saveAndAddNew}
              sx={{ marginRight: 1 }}
              size={isMobileView ? 'small' : undefined}
            >
              Save & add new
            </OrgStepSaveButton>
          )}
          <OrgStepSaveButton
            btntype="primary"
            type="submit"
            disabled={disableButton || hasUntouchedFields || hasErrors}
            onClick={goNext}
            size={isMobileView ? 'small' : undefined}
          >
            {!alreadyHasRole || hasNewRole
              ? isEmpty(step.buttonLabel)
                ? 'Next'
                : step.buttonLabel
              : 'Looks good'}
          </OrgStepSaveButton>
        </Box>
      </Box>
    </>
  );
};

export default OrganizationRoleStepContainer;
