import React from 'react';
import TabScrollButton, {
  TabScrollButtonProps,
} from '@mui/material/TabScrollButton/TabScrollButton';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const CXTabScrollButton: React.FC<TabScrollButtonProps> = (props) => {
  return (
    <TabScrollButton
      {...props}
      slots={{
        StartScrollButtonIcon: ChevronLeftRoundedIcon,
        EndScrollButtonIcon: ChevronRightRoundedIcon,
      }}
    />
  );
};

export default CXTabScrollButton;
