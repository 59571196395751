import { get } from 'lodash';

import queryClient from 'common/utils/QueryClient';
import { useMutation, useParamsProfileId, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updatePriorExperienceApi } from '../prior-experience.service';
import { useGetPriorExperience } from './useGetPriorExperience';
import { PriorExperienceForm, UpdatePriorExperienceRequest } from '../types';

export const useUpdatePriorExperience = (options: UseMutationOptions = {}) => {
  const { profileId } = useParamsProfileId();
  const { account: user } = useSession();
  const { refetch: refetchPriorExperience } = useGetPriorExperience(
    profileId,
    user.organizationId,
  );

  const mutationResponse = useMutation(updatePriorExperienceApi, {
    showSuccessSnackbar: true,
    transformInput: (priorExperienceForm: PriorExperienceForm) => {
      const id = get(priorExperienceForm, 'id', '');
      const companyNameId = get(priorExperienceForm, 'companyNameId', null);
      const rawTitle = get(priorExperienceForm, 'companyNameTitle', '');
      const companyNameTitle = companyNameId ? rawTitle : rawTitle?.split('"')[1];

      const body: UpdatePriorExperienceRequest = { id, companyNameTitle };
      if (companyNameId) body.companyNameId = companyNameId;

      return body;
    },
    onSuccess: () => {
      refetchPriorExperience();
      queryClient.invalidateQueries([user?.organizationId, 'company-names']);
    },
    ...options,
  });
  return mutationResponse;
};
