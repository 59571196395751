import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Card, Stack } from 'common/components/material';
import { NewsFeedCardContentSkeleton } from './news-feed-card-content-skeleton.component';

export const SingleNewsFeedCardSkeleton: React.FC = () => {
  const { palette } = useTheme();

  return (
    <Card
      noElevation={true}
      sx={{
        borderRadius: '0.75rem',
        backgroundColor: palette.Background,
        height: '21rem',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        mb: 2,
      }}
    >
      <Stack gap={1}>
        <NewsFeedCardContentSkeleton />
      </Stack>
    </Card>
  );
};
