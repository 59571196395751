import React from 'react';

import { FormTextField } from 'common/components/input';
import { StepContainer } from 'common/components/container';

export const FullNameStep: React.FC = () => {
  return (
    <StepContainer
      heading={<>"Let's create your profile"</>}
      subheading="Enter your name to get started"
    >
      <FormTextField autoFocus name="firstName" label="First Name" />
      <FormTextField name="lastName" label="Last Name" />
    </StepContainer>
  );
};
