import { styled } from '@mui/material';
import { Tab } from 'libs/mui/tab';
import { Tabs } from 'libs/mui/tabs';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => {
    return prop !== 'additionalStyle';
  },
})<{ additionalStyle?: Object }>(({ theme, additionalStyle }) => ({
  backgroundColor: theme.palette.Tabs.Secondary.BgDefault,
  overflow: 'visible',
  padding: theme.spacing(1),
  boxShadow: AppBoxShadows.shadow1,
  borderRadius: '0.75rem',
  border: `1px solid ${theme.palette.Tabs.Secondary.ContainerStroke}`,
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-scrollButtons': {
    width: 'auto',
    color: theme.palette.Icons.Secondary.IconFill,
  },
  '& .MuiTabs-scrollButtons .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
  '&:not(.full-width)': {
    width: 'fit-content',
  },
  [theme.breakpoints.down('md')]: {
    '&.full-width': {
      borderRadius: 'unset',
      borderLeft: `none`,
      borderRight: `none`,
    },
  },
  ...additionalStyle,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 'auto',
  marginRight: theme.spacing(1),
  borderRadius: '8px',
  color: theme.palette.Tabs.Secondary.LabelDefault,
  padding: '8px',
  '&.MuiButtonBase-root:last-child': {
    margin: '0',
  },
  '&:hover': {
    backgroundColor: theme.palette.Tabs.Secondary.BgHover,
    color: theme.palette.Tabs.Secondary.LabelHover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.Tabs.Secondary.BgClicked,
    color: theme.palette.Tabs.Secondary.LabelClicked,
  },
  '&.Mui-disabled': {
    color: theme.palette.Buttons.Secondary.LabelDisabled,
  },
}));

export const NoBackgroundTabs = styled(Tabs, {
  shouldForwardProp: (prop) => {
    return prop !== 'additionalStyle';
  },
})<{ additionalStyle?: Object }>(({ theme, additionalStyle }) => ({
  overflow: 'visible',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-scrollButtons': {
    width: 'auto',
    color: theme.palette.Icons.Secondary.IconFill,
  },
  '& .MuiTabs-scrollButtons .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
  '&:not(.full-width)': {
    width: 'fit-content',
  },
  [theme.breakpoints.down('md')]: {
    '&.full-width': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  ...additionalStyle,
}));

export const NoBackgroundTab = styled(Tab)(({ theme }) => ({
  minHeight: 'auto',
  marginRight: theme.spacing(1),
  padding: '0.5rem',
  paddingBlock: '0.25rem',
  borderRadius: '0.375rem',
  paddingInline: '0.5rem',
  backgroundColor: theme.palette.Tabs.Primary.BgDefault,
  border: `1px solid ${theme.palette.Tabs.Primary.StrokeDefault}`,
  color: theme.palette.Tabs.Primary.LabelAndIconDefault,

  '&.MuiButtonBase-root:last-child': {
    margin: '0',
  },
  '&:hover': {
    color: theme.palette.Tabs.Primary.LabelAndIconHover,
    borderColor: theme.palette.Tabs.Primary.StrokeHover,
    boxShadow: AppBoxShadows.shadow1,
  },
  '&.Mui-selected': {
    color: theme.palette.Tabs.Primary.LabelAndIconClicked,
    border: `2px solid ${theme.palette.Tabs.Primary.LabelAndIconClicked}`,
    backgroundColor: theme.palette.Tabs.Primary.BgActive,
    boxShadow: AppBoxShadows.shadow1,
  },
  '&.Mui-disabled': {
    color: theme.palette.Buttons.Secondary.LabelDisabled,
  },
  [theme.breakpoints.down('md')]: {
    '&.full-width': {
      flexGrow: 1,
    },
  },
}));
