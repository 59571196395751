import { styled } from '@mui/material/styles';
import { Stack } from 'common/components/material';

export const StyledHeaderContainer = styled(Stack, {
  shouldForwardProp: (prop) => {
    return prop !== 'hideTopDivider';
  },
})<{ hideTopDivider: boolean }>(({ hideTopDivider, theme }) => ({
  borderBottom: hideTopDivider ? 'none' : `1px solid ${theme.palette.Divider}`,
  display: 'flex',
  paddingBottom: '0.5rem',
  marginBottom: hideTopDivider ? 0 : '0.75rem',
}));
