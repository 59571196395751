import React from 'react';

import { Outlet } from 'react-router-dom';
import OrgChartTabs from './org-chart-tabs';
import { Box } from 'common/components/material';

const OrgChartContainer: React.FC = () => {
  return (
    <>
      <OrgChartTabs />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '12px 0',
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Outlet></Outlet>
        </Box>
      </Box>
    </>
  );
};

export default OrgChartContainer;
