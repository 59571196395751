import React, { useState } from 'react';

import { Stack } from 'common/components/material';
import AddStaticDataButton from 'features/org-root/components/StaticData/AddStaticDataButton';
import StackedDataListItemCard from 'features/profiles/components/StackedDataListItemCard';
import { CompanyNameField } from './company-name-field.component';

export const AddPriorExperienceContainer: React.FC = () => {
  const [editMode, setEditMode] = useState(false);

  return (
    <StackedDataListItemCard>
      <Stack flex={1} pl={editMode ? 1 : 0.5} pr={3} height="46px" justifyContent="center">
        {editMode ? (
          <CompanyNameField onClose={() => setEditMode(false)} />
        ) : (
          <AddStaticDataButton onClick={() => setEditMode(true)}>Add New</AddStaticDataButton>
        )}
      </Stack>
    </StackedDataListItemCard>
  );
};
