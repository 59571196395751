import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { EVENTS_TO_REFETCH_PROJECTS } from 'features/projects/project.utils';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { getWinsExpiryStatusApi } from 'features/projects/services/projects.service';

export const useGetWinsExpiryStatus = (options?: UseQueryOptions) => {
  const { account: user, hasSession } = useSession();
  const queryKey = [user?.organizationId, user?.profileId, 'wins-expiry-status'];

  const queryResponse = useQuery(queryKey, getWinsExpiryStatusApi, {
    enabled: hasSession,
    select: (response) => {
      return {
        ...response,
        winsPeriodStatus: response.data.winsPeriodStatus,
      };
    },
    ...options,
  });

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data: queryResponse?.data?.winsPeriodStatus,
  };
};
