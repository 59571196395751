import { Button, Stack } from 'common/components/material';
import { ReviewSubmitActionButtonsProps } from 'features/reviews';

const ReviewSubmitActionButtons: React.FC<ReviewSubmitActionButtonsProps> = ({
  onSubmit,
  onDiscard,
  isSubmitDisabled,
  isDiscardDisabled,
}) => {
  return (
    <Stack direction="row" spacing={2} sx={{ p: '1rem' }}>
      <Button
        btntype="tertiary"
        sx={{ fontWeight: 500 }}
        disabled={isDiscardDisabled}
        onClick={onDiscard}
      >
        Discard
      </Button>
      <Button btntype="primary" disabled={isSubmitDisabled} onClick={onSubmit}>
        Submit
      </Button>
    </Stack>
  );
};

export default ReviewSubmitActionButtons;
