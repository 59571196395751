import React from 'react';

import { Button } from 'common/components/material';
import { ButtonProps } from 'common/components/material/Button';
import { useView } from 'common/hooks';

export type QuickActionButtonProps = ButtonProps & {
  icon?: string;
  iconElement?: React.ReactElement;
};

const QuickActionButton: React.FC<QuickActionButtonProps> = ({
  icon,
  iconElement,
  ...props
}) => {
  const { isMobileView } = useView();
  const title = props.title;
  const startIcon =
    !iconElement && icon ? (
      <img src={icon} style={{ marginRight: 5, height: 18 }} alt="" />
    ) : (
      iconElement
    );

  return (
    <>
      {isMobileView ? (
        <Button btntype="primary" size="xsmall" {...props}>
          {title}
        </Button>
      ) : (
        <Button btntype="primary" size="small" startIcon={startIcon} {...props}>
          {title}
        </Button>
      )}
    </>
  );
};

export default QuickActionButton;
