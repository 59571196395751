import { useView } from 'common/hooks';

export const useGenerateSocialText = () => {
  const { isDesktopView } = useView();
  const getReactionText = (totalReaction: number, isReactedByLoggedInUser: boolean) => {
    let reactionText = '';
    if (totalReaction === 0) {
      return;
    }
    if (!isDesktopView) {
      return `${totalReaction}`;
    }
    if (isReactedByLoggedInUser) {
      reactionText = 'Liked by you';
      return totalReaction > 1
        ? `${reactionText} and ${totalReaction - 1} ${
            totalReaction === 2 ? 'other' : 'others'
          }`
        : reactionText;
    }
    return totalReaction === 1 ? '1 like' : `${totalReaction} likes`;
  };
  const getCommentsText = (totalComment: number) => {
    if (totalComment === 0) {
      return;
    }
    if (!isDesktopView) {
      return `${totalComment}`;
    }
    if (totalComment === 1) {
      return '1 comment';
    }
    return `${totalComment} comments`;
  };

  return {
    getReactionText,
    getCommentsText,
  };
};
