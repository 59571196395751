import { styled } from '@mui/material';
import { BannerBlockProps } from './preview-card-props.type';

export const BannerBlock = styled('div', {
  shouldForwardProp: (prop) => {
    return prop !== 'bannerColor';
  },
})<BannerBlockProps>(({ bannerColor, theme }) => {
  return {
    width: '100%',
    background: bannerColor || theme.palette.CoversBackground.ProfileCoverBg,
    borderRadius: '0.5rem',
    paddingBottom: '35%',
  };
});

export const OverlappingElementContainer = styled('div')({
  transform: 'translateY(-50%)',
});
