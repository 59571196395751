import { styled } from '@mui/material/styles';

import { Box } from 'common/components/material';
import { getThemeImagePath } from 'common/utils/file.utils';

export const MultipleJoinersWelcomeBanner = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${getThemeImagePath(
    'NewJoiners',
    theme.palette.mode,
    'NewJoinersBanner.svg',
  )})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  borderRadius: '12px',
  height: '3.5rem',
  fontSize: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    backgroundPosition: 'right 1rem center, 0 -1.5rem',
  },
}));
