import * as _ from 'lodash';
import React from 'react';

import { FEEDS_SKELETON_LENGTH } from '../feeds.constants';
import { SingleNewsFeedCardSkeleton } from './single-news-feed-card-skeleton.component';

export const NewsFeedCardSkeleton: React.FC = () => {
  return (
    <>
      {_.range(FEEDS_SKELETON_LENGTH).map((_, index) => (
        <SingleNewsFeedCardSkeleton key={index} />
      ))}
    </>
  );
};
