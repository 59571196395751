import { styled } from '@mui/material';
import { EllipsisText } from './ellipsis-text.component';

export const EllipsisLink = styled(EllipsisText, {
  shouldForwardProp: (prop) => {
    return prop !== 'readonly';
  },
})<{ readonly?: boolean }>(({ readonly = false, theme }) => ({
  color: readonly ? theme.palette.Text.SubHeadline : theme.palette.LinkText.Default,
  cursor: readonly ? 'default' : 'pointer',
  '&:hover': {
    color: readonly ? theme.palette.Text.SubHeadline : theme.palette.LinkText.Active,
  },
}));
