import React from 'react';
import { useTheme } from '@mui/material/styles';

import Typography from 'common/components/material/Typography';
import { ProfileJobTitleProps } from 'features/profiles';
import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { Link } from 'common/components/material';

const ProfileJobTitle: React.FC<ProfileJobTitleProps> = ({
  jobTitle,
  readonly = false,
  ...typographyProps
}) => {
  const { getOrgRoute } = useRouter();
  const { palette } = useTheme();
  const exploreSearchRoute = getOrgRoute(
    AppRoutesEnum.EXPLORE_PEOPLE,
    {},
    { query: jobTitle?.name },
  );

  const textColor = jobTitle ? palette.Text.SubHeadline : palette.Text.Disabled;

  return (
    <Link href={exploreSearchRoute}>
      <Typography
        variant="body3"
        color={textColor}
        {...typographyProps}
        sx={{
          cursor: readonly ? 'text' : 'pointer',
          ...typographyProps.sx,
          '&:hover': {
            color: readonly ? textColor : palette.LinkText.Active,
          },
        }}
      >
        {jobTitle?.name || 'Job Title'}
      </Typography>
    </Link>
  );
};

export default ProfileJobTitle;
