import { Typography } from 'common/components/material';
import { ReactComponent as ShareIconSVG } from 'common/svg-icons/share.svg';
import { styled } from '@mui/material';

export const StyledShareButtonText = styled(Typography)(() => ({
  marginLeft: 5,
  textTransform: 'capitalize',
}));

export const StyledShareIcon = styled(ShareIconSVG)(({ color }) => ({
  fill: color || 'default',
  path: {
    fill: color || 'default',
  },
}));
