import React from 'react';
import { useTheme } from '@mui/material';
import { getWinsBannerInitialText } from '../utils/wins-expiration.utils';
import { WinsPeriodStatusEnum } from '../wins-period-status.enum';
import { Stack, Typography } from 'common/components/material';
import { useDrawer, useView } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { useGetCurrentWinPeriod } from 'features/projects/hooks/useGetCurrentWinPeriod';
import { useGetNextWinPeriod } from '../hooks/useGetNextWinPeriod';
import { BannerDurationText } from './banner-duration-text.component';

export const WinsExpirationBannerText: React.FC<{
  periodStatus: WinsPeriodStatusEnum;
  daysToExpire?: number;
}> = ({ periodStatus, daysToExpire }) => {
  const { palette } = useTheme();
  const { openDrawer } = useDrawer();
  const { isDesktopView } = useView();
  const { data: currentWinPeriod, isLoading: isCurrentWinPeriodLoading } =
    useGetCurrentWinPeriod();
  const { data: nextWinPeriod, isLoading: isNextWinPeriodLoading } = useGetNextWinPeriod();

  if (isCurrentWinPeriodLoading || isNextWinPeriodLoading) {
    return <></>;
  }

  const initialText = getWinsBannerInitialText(
    periodStatus,
    currentWinPeriod?.name,
    daysToExpire,
  );

  const openManageWinsDrawer = () => {
    openDrawer(DrawerIdEnum.MANAGE_WIN_LIST, {
      addToProfileHandler: () => {},
    });
  };

  return (
    <Stack
      flexDirection={isDesktopView ? 'row' : 'column'}
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant="caption"
        fontWeight={500}
        color={palette.Text.Headline}
      >
        {initialText}
        {daysToExpire != null && ' '}
        {daysToExpire != null && <BannerDurationText days={daysToExpire} />}.
      </Typography>
      <Typography
        ml={0.5}
        variant="caption"
        fontWeight={500}
        color={palette.Buttons.Tertiary.LabelDefault}
        sx={{ cursor: 'pointer' }}
        onClick={openManageWinsDrawer}
      >
        {`Add New ${
          periodStatus === WinsPeriodStatusEnum.WINS_EXPIRED
            ? currentWinPeriod?.name
            : nextWinPeriod?.name
        } WINs`}
      </Typography>
    </Stack>
  );
};
