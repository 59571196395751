import {
  AspirationsStep,
  FullNameStep,
  SkillEndorsementsStep,
  ProfilePhotoStep,
  WINStep,
} from '../components';

export const CREATE_PROFILE_STEPS = {
  FULLNAME: {
    element: <FullNameStep />,
    fields: ['firstName', 'lastName'],
    requiredTouchedFields: ['firstName', 'lastName'],
  },
  ENDORSEMENTS: {
    element: <SkillEndorsementsStep />,
    fields: ['endorsements'],
    requiredTouchedFields: ['endorsements'],
  },
  ASPIRATIONS: {
    element: <AspirationsStep />,
    fields: ['aspirations'],
    requiredTouchedFields: ['aspirations'],
  },
  PROFILE_PHOTO: {
    element: <ProfilePhotoStep />,
    fields: [],
    formContainerDisabled: true,
  },
  WIN: {
    element: <WINStep />,
    fields: ['win1', 'win2', 'win3'],
    requiredTouchedFields: ['win1'],
  },
};
