import { useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getReactionRespondersApi } from '../reactions.service';
import { Person } from 'features/profiles';
import { ProfilePreviewModel } from 'features/profiles/models/profile-preview.model';

export const useGetReactionResponders = (
  reactableId: string,
  reactionType: string,
  options: UseQueryOptions = {},
) => {
  const queryKey = ['reaction-responders', reactableId, reactionType];

  const response = useQuery(
    queryKey,
    () => getReactionRespondersApi(reactableId, reactionType),
    {
      select: (result) => {
        return result.data.profiles.map(
          (profile: Person) => new ProfilePreviewModel(profile),
        );
      },
      ...options,
    },
  );

  return {
    ...response,
    data: response.data as ProfilePreviewModel[],
  };
};
