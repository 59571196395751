import React from 'react';

import { Box } from 'common/components/material';
import { CXTabs } from 'common/components/cx-tabs';
import useGetOrgChartTabs from '../hooks/useGetVisibleOrgChartTabs';
import StickyHeaderContainer from 'common/components/container/StickyHeaderContainer';
import { useView } from 'common/hooks';

const OrgChartTabs: React.FC = () => {
  const { orgChartTabs, selectedTab } = useGetOrgChartTabs();
  const { isDesktopView } = useView();

  if (!isDesktopView) {
    return (
      <StickyHeaderContainer>
        <CXTabs items={orgChartTabs} activeTab={selectedTab} />
      </StickyHeaderContainer>
    );
  }

  return (
    <StickyHeaderContainer disablePointerEvents={true}>
      <Box sx={{ display: 'flex', justifyContent: 'center', pointerEvents: 'none' }}>
        <CXTabs items={orgChartTabs} activeTab={selectedTab} />
      </Box>
    </StickyHeaderContainer>
  );
};

export default OrgChartTabs;
