import { useEvents, useQuery, useSession } from 'common/hooks';
import { isEmpty } from 'lodash';
import { EVENTS_TO_REFETCH_FEED_DATA, transformFeed } from 'features/feeds';
import { getFeedDataApi } from '../feeds.service';
import { Event } from 'common/hooks/eventHooks/useEvents';

export const useGetFeedData = (feedId: string | undefined) => {
  const { account } = useSession();
  const organizationId = account?.organizationId;
  const queryResponse = useQuery(
    [organizationId, feedId, 'feedItem'],
    () => getFeedDataApi(feedId!),
    {
      enabled: !isEmpty(feedId),
    },
  );

  const response = {
    ...queryResponse,
    data: transformFeed(queryResponse.data?.data),
  };

  /**
   * TODO: would be nice to refetch only if the update was for our item
   */
  useEvents(EVENTS_TO_REFETCH_FEED_DATA as Event[], () => response.refetch());

  return response;
};
