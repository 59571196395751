import React from 'react';
import { useFormContext } from 'react-hook-form';

import { StepContainer } from 'common/components/container';
import ProjectFormTitle from 'features/projects/components/project-form-title.component';
import { WinListTooltip } from 'features/win-lists/components/win-list-tooltip.component';

export const WINStep: React.FC = () => {
  const { watch } = useFormContext();

  return (
    <StepContainer
      heading={
        <>
          Create your “What’s Important Now” List{' '}
          <WinListTooltip
            sx={{ verticalAlign: 'sub', width: '1.375rem', height: '1.375rem' }}
          />
        </>
      }
      subheading="Briefly list up to 3 key initiatives you’re focusing on this quarter."
      pb={3}
    >
      <ProjectFormTitle autoFocus name="win1" />
      <ProjectFormTitle name="win2" disabled={!watch('win1')} />
      <ProjectFormTitle name="win3" disabled={!watch('win1') || !watch('win2')} />
    </StepContainer>
  );
};
