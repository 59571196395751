import { CXTabsRoute } from './cx-tabs-router.type';

export const getTabsConfig = (config: CXTabsRoute[]) => {
  return config.map((item) => ({
    label: item.label,
    value: item.path,
    to: item.to,
    state: { tabNavigation: true },
    disabled: item.disabled,
  }));
};

// Defines the path for the tab's Route component
export const getTabRoutePath = (item: CXTabsRoute) => {
  return item.hasChildren ? item.to + '/*' : item.to;
};
