import { isEmpty } from 'lodash';
import { Typography, useTheme } from '@mui/material';
import { HorizontalScrollableContainer } from 'common/components/container';
import { Grid, Stack } from 'common/components/material';
import { useView } from 'common/hooks';
import TagChip from 'features/tags/components/tag-chip.component';
import { Tag } from 'features/tags/types';

export const OrganizationRoleTagsView: React.FC<{ tags: Tag[] }> = ({ tags }) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();
  if (isEmpty(tags)) {
    return <></>;
  }

  return (
    <Stack direction="row" spacing={3.5} alignItems="center">
      <Typography variant="caption" color={palette.Text.Caption} fontWeight={500} width={43}>
        Part of
      </Typography>
      <Grid width={isDesktopView ? '90%' : '75%'}>
        <HorizontalScrollableContainer
          scrolledListElement={
            <Stack py={0.5} direction="row" spacing={1}>
              {tags.map(({ ...tag }) => (
                <TagChip key={tag.id} tag={tag} />
              ))}
            </Stack>
          }
        />
      </Grid>
    </Stack>
  );
};

export default OrganizationRoleTagsView;
