import { FeaturesEnum } from 'features/feature-access/features.enum';
import { Tag } from '../types';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';

export const useGetTransformedTags = (tags: Tag[] = []) => {
  const { canRead: hasAccessToStaticDataPages } = useFeatureAccess(
    FeaturesEnum.STATIC_DATA_PAGES,
  );

  return (
    tags?.map((tag) => {
      tag.readonly = hasAccessToStaticDataPages;
      return tag;
    }) ?? []
  );
};
