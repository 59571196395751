import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deleteReactionApi } from '../reactions.service';

export const useDeleteSocialReaction = (
  reactableId: string,
  options: UseMutationOptions = {},
) => {
  return useMutation(() => deleteReactionApi(reactableId), {
    ...options,
  });
};
