import { Profile } from 'features/profiles/models/profile.model';
import { DEFAULT_FEATURE_ACCESS_PERMISSION } from '../feature-access.constants';
import { SystemFeatureAccessEnum } from '../enums/feature-access.enum';
import { FeaturesEnum } from '../features.enum';
import { AccessPermissionType } from '../types/feature-access.type';

export const getOwnProfileAccess = (
  getAccessPermission: (feature: FeaturesEnum) => AccessPermissionType,
  loggedInUser: Profile,
) => {
  const permissions = { ...DEFAULT_FEATURE_ACCESS_PERMISSION };
  const ownProfilePermission = getAccessPermission(FeaturesEnum.OWN_PROFILE);

  permissions.canRead =
    ownProfilePermission[SystemFeatureAccessEnum.READ] && !loggedInUser?.isGuestProfile;
  permissions.canWrite =
    ownProfilePermission[SystemFeatureAccessEnum.WRITE] && !loggedInUser?.isGuestProfile;
  return permissions;
};

export const getContentCreationAccess = (
  getAccessPermission: (feature: FeaturesEnum) => AccessPermissionType,
) => {
  const permissions = { ...DEFAULT_FEATURE_ACCESS_PERMISSION };

  const postPrmissions = getAccessPermission(FeaturesEnum.POSTS);
  const praisePermissions = getAccessPermission(FeaturesEnum.PRAISES);
  const opportunityPermissions = getAccessPermission(FeaturesEnum.OPPORTUNITIES);
  const companyUpdatesPermissions = getAccessPermission(FeaturesEnum.COMPANY_UPDATES);
  const organizationEventsPermissions = getAccessPermission(FeaturesEnum.ORGANIZATION_EVENTS);

  permissions.canWrite =
    postPrmissions[SystemFeatureAccessEnum.WRITE] ||
    praisePermissions[SystemFeatureAccessEnum.WRITE] ||
    opportunityPermissions[SystemFeatureAccessEnum.WRITE] ||
    companyUpdatesPermissions[SystemFeatureAccessEnum.WRITE] ||
    organizationEventsPermissions[SystemFeatureAccessEnum.WRITE];

  return permissions;
};

export const getUserSettingsAccess = (
  getAccessPermission: (feature: FeaturesEnum) => AccessPermissionType,
) => {
  const permissions = { ...DEFAULT_FEATURE_ACCESS_PERMISSION };
  const manageNotificationsPermissions = getAccessPermission(
    FeaturesEnum.MANAGE_NOTIFICATIONS,
  );
  const profilesPermissions = getAccessPermission(FeaturesEnum.OWN_PROFILE);

  permissions.canRead =
    manageNotificationsPermissions[SystemFeatureAccessEnum.READ] ||
    profilesPermissions[SystemFeatureAccessEnum.READ];
  permissions.canWrite =
    manageNotificationsPermissions[SystemFeatureAccessEnum.WRITE] ||
    profilesPermissions[SystemFeatureAccessEnum.WRITE];

  return permissions;
};

export const getSearchSuggestAccess = (
  getAccessPermission: (feature: FeaturesEnum) => AccessPermissionType,
) => {
  const permissions = { ...DEFAULT_FEATURE_ACCESS_PERMISSION };
  const staticDataPagesPermissions = getAccessPermission(FeaturesEnum.STATIC_DATA_PAGES);
  const otherProfilesPermission = getAccessPermission(FeaturesEnum.OTHER_PROFILE);

  permissions.canRead =
    staticDataPagesPermissions[SystemFeatureAccessEnum.READ] ||
    otherProfilesPermission[SystemFeatureAccessEnum.READ];

  return permissions;
};
