import axios from 'common/utils/axios';

const feedsApi = axios('feeds');

export const getFeedsApi = (params: any) => {
  const page = params.pageParam?.page || 1;
  return feedsApi.get('', { params: { page, feedType: params.feedType } });
};

export const getFeedDataApi = (feedId: string) => {
  return feedsApi.get(feedId);
};

export const getPersonalizedFeedsApi = (params: any) => {
  const page = params.pageParam?.page || 1;
  return feedsApi.get('personalized', { params: { page } });
};
