import React from 'react';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';

import { PersonLink } from 'common/components/person-link/person-link.component';
import { UserAvatar } from 'features/profiles/components/user-avatar';
import { Stack, Typography } from 'common/components/material';
import {
  FEED_HEADER_SUBSTRING,
  FeedCardHeaderProps,
  StyledHeaderContainer,
} from 'features/feeds';
import { FeedTimestamp } from './feed-timestamp.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import VisibleTo from './visible-to-component';
import { CXChip } from 'common/components/cx-chip';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import useView from 'common/hooks/useView';
import useSession from 'common/hooks/useSession';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';

const FeedCardHeader: React.FC<FeedCardHeaderProps> = ({
  user,
  headerContent,
  feedId,
  feedType,
  timestamp,
  editedTimestamp,
  headerAction,
  headerOptions,
  hideEditedMarker,
  hideTopDivider = false,
  visibleTo,
  publishedBy,
}) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const { account } = useSession();

  const showEditedMarker =
    !hideEditedMarker && editedTimestamp && editedTimestamp !== timestamp;
  const headerSubstring = FEED_HEADER_SUBSTRING[feedType];
  const hasHeaderContent = !isNil(headerContent);

  const { canRead: canNavigateToProfile } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    user,
  );

  const defaultHeaderContent = (
    <Stack direction="row" display="flex" flex={1}>
      {user && <UserAvatar fullName={user.fullName} src={user.photo} size="mediumLarge" />}
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" flex={1}>
        <Stack direction="column" alignItems="flex-start" mb={{ xs: 1, md: 0 }}>
          <Stack direction={isMobileView ? 'column' : 'row'} flexWrap="wrap">
            {user && (
              <PersonLink
                id={user?.id as string}
                name={user?.fullName}
                variant="body3"
                fontWeight={500}
                sx={{ paddingLeft: '0.5rem' }}
                readonly={!canNavigateToProfile || !permissions.interact}
              />
            )}

            {publishedBy && account?.organizationId !== publishedBy?.id && (
              <CXChip
                label={publishedBy?.name}
                sx={{
                  borderRadius: '4px',
                  ml: 0.5,
                }}
                key={publishedBy?.id}
                disabled
              />
            )}
            <Typography
              variant={isMobileView ? 'body4' : 'body3'}
              sx={{
                paddingLeft: isMobileView ? 1 : 0.5,
                color: palette.Text.SubHeadline,
              }}
            >
              {headerSubstring}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <FeedTimestamp feedId={feedId} timestamp={timestamp} />
            {showEditedMarker && (
              <>
                <Typography variant="body4" color={palette.Divider}>
                  |
                </Typography>
                <Typography variant="body4" color={palette.Text.SubHeadline}>
                  Edited
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        {headerAction}
      </Stack>
    </Stack>
  );

  return (
    <StyledHeaderContainer direction="row" spacing={1} hideTopDivider={hideTopDivider}>
      {hasHeaderContent ? headerContent : user ? defaultHeaderContent : <></>}
      <Stack alignItems="center" direction="row">
        <VisibleTo visibleTo={visibleTo} />
        {headerOptions && (
          <Stack alignItems="center" justifyContent="center" minWidth={30}>
            {headerOptions}
          </Stack>
        )}
      </Stack>
    </StyledHeaderContainer>
  );
};

export default FeedCardHeader;
