import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import { get, isNil, some, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import CXCheckbox from 'common/components/cx-checkbox/cx-checkbox.component';
import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { FormTextField, RadioGroup, StyledForm } from 'common/components/input';
import { Box, Divider, Stack, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';
import { useDeferredState, useDrawer, useForm, useSession, useView } from 'common/hooks';
import {
  CompanyUpdate,
  CompanyUpdateDrawerProps,
  CompanyUpdateForm,
  CompanyUpdateFormSchema,
  useCreateCompanyUpdate,
  useUpdateCompanyUpdate,
} from 'features/company-updates';

import OnBehalfOfSelector from 'features/on-behalf-of/components/on-behalf-of-selector.component';
import TagSelector from 'features/tags/components/tag-selector.component';
import { TagContentType } from 'features/tags/enums/tag-content-type.enum';
import { Tag } from 'features/tags/types';
import { useGetOnBehalfOfValue } from 'features/on-behalf-of/hooks/useGetOnBehalfOfValue';
import { CXEditorField } from 'libs/toast-ui';
import { COMPANY_UPDATE_DRAWER_HELPER } from '../company-updates.constants';

import { Media, toMediaRequestDto } from 'features/media/types/media.types';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { MediaLoader } from 'features/media/components/media-uploaders/media-loader.component';
import PublishingProgress from 'common/components/loaders/publishing-progress.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import VisibileToSelector from 'common/components/role-access/VisibleToSelector';
import { ContentType } from 'common/enum/ContentType.enum';

const DRAWER_TITLE = 'Company Update';

export const INIT_PROPS: CompanyUpdateDrawerProps = {
  companyUpdate: {} as CompanyUpdate,
  selectedTags: [] as Tag[],
  isComingFromGlobalCreateButton: false,
};

const CompanyUpdateDrawer: React.FC<CompanyUpdateDrawerProps> = ({
  companyUpdate = {} as CompanyUpdate,
  selectedTags = [],
  isComingFromGlobalCreateButton = false,
  onBehalfOfPage,
}) => {
  const initTags = !isEmpty(selectedTags)
    ? [...selectedTags]
    : companyUpdate?.tags && !isEmpty(companyUpdate?.tags)
    ? [...companyUpdate?.tags]
    : [];

  const [tags, setTags] = useDeferredState<Tag[]>(initTags);

  const [sendToAllEmployees, setSendToAllEmployees] = useDeferredState<boolean>(false);
  const [shouldSendEmail, setShouldSendEmail] = useDeferredState<boolean>(false);
  const { closeDrawer, openDrawer } = useDrawer();
  const { isMobileView } = useView();
  const { palette } = useTheme();
  const { profile, currentOrganization } = useSession();

  const { submit: createCompanyUpdate, isLoading: isCreating } = useCreateCompanyUpdate();
  const { submit: updateCompanyUpdate, isLoading: isUpdating } = useUpdateCompanyUpdate();
  const onBehalfOfDefaultValue = useGetOnBehalfOfValue({
    isComingFromGlobalCreateButton,
    onBehalfOfPage,
  });
  const [isSaving, setIsSaving] = useState(false);

  companyUpdate = companyUpdate ?? {};
  companyUpdate.onBehalfOf = companyUpdate?.onBehalfOf || onBehalfOfDefaultValue;

  const [onBehalfOf, setOnBehalfOf] = useState<Tag | null>(companyUpdate?.onBehalfOf);

  const companyUpdateId = get(companyUpdate, 'id');

  const action = companyUpdateId ? 'Update' : 'Create';

  const isAdmin = profile.isAdmin() || profile.isContentAdmin();
  const isEmailDisabled = !isAdmin && (isEmpty(tags) || isEmpty(onBehalfOf));

  const [mediaAttachments, setMediaAttachments] = useState<Media[]>(
    companyUpdate.attachments || [],
  );

  const form = useForm<CompanyUpdateForm>({
    defaultValues: {
      ...companyUpdate,
      visibleTo: companyUpdate?.visibleTo || [currentOrganization()],
    },
    mode: 'onChange',
    resolver: yupResolver(CompanyUpdateFormSchema(!isAdmin)),
  });

  const {
    formState: { isDirty, isValid, dirtyFields },
    getValues,
    clearErrors,
    reset,
    trigger,
  } = form;

  const [isMediaUpdated, setIsMediaUpdated] = useState(false);

  const shouldDisableActions =
    !isValid || (!isDirty && !isMediaUpdated) || isCreating || isUpdating;

  const close = useCallback(() => {
    reset();
    closeDrawer(DrawerIdEnum.COMPANY_UPDATE_CONFIRMATION);
    closeDrawer(DrawerIdEnum.COMPANY_UPDATE);
  }, [reset, closeDrawer]);

  useEffect(() => {
    if (!(isCreating || isUpdating) && isSaving) {
      close();
    }
  }, [isCreating, isUpdating, isSaving, close]);

  // POBO => Publish On Behalf Of
  const onPOBOChanged = (tag: Tag | null) => {
    setOnBehalfOf(tag);
    if (tag && tag.type !== 'COMPANY') {
      const newTags = tags || [];
      if (!some(tags, (t) => t.id === tag.id)) {
        newTags?.push(tag);
        setTags(newTags);
        form.setValue('tags', newTags);
        if (isDirty && dirtyFields['tags']) {
          clearErrors();
        }
      }
    }
  };

  const onTagsChanged = (tags: Tag[]) => {
    const newTags = tags || [];
    if (
      onBehalfOf &&
      onBehalfOf.type !== 'COMPANY' &&
      !(tags || []).some((t) => t.id === onBehalfOf.id)
    ) {
      newTags.push(onBehalfOf);
    }

    if (!newTags.length) {
      setSendToAllEmployees(true);
    }

    setTags(newTags);
  };

  const onBeforeSubmit = () => {
    openDrawer(DrawerIdEnum.COMPANY_UPDATE_CONFIRMATION, {
      confirmButtonHandler: onSubmit,
      tags: getValues('tags'),
      sendToAllEmployees,
    });
  };

  const onMediaAttachmentsChange = (attachments: Media[]) => {
    setMediaAttachments(attachments);
    setIsMediaUpdated(true);
  };

  const onSubmit = () => {
    const data = getValues();
    //transform form data to incorporate image
    const formData = new FormData();
    const onBehalfOfValue: Tag = onBehalfOf || data.onBehalfOf;
    formData.append('onBehalfOfId', onBehalfOfValue?.id || '');
    formData.append('onBehalfOfType', onBehalfOfValue?.type || '');
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('tags', JSON.stringify(data.tags ?? []));
    formData.append('visibleTo', JSON.stringify(data.visibleTo.map((v) => v.id)));

    if (!companyUpdateId) {
      formData.append('shouldSendEmail', JSON.stringify(shouldSendEmail));
      formData.append('sendToAllEmployees', JSON.stringify(sendToAllEmployees));
    }

    if (!isEmpty(mediaAttachments)) {
      const attachments = [];
      for (const mediaAttachment of mediaAttachments) {
        if (mediaAttachment.file?.file) {
          formData.append('files', mediaAttachment.file.file);
        }
        if (mediaAttachment.thumbnailFile?.file) {
          formData.append('files', mediaAttachment.thumbnailFile.file);
        }
        attachments.push(toMediaRequestDto(mediaAttachment));
      }
      formData.append('attachments', JSON.stringify(attachments));
    }

    if (companyUpdateId) {
      formData.append('id', companyUpdateId);
      updateCompanyUpdate(formData);
    } else {
      createCompanyUpdate(formData);
    }
    setIsSaving(true);
  };

  const onRadioSelect = (value: string) => {
    setSendToAllEmployees(value === 'true');
  };

  const onSendEmailClick = (value: boolean) => {
    setShouldSendEmail(value);
    if (!tags?.length && !sendToAllEmployees) {
      setSendToAllEmployees(true);
    }

    if (tags?.length && sendToAllEmployees) {
      setSendToAllEmployees(false);
    }
  };

  const header = (
    <DrawerHeader sx={{ display: 'block', p: 2, pb: 0 }}>
      <Typography
        variant={isMobileView ? 'h4' : 'h2'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {`${action} ${DRAWER_TITLE}`}
      </Typography>
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isCreating || isUpdating}
      isPrimaryActionDisabled={shouldDisableActions}
      secondaryAction={close}
      primaryAction={shouldSendEmail && !companyUpdateId ? onBeforeSubmit : onSubmit}
      primaryActionLabel={action}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={3} mx={2} pb={2}>
            <Stack direction="column" justifyContent="center">
              <OnBehalfOfSelector
                label="Posting on behalf of"
                tag={onBehalfOf}
                onChange={onPOBOChanged}
                contentType={TagContentType.COMPANY_UPDATE}
                sx={{ width: '20rem' }}
              />
              <Typography variant="body4" color={palette.Text.Caption} mt={1.5}>
                <b>Note</b>: your name will be displayed as the creator
              </Typography>
            </Stack>
            <Divider />
            <FormTextField
              name="title"
              label="Headline"
              autoFocus={!isNil(onBehalfOf)}
              inputProps={{
                maxLength: TITLE_CHARACTER_LIMIT,
              }}
            />
            <CXEditorField name="description" placeholder="What would you like to share?" />
            <FeatureGuard feature={FeaturesEnum.TAGGINGS}>
              <Box>
                <TagSelector
                  value={tags}
                  onChange={onTagsChanged}
                  disabledIds={onBehalfOf ? [onBehalfOf.id] : []}
                  contentType={TagContentType.COMPANY_UPDATE}
                />
                <Box mt={1}>
                  <Typography variant="body4" color={palette.Text.Caption} mt={1.5}>
                    <b>Note: </b>
                    {COMPANY_UPDATE_DRAWER_HELPER}
                  </Typography>
                </Box>
              </Box>
            </FeatureGuard>

            {!companyUpdateId && (
              <Box>
                <CXCheckbox
                  name="shouldSendEmail"
                  value={shouldSendEmail}
                  onChange={onSendEmailClick}
                  disabled={isEmailDisabled}
                  label={
                    <Typography
                      variant="body3"
                      ml="0.375rem"
                      color={isEmailDisabled ? palette.Text.Disabled : palette.Text.Caption}
                    >
                      Send email to employees
                    </Typography>
                  }
                />
                {shouldSendEmail && (
                  <RadioGroup
                    name="sendToAllEmployees"
                    value={sendToAllEmployees}
                    onChange={onRadioSelect}
                    options={[
                      {
                        label: 'Followers of the pages selected above',
                        value: false,
                        disabled: isEmpty(tags) || isEmpty(onBehalfOf),
                      },
                      {
                        label: 'All employees',
                        value: true,
                        disabled: !isAdmin,
                      },
                    ]}
                    radioSize="small"
                  ></RadioGroup>
                )}
              </Box>
            )}
            {onBehalfOf?.type === 'COMPANY' && (
              <VisibileToSelector
                name="visibleTo"
                label="Visible to"
                contentType={ContentType.COMPANY_UPDATES}
                value={getValues().visibleTo}
                onChange={() => {
                  trigger('visibleTo');
                }}
              />
            )}

            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                pb={1.25}
                color={palette.Text.Headline}
              >
                Attachments
              </Typography>
              <MediaLoader onChange={onMediaAttachmentsChange} media={mediaAttachments} />
            </Stack>
          </Stack>
          {(isCreating || isUpdating) && <PublishingProgress />}
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default CompanyUpdateDrawer;
