import { find, get, pick, isEmpty, trim } from 'lodash';

import {
  OrganizationRolesEnum,
  OrganizationTypeEnum,
} from '../../../common/enum/Organization.enum';

import { getWorkLocationWithOffice, OrgStaticData } from 'features/static-data';
import { BaseProfile } from './base-profile.model';
import { Person } from '../types/person.type';
import { SearchProfile, EditProfileForm } from '../types';
import { Account } from 'features/accounts';
import { ProfileVisibilityEnum } from '../profiles.enum';

export class Profile extends BaseProfile {
  private role: string;

  fullName: string;
  adminPageIds: string[];

  constructor(profile: BaseProfile, account: Account) {
    super();
    Object.assign(this, profile);
    this.fullName = trim(`${profile.firstName || ''} ${profile.lastName || ''}`);
    this.organizations = this.getTransformedOrganizations() || [];
    this.adminPageIds = account.adminPageIds;
    this.role = account.role ?? '';

    const organizationProfileData = profile.organizationProfile?.data;
    if (organizationProfileData) {
      this.organizationProfile = {
        ...profile.organizationProfile,
        data: {
          ...organizationProfileData,
          workLocation: getWorkLocationWithOffice(organizationProfileData?.workLocation),
        },
      };
    }
    profile?.organizationProfiles?.forEach((organizationProfile) => {
      organizationProfile.company.logoSrc = organizationProfile.company.logo;
    });
  }

  get isGuestProfile() {
    return !!this.organizationProfile?.data?.isGuestProfile;
  }

  get isFullyVisible() {
    const { visibility } = this.organizationProfile?.data || {};
    return visibility === ProfileVisibilityEnum.FULL;
  }

  getOrganizationProfile(param: { organizationId?: string; subUrl?: string }) {
    const { organizationId, subUrl } = param;
    if (isEmpty(organizationId) && isEmpty(subUrl)) {
      return null;
    }

    return find(this.organizationProfiles, (organizationProfile) =>
      !isEmpty(organizationId)
        ? organizationProfile.companyId === organizationId
        : organizationProfile.company.subUrl === subUrl,
    );
  }

  getTransformedOrganizations() {
    return (
      this.organizations &&
      this.organizations.map((organization) => ({
        ...organization,
        isAdmin: organization.role === OrganizationRolesEnum.ADMIN,
        isCurrentOrganization: organization.id === this.organizationProfile?.data?.companyId,
        logoSrc: organization.logo,
      }))
    );
  }

  getOrgId() {
    const { type, data } = this.organizationProfile;

    if (type === OrganizationTypeEnum.COMPANY) {
      return data.companyId;
    }
  }

  getOrgSubUrl() {
    if (!this.organizationProfile) {
      return '';
    }

    const orgId = this.getOrgId();

    if (!orgId) {
      return '';
    }

    const org = find(this.organizations, { id: orgId });

    return org?.subUrl;
  }

  getCurrentOrganization() {
    if (!this.organizationProfile) {
      return;
    }

    const orgId = this.getOrgId();
    const organization = find(this.organizations, { id: orgId });

    return organization;
  }

  getProfileFormFields(): EditProfileForm {
    const orgProfile = pick(this.organizationProfile.data, [
      'jobTitle',
      'visibleEmail',
      'manager',
      'reportees',
      'role',
      'bio',
      'joinDate',
      'terminationDate',
      'phoneNumber',
      'department',
      'division',
      'team',
      'product',
      'groups',
      'workLocation',
    ]);
    const profileEducations = this.profileEducations?.map((e) => ({
      ...e,
      yearOfCompletion: `${e.yearOfCompletion}`,
    }));
    return {
      id: this.id,
      loginEmail: this.loginEmail,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      pronouns: this.pronouns,
      profileEducations,
      ...orgProfile,
      manager: this.getManager(orgProfile?.manager),
      department: this.getStaticData(orgProfile?.department),
      division: this.getStaticData(orgProfile?.division),
      team: this.getStaticData(orgProfile?.team),
      product: this.getStaticData(orgProfile?.product),
      groups: this.getGroupsStaticData(orgProfile?.groups),
    };
  }

  isAdmin() {
    return this.isSuperAdmin || this.role === OrganizationRolesEnum.ADMIN;
  }

  isContentAdmin() {
    return this.role === OrganizationRolesEnum.CONTENT_ADMIN;
  }

  isSuperUser() {
    return this.role === OrganizationRolesEnum.SUPER_USER;
  }

  isPageAdminOf(id: string) {
    return this.adminPageIds.includes(id);
  }

  isPageAdmin() {
    return !!this.adminPageIds.length;
  }

  hasCompleteProfile() {
    return !!this.id;
  }

  hasOrganizationProfile() {
    return !!this.organizationProfile;
  }

  hasAccessToOrganization() {
    return get(this.organizationProfile, 'data.isActive');
  }

  getManager(manager?: Person) {
    return manager && (pick(manager, ['id', 'photo', 'fullName']) as SearchProfile);
  }

  getStaticData(data?: OrgStaticData) {
    return data && (pick(data, ['id', 'name', 'fullName', 'isVisible']) as OrgStaticData);
  }

  getGroupsStaticData(staticData?: OrgStaticData[]) {
    return (
      staticData &&
      staticData.map(
        (data) => pick(data, ['id', 'name', 'fullName', 'isVisible']) as OrgStaticData,
      )
    );
  }
}
