import React, { useRef } from 'react';

import HorizontalScrollableContainer from 'common/components/container/HorizontalScrollableContainer';
import { ScrollLeftButton, ScrollRightButton } from '../../../features/profiles/styles';
import { HorizontalScrollablePreviewCardsProps } from './horizontal-scrollable-preview-cards-props.type';
import { Stack } from 'common/components/material';
import { useGetScrollableDimensions } from './useGetScrollableDimensions';
import { useView } from 'common/hooks';

const HorizontalScrollablePreviewCards: React.FC<HorizontalScrollablePreviewCardsProps> = ({
  header,
  footer,
  children,
  isFetchingNextPage,
  fetchNextPage = () => {},
  hasNextPage,
  itemsToShowInViewPort = 1,
  itemsToScroll = 1,
  spacing = 2,
  scrollAlignment = 'left',
}) => {
  const handleNextPageClick = () => hasNextPage && !isFetchingNextPage && fetchNextPage();
  const { isDesktopView } = useView();

  itemsToShowInViewPort = isDesktopView ? itemsToShowInViewPort : 1;
  itemsToScroll = itemsToShowInViewPort;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { itemWidth, scrollOffset } = useGetScrollableDimensions(
    itemsToShowInViewPort,
    itemsToScroll,
    spacing,
    containerRef,
  );

  const getContainerComponent = () => {
    return (
      <Stack direction="row" spacing={spacing}>
        {React.Children.map(children, (child) => {
          return (
            <Stack sx={{ minWidth: scrollAlignment === 'left' ? itemWidth : 0 }}>
              {child}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <Stack
      spacing={2}
      ref={containerRef}
      alignItems={scrollAlignment === 'left' ? 'initial' : 'flex-end'}
    >
      {header}
      <HorizontalScrollableContainer
        onScrollEnd={handleNextPageClick}
        scrollOffset={scrollOffset}
        scrolledListElement={getContainerComponent()}
        ScrollLeftButtonComponent={ScrollLeftButton}
        ScrollRightButtonComponent={ScrollRightButton}
      ></HorizontalScrollableContainer>
      {footer}
    </Stack>
  );
};

export default HorizontalScrollablePreviewCards;
