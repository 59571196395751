import { styled } from '@mui/material/styles';
import Typography from 'common/components/material/Typography';

export const StyledStaticDataLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.LinkText.Default,
  paddingLeft: '0.5rem',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.LinkText.Active,
  },
}));
