import React from 'react';
import { isEmpty } from 'lodash';

import { Box, Grid, Stack } from 'common/components/material';
import { BackButton } from '../navigation';
import { CXTabsRouter } from '../cx-tabs-router';
import { CXTabsRoute } from '../cx-tabs-router/cx-tabs-router.type';
import { useView } from 'common/hooks';
import { useHistoryIndex } from 'common/context/hooks/historyIndex';

export const PageContainer: React.FC<{
  topContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  config?: CXTabsRoute[];
  renderInMobileView?: boolean;
  children?: React.ReactNode;
  narrowSideColumns?: boolean;
}> = ({
  topContent = <></>,
  leftContent,
  rightContent,
  config,
  children,
  renderInMobileView = false,
  narrowSideColumns,
}) => {
  const { isDesktopView } = useView();
  const { historyIndex } = useHistoryIndex();

  const isMobileView = !isDesktopView || renderInMobileView;
  const hasBothSideContent = !!leftContent && !!rightContent;
  const mainContentSize = hasBothSideContent ? 6 : narrowSideColumns ? 9 : 8;
  const sideContentSize = narrowSideColumns || hasBothSideContent ? 3 : 4;

  return (
    <Stack flexDirection="column" px={{ md: 1 }}>
      {!!historyIndex && (
        <Box mt={2}>
          <BackButton />
        </Box>
      )}
      {topContent}
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} md={isMobileView ? 12 : sideContentSize}>
          <Stack height="100%" spacing={2}>
            {leftContent}
            {isMobileView && rightContent}
          </Stack>
        </Grid>
        <Grid item xs={12} md={isMobileView ? 12 : mainContentSize}>
          {!isEmpty(config) ? (
            <CXTabsRouter
              config={config as CXTabsRoute[]}
              fullWidth
              scrollToTabOnNavigate
              renderInMobileView={isMobileView}
              stickyHeader
            />
          ) : (
            <Stack height="100%" spacing={2}>
              {children}
            </Stack>
          )}
        </Grid>
        {!!rightContent && (
          <Grid item xs={12} md={isMobileView ? 12 : sideContentSize}>
            <Stack height="100%" spacing={2}>
              {rightContent}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
