import { useState } from 'react';
import { useTheme } from '@mui/material';
import { Stack } from 'common/components/material';
import { useGetWinsExpiryStatus } from '../hooks/useGetWinsExpiryStatus';
import { WinsPeriodStatusEnum } from '../wins-period-status.enum';
import { WinsExpirationBannerText } from './wins-expiration-banner-text.component';
import { CloseButton } from 'common/components/navigation';
import { useSession, useView } from 'common/hooks';

export const WinExpirationBanner: React.FC = () => {
  const { palette } = useTheme();
  const { profile } = useSession();
  const { isDesktopView } = useView();
  const { data: winsPeriodStatus, isLoading } = useGetWinsExpiryStatus();
  const [showBanner, setShowBanner] = useState<boolean>(true);

  if (
    isLoading ||
    !profile ||
    !winsPeriodStatus ||
    !showBanner ||
    winsPeriodStatus?.periodStatus === WinsPeriodStatusEnum.VALID
  ) {
    return <></>;
  }

  return (
    <Stack
      height={isDesktopView ? 40 : 60}
      direction="row"
      alignItems="center"
      sx={{ backgroundColor: palette.Extras.Extra10, color: palette.Text.Headline, px: 2 }}
    >
      <WinsExpirationBannerText
        periodStatus={winsPeriodStatus.periodStatus}
        daysToExpire={winsPeriodStatus.daysToExpire}
      />
      <CloseButton size="small" clickHandler={() => setShowBanner(false)} />
    </Stack>
  );
};
