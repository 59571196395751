import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from 'common/components/material';
import { ReactChild } from 'react';

export const PreviewCardRow: React.FC<{
  title?: string;
  children: ReactChild | ReactChild[];
}> = ({ title, children }) => {
  const { palette } = useTheme();
  return (
    <Stack py={1} spacing={1}>
      <Typography variant="body4" color={palette.Text.Caption}>
        {title}
      </Typography>
      {children}
    </Stack>
  );
};
