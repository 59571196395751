import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';

import { Box, Divider, Stack, Typography } from 'common/components/material';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import useDrawer from 'common/hooks/useDrawer';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { OrganizationRoleCardContentProps } from '../types/organization-role-card-content.props.type';
import { ThreeDotMenu } from 'common/components/core';
import { DEFAULT_OPTIONS } from '../utils/organization-role.utils';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { useParamsProfileId } from 'common/hooks';
import { useDeleteOrganizationRole } from '../hooks';
import ProfileJobTitle from 'features/org-root/components/ProfileJobTitle';
import OrganizationRoleTagsView from './organization-role-tags-view.component';
import OrganizationRoleSkillsView from './organization-role-skills-view.component';
import { CXViewer } from 'libs/toast-ui/components/cx-viewer.component';

const OrganizationRoleCardContent: React.FC<OrganizationRoleCardContentProps> = ({
  profile,
  roleData,
  order,
  isRoleNotRemovable,
}) => {
  const { palette } = useTheme();
  const { openDrawer, closeDrawer } = useDrawer();
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );

  // Admins can view jobFunction of all the profiles, whereas loggedInUser can view just their own.
  // This is a temporary change until ION normalizes the jobFunctions for all the employees.
  // So we are just worried about showing it on frontend, not really whether the data is coming in APIs.
  // See discussion here[https://xconnecttechnologies.atlassian.net/browse/CNXT-1370]
  const { profileId, isLoggedInUser } = useParamsProfileId();
  const isAdmin = profile.isAdmin();

  const { mutate: deleteOrganizationRole, isLoading } = useDeleteOrganizationRole();

  const currentRoleTimestampLabel = `Since ${roleData.startMonth?.slice(0, 3)} ${
    roleData.startYear
  }`;
  const previousRoleTimestampLabel = `${roleData.startMonth?.slice(0, 3)} ${
    roleData.startYear
  } - ${roleData.endMonth?.slice(0, 3)} ${roleData.endYear}`;

  const openOrganizationRoleDrawer = () => {
    openDrawer(DrawerIdEnum.ORGANIZATION_ROLE, {
      organizationRole: roleData,
      profileId,
      isRoleNotRemovable,
      isNewRole: false,
    });
  };

  const openDeleteConfirmation = () => {
    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      headerTitle: 'Delete Role?',
      confirmButtonText: 'Delete',
      subTitle: 'Role data will be permanently deleted',
      confirmButtonHandler: () => {
        deleteOrganizationRole(roleData);
        closeDrawer(DrawerIdEnum.LEAVE_FORM);
      },
    });
  };

  const handleSelect = (item: FloatingMenuItem) => {
    switch (item.value) {
      case 'edit':
        openOrganizationRoleDrawer();
        break;
      case 'delete':
        !isLoading && openDeleteConfirmation();
        break;
    }
  };

  return (
    <>
      {!!order && <Divider sx={{ my: '0.5rem' }} className="divider" />}
      <Stack spacing={2} py={1}>
        <Box>
          <Stack flexDirection="row" justifyContent="space-between" lineHeight="2.25rem">
            <EllipsisText
              variant="button1"
              fontWeight={600}
              color={palette.Text.Headline}
              lineclamp={1}
            >
              {roleData.jobTitle}
            </EllipsisText>
            {permissions.edit && (
              <ThreeDotMenu
                items={
                  roleData.isCurrentRole && isRoleNotRemovable
                    ? DEFAULT_OPTIONS.slice(0, 1)
                    : DEFAULT_OPTIONS
                }
                onSelect={handleSelect}
              />
            )}
          </Stack>
          <Box lineHeight={1}>
            {(isLoggedInUser || isAdmin) && (
              <>
                <ProfileJobTitle
                  variant="caption"
                  sx={{ fontWeight: 500 }}
                  color={palette.Text.Caption}
                  jobTitle={roleData.jobFunction}
                  readonly={!roleData.isCurrentRole}
                />
                <CircleIcon
                  sx={{
                    height: '2.5px',
                    width: '2.5px',
                    lineHeight: 1,
                    verticalAlign: 'middle',
                    marginX: '0.5rem',
                  }}
                />
              </>
            )}
            <Typography variant="caption" color={palette.Text.SubHeadline}>
              {roleData.isCurrentRole
                ? currentRoleTimestampLabel
                : previousRoleTimestampLabel}
            </Typography>
          </Box>
        </Box>
        {roleData?.jobDescription && (
          <Typography variant="body3" component="div" color={palette.Text.Headline}>
            <CXViewer value={roleData.jobDescription} />
          </Typography>
        )}
        <Stack spacing={1.5}>
          <OrganizationRoleTagsView tags={roleData.tags} />
          <OrganizationRoleSkillsView skills={roleData.skills} profile={profile} />
        </Stack>
      </Stack>
    </>
  );
};

export default OrganizationRoleCardContent;
