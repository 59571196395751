import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { OrgChartRoutesEnum } from './org-chart.routes.enum';
import OrgChartContainer from './components/org-chart-container';
import { useSession } from 'common/hooks';
import { NavigateToOrgRoute } from 'common/components/navigation';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { suspendable } from 'common/utils/suspendable';

const ProfileOrgChart = suspendable(
  React.lazy(() => import('./components/profile-org-chart')),
);
const StaticDataOrgChart = suspendable(
  React.lazy(() => import('./components/static-data-org-chart')),
);

const OrgChartRouter: React.FC = () => {
  const { profile } = useSession();

  return (
    <Routes>
      <Route element={<OrgChartContainer />}>
        <Route path={OrgChartRoutesEnum.PROFILE_ROOT}>
          <Route path=":profileId/*" element={<ProfileOrgChart />} />
          <Route path="" element={<ProfileOrgChart />} />
        </Route>

        <Route path={OrgChartRoutesEnum.STATIC_DATA_ROOT}>
          <Route path=":staticDataType/:staticDataId/*" element={<StaticDataOrgChart />} />
          <Route path="" element={<StaticDataOrgChart />} />
        </Route>

        <Route
          path="*"
          element={
            <NavigateToOrgRoute
              route={AppRoutesEnum.ORG_CHART_PROFILE}
              params={{ id: profile?.id || '' }}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default OrgChartRouter;
