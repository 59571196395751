import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { NavigateToOrgProfile } from 'common/components/navigation';
import { ReviewRoutesEnum } from './reviews.constants';
import ReviewContainer from 'features/org-root/components/Review/ReviewContainer';

const ReviewRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={ReviewRoutesEnum.REVIEW} element={<ReviewContainer />} />
      <Route
        path="*"
        element={<NavigateToOrgProfile state={{ resetHistoryIndex: true }} />}
      />
    </Routes>
  );
};

export default ReviewRouter;
