import React from 'react';

import { HorizontalScrollableContainer } from 'common/components/container';
import { Stack, Typography } from 'common/components/material';
/* Last minute changes for Show & Tell */
// import { InstancePermissionsResourcesEnum } from 'common/enum/InstancePermissions.enum';
// import { useInstancePermissions } from 'common/hooks';

import SkillChip from 'features/org-root/components/SkillChip';
import { ProjectCardContentProps } from 'features/projects';
import { TagChips } from 'features/tags/components/tag-chips.component';
import useTheme from '@mui/material/styles/useTheme';

const ProjectCardContent: React.FC<ProjectCardContentProps> = ({
  title,
  description,
  skills = [],
  tags = [],
  project,
  readOnly,
}) => {
  const { palette } = useTheme();

  /* Last minute changes for Show & Tell */
  // const { permissions: projectPermissions } = useInstancePermissions(
  //   InstancePermissionsResourcesEnum.PROJECT,
  //   project,
  // );
  // const { submit: changeStatus } = useUpdateProjectApi();
  // const handleStatusChangeProject = (status: string) => {
  //   changeStatus({ ...project, status });
  // };

  const scrolledSkillsElement = (
    <Stack
      py={0.5}
      direction="row"
      spacing={1}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {skills?.map(({ isMatch, ...skill }) => (
        <SkillChip key={skill.id} skill={skill} isSelected={isMatch} />
      ))}
    </Stack>
  );

  return (
    <Stack
      sx={{
        backgroundColor: 'none',
        pb: 1,
        paddingX: 0,
      }}
      flexDirection="row"
      justifyContent="space-between"
    >
      {/* <Stack width={(permissions.edit || projectPermissions.edit) ? { xs: '80%', sm: '88%' } : '100%'}> */}
      <Stack width="100%">
        <Typography variant="body1" fontWeight={500} color={palette.Text.Headline}>
          {title}
        </Typography>
        <Typography variant="body2" color={palette.Text.Paragraph}>{description}</Typography>
        <Stack width="98%" pt={2}>
          <HorizontalScrollableContainer scrolledListElement={scrolledSkillsElement} />
        </Stack>
        <TagChips tags={tags} />
      </Stack>
      {/* Last minute changes for Show & Tell */}
      {/* <Stack>
        {project && projectPermissions.edit && !readOnly && (
          <ProjectStatusDropdown
            status={project?.status}
            onClick={handleStatusChangeProject}
          />
        )}
      </Stack> */}
    </Stack>
  );
};

export default ProjectCardContent;
