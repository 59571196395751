import { useView } from 'common/hooks';
import { RefObject, useEffect, useState } from 'react';

export const useGetScrollableDimensions = (
  itemsToShowInViewPort: number,
  itemsToScroll: number,
  spacing: number,
  containerRef: RefObject<HTMLDivElement>,
) => {
  const { isDesktopView } = useView();
  const [containerWidth, setContainerWidth] = useState(0);
  const itemWidthBuffer = 40;
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const rect = containerRef.current.getBoundingClientRect();
    setContainerWidth(rect?.width || 0);
  }, [containerRef]);

  const itemSpacing = spacing * 8; //MUI uses a 8px scaling factor by default.
  const itemWidth =
    (containerWidth - (itemsToShowInViewPort - 1) * itemSpacing) / itemsToShowInViewPort;
  const scrollOffset = (containerWidth * itemsToScroll) / itemsToShowInViewPort + itemSpacing;

  return {
    itemWidth: isDesktopView ? itemWidth : itemWidth - itemWidthBuffer,
    scrollOffset,
  };
};
