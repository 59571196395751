import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { FormTextField } from 'common/components/input';
import { Stack, Typography } from 'common/components/material';
import { FormTextFieldProps } from 'common/types/Form.type';
import { TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';

const ProjectFormTitle: React.FC<FormTextFieldProps> = ({ name = '', ...props }) => {
  const { palette } = useTheme();
  const { watch } = useFormContext();
  const value = watch(name) || '';
  const [focused, setFocused] = useState(false);

  return (
    <Stack>
      <FormTextField
        label="Add Initiative"
        name={name}
        {...props}
        inputProps={{
          maxLength: TITLE_CHARACTER_LIMIT,
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        }}
      />
      {focused && (
        <Typography
          display="block"
          textAlign="right"
          variant="caption"
          sx={{ marginTop: 1, marginBottom: -1 }}
          color={value.length > 0 ? palette.Success : palette.TextFields.HintText}
        >
          {`${value.length} / ${TITLE_CHARACTER_LIMIT} characters`}
        </Typography>
      )}
    </Stack>
  );
};

export default ProjectFormTitle;
