import { useTheme } from '@mui/material/styles';
import { UserAvatar } from 'features/profiles/components/user-avatar';
import { Typography } from 'common/components/material';
import { useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { SingleJoinerCardContentProps } from 'features/feeds';
import { SingleJoinerWelcomeBanner } from '../styles';

export const SingleJoinerCardContent: React.FC<SingleJoinerCardContentProps> = ({
  person,
}) => {
  const { goTo } = useRouter();
  const { profile } = useSession();
  const organization = profile?.getCurrentOrganization();
  const { id, fullName, photo, profileHeadline } = person;
  const { palette } = useTheme();

  const goToProfile = () => {
    goTo(AppRoutesEnum.PROFILE, { profileId: id });
  };

  return (
    <SingleJoinerWelcomeBanner onClick={goToProfile} spacing={2}>
      <UserAvatar src={photo} fullName={fullName} size="xlarge" />
      <Typography
        color={palette.Text.SubHeadline}
        fontWeight={400}
        fontSize={{ xs: '0.875rem', lg: '1.125rem' }}
      >
        Welcome to {organization?.name}
      </Typography>
      <Typography
        color={palette.Text.Headline}
        fontWeight={700}
        fontSize={{ xs: '1.375rem' }}
      >
        {fullName}
      </Typography>
      <Typography
        color={palette.Text.Paragraph}
        fontSize={{ xs: '0.875rem' }}
        textAlign="center"
      >
        {profileHeadline}
      </Typography>
    </SingleJoinerWelcomeBanner>
  );
};
