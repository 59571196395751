export enum OrgRootRoutesEnum {
  ROOT = '/',
  BEHAVIORS = '/behaviors/*',
  PROFILE = '/profile/*',
  HOME = '/home',
  HOME_ALL = '/home/all',
  HOME_FOLLOWING = '/home/following',
  ORG_CHART = 'org-chart/*',
  EXPLORE = '/explore/*',
  ADMIN = '/admin/*',
  REVIEW = '/review/*',
  FEED = '/feed/:feedId',
  STATIC_DATA = 'static-data/:staticDataType/:staticDataId/*',
  COMPANY_OVERVIEW = '/overview/*',
  NOTIFICATIONS = '/notifications/*',
}
