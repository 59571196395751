import React from 'react';

import { useGetOrganizationRole } from '../hooks';
import { useDrawer, useParamsProfileId, useSession } from 'common/hooks';
import { OrganizationRole } from '../types';
import { useGetProfile } from 'features/profiles';
import SearchSkeletonView from 'features/search/components/search-skeleton-view.component';
import { Stack } from 'common/components/material';
import CXAddButton from 'common/components/cx-add-button/cx-add-button.component';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import {
  PageContainerTabContent,
  PageContainerTabHeader,
} from 'common/components/page-container';
import { EntitySectionCard } from 'common/components/entity-section-card';
import OrganizationRoleCardContent from './organization-role-card-content.component';
import { PriorExperiencesView } from 'features/prior-experiences/components';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

const OrganizationRolesView: React.FC = () => {
  const { openDrawer } = useDrawer();
  const { profileId } = useParamsProfileId();
  const { account: user } = useSession();
  const { data: profile, isLoading: isLoadingProfile } = useGetProfile({
    id: profileId,
  });
  const { data, isLoading: isLoadingRoles } = useGetOrganizationRole(
    profileId,
    user.organizationId,
  );
  const isLoading = isLoadingProfile || isLoadingRoles;

  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );

  const currentRoles = data.currentOrganizationRoles || [];
  const previousRoles = data.previousOrganizationRoles || [];
  const roles = [...currentRoles, ...previousRoles];

  const openOrganizationRoleDrawer = () => {
    openDrawer(DrawerIdEnum.ORGANIZATION_ROLE, {
      organizationRole: {},
      profileId,
      isNewRole: true,
    });
  };

  return (
    <PageContainerTabContent isLoading={isLoading} LoaderComponent={SearchSkeletonView}>
      <PageContainerTabHeader title="Experience">
        <CXAddButton
          label="Add Role"
          onClick={openOrganizationRoleDrawer}
          isVisible={permissions.edit}
        />
      </PageContainerTabHeader>
      <EntitySectionCard>
        <Stack style={{ marginTop: 0 }} pb={1}>
          {roles?.map((role: OrganizationRole, index: number) => (
            <OrganizationRoleCardContent
              order={index}
              profile={profile}
              roleData={role}
              isRoleNotRemovable={currentRoles.length === 1}
              key={role.id}
            />
          ))}
          <PriorExperiencesView />
        </Stack>
      </EntitySectionCard>
    </PageContainerTabContent>
  );
};

export default OrganizationRolesView;
