import { styled } from '@mui/material';
import React, { useState } from 'react';

import { Divider, Stack, Typography } from 'common/components/material';
import ReactionIcon from 'common/components/modals/ReactionIcon';
import ReactionRespondersModal from 'common/components/modals/ReactionRespondersModal';
import { useView } from 'common/hooks';
import { SocialInfoContainerProps, useGenerateSocialText } from 'features/social';
import { ReactionEnum } from 'features/reactions';

const StyledSocialInfoLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.SpecialButtons.Like.LabelDefault,
  cursor: 'pointer',
  fontWeight: 500,
  fontSize: '0.75rem',
  '&:hover': {
    color: theme.palette.Text.Headline,
  },
}));

const SocialInfoContainer: React.FC<SocialInfoContainerProps> = ({
  reactableId,
  totalReactions,
  isReactedByLoggedInUser = false,
  totalComment,
  onCommentLinkClick,
}) => {
  const { isDesktopView } = useView();
  const [openReactionDialog, setOpenReactionDialog] = useState(false);
  const { getReactionText, getCommentsText } = useGenerateSocialText();
  const hasComment = totalComment > 0;
  const likes = getReactionText(totalReactions, isReactedByLoggedInUser);

  if (totalReactions === 0 && totalComment === 0) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        {likes && (
          <>
            <ReactionIcon reactionType={ReactionEnum.LIKE} />
            <StyledSocialInfoLink onClick={() => setOpenReactionDialog(true)} variant="body3">
              {likes}
            </StyledSocialInfoLink>
          </>
        )}
        {likes && hasComment && <Divider orientation="vertical" variant="middle" flexItem />}
        {hasComment && (
          <>
            {!isDesktopView && <ReactionIcon reactionType={ReactionEnum.COMMENT} />}
            <StyledSocialInfoLink variant="body3" onClick={onCommentLinkClick}>
              {getCommentsText(totalComment)}
            </StyledSocialInfoLink>
          </>
        )}
      </Stack>
      {openReactionDialog && (
        <ReactionRespondersModal
          reactableId={reactableId}
          type={ReactionEnum.LIKE}
          open={openReactionDialog}
          onClose={() => setOpenReactionDialog(false)}
        ></ReactionRespondersModal>
      )}
    </>
  );
};

export default SocialInfoContainer;
