/**
 * This feature is a super set of System features (Domain Entities) as well as the UI(view) features
 */
export enum FeaturesEnum {
  FEEDS = 'FEEDS',
  POSTS = 'POSTS',
  OPPORTUNITIES = 'OPPORTUNITIES',
  PRAISES = 'PRAISES',
  COMPANY_UPDATES = 'COMPANY_UPDATES',
  ORGANIZATION_EVENTS = 'ORGANIZATION_EVENTS',
  VIDEOS = 'VIDEOS',
  WINS = 'WINS',
  COMMENTS_AND_REACTIONS = 'COMMENTS_AND_REACTIONS',
  QUICK_LINKS = 'QUICK_LINKS',
  PROFILE_QUICK_LINKS = 'PROFILE_QUICK_LINKS',
  ORG_CHART = 'ORG_CHART',
  BEST_OF = 'BEST_OF',
  EXPERIENCE = 'EXPERIENCE',
  PRINCIPLES_US = 'PRINCIPLES_US',
  SKILLS = 'SKILLS',
  ASPIRATIONS = 'ASPIRATIONS',
  PROFILES = 'PROFILES',
  ORG_PAGE = 'ORG_PAGE',
  FOLLOW = 'FOLLOW',
  MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS',
  BIOGRAPHY = 'BIOGRAPHY',
  PROFILE_FEEDS = 'PROFILE_FEEDS',
  ADMIN_SETTINGS = 'ADMIN_SETTINGS',
  STATIC_DATA_PAGES = 'STATIC_DATA_PAGES',
  STATIC_DATA_PAGES_ABOUT = 'STATIC_DATA_PAGES_ABOUT',
  TASK_COMPLETION = 'TASK_COMPLETION',
  OWN_PROFILE = 'OWN_PROFILE',
  OTHER_PROFILE = 'OTHER_PROFILE',
  GROUPS = 'GROUPS',
  PAGE_ADMIN = 'PAGE_ADMIN',
  KEY_CONTACTS = 'KEY_CONTACTS',
  RELATED_PAGES = 'RELATED_PAGES',
  TAGGINGS = 'TAGGINGS',
  /** UI Features (Composite features) */
  SEARCH_SUGGESTION = 'SEARCH_SUGGESTION',
  USER_SETTINGS = 'USER_SETTINGS',
  CONTENT_CREATION_WIDGET = 'CONTENT_CREATION_WIDGET',
}
