import React from 'react';

import { Stack } from 'common/components/material';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { getString } from 'common/utils/app.utils';

import { useView } from 'common/hooks';
import SendFeedbackButton from './SendFeedbackButton';
import { Praise } from 'features/praise';
import { ProjectCardHeaderProps } from 'features/projects';

const ProjectCardHeader: React.FC<ProjectCardHeaderProps> = ({
  project,
  disableFeedback,
}) => {
  const { isMobileView } = useView();
  const { owner } = project;
  const ownerId = getString(owner, 'id');
  const { permissions: praisePermissions } = useInstancePermissions<Partial<Praise>>(
    InstancePermissionsResourcesEnum.PRAISE,
    {
      receiver: {
        id: ownerId,
      },
    },
  );

  return (
    <>
      {praisePermissions.create && !disableFeedback && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          ml={1}
          sx={{ height: isMobileView ? 30 : 48 }}
        >
          <SendFeedbackButton person={owner} project={project} />
        </Stack>
      )}
    </>
  );
};

export default ProjectCardHeader;
