import { styled } from '@mui/material';
import { Button } from 'common/components/material';

export const OrgStepBackButton = styled(Button)(() => ({
  '&': { marginBottom: 1 },
  '&.MuiButton-sizeSmall': {
    fontSize: '0.8rem',
  },
}));

export const OrgStepSaveButton = styled(Button)(() => ({
  '&': {
    paddingLeft: 15,
    paddingRight: 15,
  },
  '&.MuiButton-sizeSmall': {
    fontSize: '0.8rem',
  },
}));
