import { useTheme } from '@mui/material/styles';

import { Box, Typography } from '../material';
import CXTabLink from './cx-tab-link.component';
import CXTabScrollButton from './cx-tab-scroll-button.component';
import { NoBackgroundTab, NoBackgroundTabs, StyledTab, StyledTabs } from './cx-tabs.style';
import { CXTabItem } from './cx-tabs.type';

export const CXTabs: React.FC<{
  items: CXTabItem[];
  activeTab?: string;
  fullWidth?: boolean;
  noBackground?: boolean;
  tabContainerStyle?: Object;
  pt?: string;
}> = ({
  items,
  activeTab,
  fullWidth = true,
  pt = 2,
  tabContainerStyle = {},
  noBackground,
}) => {
  const { palette } = useTheme();

  if (!items) {
    return <></>;
  }

  let TabsComponent = StyledTabs;
  let TabComponent = StyledTab;

  if (noBackground) {
    TabsComponent = NoBackgroundTabs;
    TabComponent = NoBackgroundTab;
  }

  return (
    <Box sx={{ pt, backgroundColor: palette.Background, pointerEvents: 'all' }}>
      <TabsComponent
        additionalStyle={tabContainerStyle}
        value={activeTab}
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={CXTabScrollButton}
        className={fullWidth ? 'full-width' : undefined}
      >
        {items &&
          items.map((item) => (
            <TabComponent
              key={item.value}
              label={
                <Typography variant="caption" fontWeight={500}>
                  {item.label}
                </Typography>
              }
              value={item.value}
              to={item.to}
              state={item.state}
              component={CXTabLink}
              disabled={item.disabled}
              className={fullWidth ? 'full-width' : undefined}
            />
          ))}
      </TabsComponent>
    </Box>
  );
};
