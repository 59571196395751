import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled, useTheme } from '@mui/material';
import { find, get } from 'lodash';

import { VerticalScrollableContainer } from 'common/components/container';
import { FormTextField, RecommendedSkills, SkillSelector } from 'common/components/input';
import { Card, Stack } from 'common/components/material';
import { CardBoxShadow, CardContent } from 'common/components/material/Card';
import { useDrawer, useForm, useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import {
  DetailedReview,
  REVIEW_SUBMIT_DRAWER_SUBTITLE,
  ReviewSubmitFormProps,
  ReviewSubmitFormSchema,
  ReviewSubmitFormType,
  useGetProfileReviews,
  useGetReviewDetails,
  useSubmitReview,
} from 'features/reviews';
import { Skill } from 'features/skills';

import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import ReviewRatingsPicker from './ReviewRatingsPicker';
import ReviewSubmitActionButtons from './ReviewSubmitActionButtons';
import ReviewSubmitFormLabel from './ReviewSubmitFormLabel';

const SkillSelectorStyle = {
  marginBottom: 1,
};

const SkillsContainer = styled('div')({
  marginBottom: 20,
});

const ReviewSubmitForm: React.FC<ReviewSubmitFormProps> = ({
  reviewId,
  readOnly = false,
  formChanged,
}) => {
  const {
    data: review,
    isLoading: isReviewLoading,
    refetch: refetchReviewDetails,
  } = useGetReviewDetails(reviewId);
  const { goTo } = useRouter();
  const { palette } = useTheme();
  const { isDesktopView } = useView();
  const { openDrawer, closeDrawer } = useDrawer();
  const { isLoading: reviewSubmitting, mutateAsync: submitReview } = useSubmitReview(
    review?.id,
  );
  const { refetch: refetchProfileReviews } = useGetProfileReviews(review?.reviewee?.id);

  const form = useForm<ReviewSubmitFormType>({
    defaultValues: {
      highStdSkills: review.highStdSkills,
      lowStdSkills: review.lowStdSkills,
      rating: review.rating,
      comment: review.comment,
    },
    resolver: yupResolver(ReviewSubmitFormSchema),
  });
  const { getValues, setValue, reset, formState } = form;
  const { isDirty, isValid } = formState;
  const reviewFormData = getValues();

  const [highStdSkills, setHighStdSkills] = useState<Skill[]>(review.highStdSkills || []);
  const [lowStdSkills, setLowStdSkills] = useState<Skill[]>(review.lowStdSkills || []);
  const [rating, setRating] = useState<number | undefined>(review.rating);

  useEffect(() => {
    formChanged(isDirty);
  }, [isDirty, formChanged]);

  const openSubmitDrawer = () => {
    openDrawer(DrawerIdEnum.SUBMIT_FORM, {
      subtitle: REVIEW_SUBMIT_DRAWER_SUBTITLE,
      confirmButtonHandler: onConfirm,
    });
  };

  const onConfirm = () => {
    closeDrawer(DrawerIdEnum.SUBMIT_FORM);
    onSubmit();
  };

  const onDiscard = () => {
    reset();
    setHighStdSkills([]);
    setLowStdSkills([]);
    setRating(undefined);
  };

  const onSubmit = async () => {
    await submitReview({ ...reviewFormData, revieweeId: get(review, 'reviewee.id') });
    onDiscard();

    goTo(AppRoutesEnum.PROFILE, { profileId: review?.reviewee?.id });

    // Refetch Reviews list on profile cache and review detail API cache to get latest status and data
    refetchProfileReviews();
    refetchReviewDetails();
  };

  const handleRatingSelect = (rating: number) => {
    setValue('rating', rating, { shouldDirty: true });
    setRating(rating);
  };

  const handleHighStdSkillsSelect = (newHighStdSkills: Skill[]) => {
    setHighStdSkills(newHighStdSkills);
    const newLowStdSkills = lowStdSkills.filter(
      (skill) => !find(newHighStdSkills, { id: skill.id }),
    );
    setLowStdSkills(newLowStdSkills);
    setValue('highStdSkills', newHighStdSkills);
    setValue('lowStdSkills', newLowStdSkills);
  };

  const handleLowStdSkillsSelect = (newLowStdSkills: Skill[]) => {
    setLowStdSkills(newLowStdSkills);
    const newHighStdSkills = highStdSkills.filter(
      (skill) => !find(newLowStdSkills, { id: skill.id }),
    );
    setHighStdSkills(newHighStdSkills);
    setValue('lowStdSkills', newLowStdSkills);
    setValue('highStdSkills', newHighStdSkills);
  };

  const getRevieweeFirstName = (review: DetailedReview) => {
    return get(review, 'reviewee.firstName') || '';
  };

  return (
    <Card boxShadow={CardBoxShadow.SHADOW4}>
      <CardContent sx={{ padding: 0 }}>
        <VerticalScrollableContainer disableGutters maxHeight={isDesktopView ? '84vh' : ''}>
          <Stack sx={{ backgroundColor: palette.Container.Bg }}>
            <FormProvider {...form}>
              <form noValidate>
                <ReviewSubmitFormLabel label="Skills Review" />
                <Stack gap={1} margin={2}>
                  <SkillsContainer>
                    <SkillSelector
                      name="highStdSkills"
                      label="Skills demonstrated to a high standard"
                      readOnly={readOnly}
                      value={highStdSkills}
                      onChange={handleHighStdSkillsSelect}
                      sx={SkillSelectorStyle}
                    />
                    {!readOnly && (
                      <RecommendedSkills
                        name="highStdSkills"
                        label="Suggestions"
                        selectedSkills={highStdSkills}
                        onChange={handleHighStdSkillsSelect}
                      />
                    )}
                  </SkillsContainer>
                  <SkillsContainer>
                    <SkillSelector
                      name="lowStdSkills"
                      label="Add skills needing improvement"
                      readOnly={readOnly}
                      value={lowStdSkills}
                      onChange={handleLowStdSkillsSelect}
                      sx={SkillSelectorStyle}
                    />
                    {!readOnly && (
                      <RecommendedSkills
                        name="lowStdSkills"
                        label="Suggestions"
                        selectedSkills={lowStdSkills}
                        onChange={handleLowStdSkillsSelect}
                      />
                    )}
                  </SkillsContainer>
                </Stack>
                <ReviewSubmitFormLabel
                  label={`How was ${getRevieweeFirstName(review)}'s Performance?`}
                />
                <Stack gap={2} margin={2}>
                  <ReviewRatingsPicker
                    selectedRating={rating}
                    onRatingsSelected={handleRatingSelect}
                    readOnly={readOnly}
                  />
                  <FormTextField
                    name="comment"
                    label="Add Comments"
                    multiline
                    rows={10}
                    sx={{ mb: 4, '& .MuiOutlinedInput-input': { fontSize: '0.875rem' } }}
                    disabled={readOnly}
                    value={review?.comment}
                  />

                  {!readOnly && (
                    <ReviewSubmitActionButtons
                      isDiscardDisabled={!isDirty || isReviewLoading || reviewSubmitting}
                      isSubmitDisabled={!isValid || isReviewLoading || reviewSubmitting}
                      onDiscard={onDiscard}
                      onSubmit={openSubmitDrawer}
                    />
                  )}
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </VerticalScrollableContainer>
      </CardContent>
    </Card>
  );
};

export default ReviewSubmitForm;
