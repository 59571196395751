import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { CREATE_ORGANIZATION_ROLE_STEPS } from 'features/organization-roles/configs/create-organization-role-steps.config';
import { Step } from 'common/components/navigation/Stepper';
import { Profile } from 'features/profiles/models/profile.model';
import { CREATE_PROFILE_STEPS } from '../configs/create-profile-steps.config';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const useCreateProfileSteps = (hasProfile: boolean, profile: Profile, isProfileFetching: boolean = false) => {
  const skillPermission = useFeatureAccess(FeaturesEnum.SKILLS);
  const aspirationPermission = useFeatureAccess(FeaturesEnum.ASPIRATIONS);
  const experiencePermission = useFeatureAccess(FeaturesEnum.EXPERIENCE);
  const winPermission = useFeatureAccess(FeaturesEnum.WINS);

  const getSteps = () => {
    const steps: Step[] = [];

    if (isProfileFetching) {
      return steps;
    }

    if (isEmpty(profile?.fullName)) {
      steps.push(CREATE_PROFILE_STEPS.FULLNAME);
    }

    if (!profile?.isOnboarded && skillPermission.canWrite) {
      steps.push(CREATE_PROFILE_STEPS.ENDORSEMENTS);
    }

    aspirationPermission.canWrite && steps.push(CREATE_PROFILE_STEPS.ASPIRATIONS);
    experiencePermission.canWrite && steps.push(CREATE_ORGANIZATION_ROLE_STEPS.JOB_FUNCTION);
    experiencePermission.canWrite && steps.push(CREATE_ORGANIZATION_ROLE_STEPS.ROLE);

    if (hasProfile && !profile?.photo) {
      steps.push(CREATE_PROFILE_STEPS.PROFILE_PHOTO);
    }

    winPermission.canWrite && steps.push(CREATE_PROFILE_STEPS.WIN);

    const transformedSteps = steps.map((step: any, index: number) => {
      if (index === steps.length - 1) {
        return { ...step, buttonLabel: 'Create Profile' };
      }
      return step;
    });

    return transformedSteps;
  };

  const hasSteps = () => {
    const steps = getSteps();
    return steps.some((step) => !!step.requiredTouchedFields?.length)
  }

  // get steps on first render only
  return {
    // eslint-disable-next-line
    steps: useMemo(() => getSteps(), [hasProfile]),
    // eslint-disable-next-line
    hasSteps: useMemo(() => hasSteps(), [hasProfile]),
  };
};
