import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';

import SetupContainer from 'common/components/container/SetupContainer';
import { ResponsiveHeaderBar } from 'common/components/header';
import { OnboardingBackground } from 'common/components/image';
import { Box, CircularProgress, Grid, Stack } from 'common/components/material';
import { NavigateToOrgRoute, Stepper } from 'common/components/navigation';
import { useForm, useMergeSchemas, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import queryClient from 'common/utils/QueryClient';
import {
  CurrentOrganizationRoleSchema,
  LatestOrganizationRoleSchema,
  useGetOrganizationRole,
} from 'features/organization-roles';
import { CREATE_PROFILE_DEFAULT_VALUES } from './create-profile.constants';
import { useCreateProfile, useCreateProfileSteps } from './hooks';
import { CreateCompanyProfileSchema, CreateProfileSchema } from './schemas';
import { CreateProfileForm } from './types';
import { extractLocationIQDetailsFromWorkLocation } from 'features/static-data/utils';

const StyledCreateProfileContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Background,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledOnboardingBackground = styled(OnboardingBackground)({
  width: '100%',
});

const CreateProfile: React.FC = () => {
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const {
    organizationIdOnSession: organizationId,
    refetchAccount,
    refetchProfile,
    hasProfile,
    profile,
    isAccountFetching,
    isProfileFetching,
  } = useSession();

  const organizationProfile = profile?.getOrganizationProfile({
    organizationId,
  });

  const isOnboarded = organizationProfile?.isOnboarded || false;
  const isGuestProfile = organizationProfile?.isGuestProfile || false;

  const includeProfileSchema = !hasProfile;
  const includeCompanyProfileSchema = !isOnboarded;
  const schema = useMergeSchemas(
    [
      CreateProfileSchema,
      CreateCompanyProfileSchema,
      CurrentOrganizationRoleSchema,
      LatestOrganizationRoleSchema,
    ],
    [includeProfileSchema, includeCompanyProfileSchema, true, true],
  );

  const form = useForm<CreateProfileForm>({
    mode: 'onTouched',
    defaultValues: {
      ...CREATE_PROFILE_DEFAULT_VALUES,
      companyId: organizationId,
      startYear: new Date().getUTCFullYear(),
      lateststartYear: new Date().getUTCFullYear(),
    },
    resolver: yupResolver(schema),
  });

  const { isFetching: isOrganizationRoleFetching } = useGetOrganizationRole(
    profile?.id,
    organizationId,
    {
      onSuccess: (organizationRoles) => {
        const organizationRole = _.get(organizationRoles, 'data.currentOrganizationRoles[0]');
        form.setValue('organizationRoleId', organizationRole?.id);
        form.setValue('companyId', organizationRole?.organizationId || organizationId);
        form.setValue('jobTitle', organizationRole?.jobTitle || '');
        form.setValue('jobFunction', organizationRole?.jobFunction || null);
        form.setValue('jobDescription', organizationRole?.jobDescription || '');
        form.setValue('startMonth', organizationRole?.startMonth || '');
        form.setValue(
          'startYear',
          organizationRole?.startYear || new Date().getUTCFullYear(),
        );
        form.setValue('skills', organizationRole?.skills || []);
        form.setValue(
          'department',
          organizationRole?.department ||
          profile?.organizationProfile?.data?.department ||
          null,
        );
        form.setValue(
          'division',
          organizationRole?.division || profile?.organizationProfile?.data?.division || null,
        );
        form.setValue(
          'products',
          organizationRole?.products ||
          (profile?.organizationProfile?.data?.product
            ? [profile?.organizationProfile?.data?.product]
            : []),
        );

        const workLocation = profile?.organizationProfile?.data?.workLocation;
        const location = workLocation
          ? extractLocationIQDetailsFromWorkLocation(workLocation)
          : null;

        form.setValue('location', organizationRole?.location || location);
      },
      enabled: !!profile?.id && !!organizationId,
    },
  );
  const { submit, isLoading } = useCreateProfile({
    showSuccessSnackbar: true,
    onSuccess: () => {
      queryClient.clear();
      refetchAccount();
      refetchProfile();
    },
  });

  const { steps, hasSteps } = useCreateProfileSteps(hasProfile, profile);

  if (isAccountFetching || isProfileFetching || isOrganizationRoleFetching || isLoading) {
    return (
      <StyledCreateProfileContainer>
        <Stack height="100%">
          <CircularProgress sx={{ alignSelf: 'center' }} size={50} />
        </Stack>
      </StyledCreateProfileContainer>
    );
  }

  if (isOnboarded || isGuestProfile || !hasSteps) {
    return <NavigateToOrgRoute route={AppRoutesEnum.HOME} />;
  }

  const handleSubmit = (data: CreateProfileForm) => {
    data.profileId = profile?.id;
    data.organizationProfileId = organizationProfile?.id;
    data.firstName = data?.firstName || profile?.firstName;
    submit(data);
  };

  const validate = (fieldOrFields: string | string[]) => {
    // form is typed around CreateProfileForm, while the Stepper is not
    // generic and instead only supports string/strings; we will go with
    // the "shut up, compiler, I know what I am doing" approach for now
    // but we should really try and step up our type-system game a bit
    // (^20221018-JJXU44KEJM)
    type ValidatableField = keyof CreateProfileForm;
    return form.trigger(fieldOrFields as ValidatableField | ValidatableField[]);
  };

  return (
    <StyledCreateProfileContainer>
      <ResponsiveHeaderBar showAllMenuItems={false} />
      <SetupContainer sx={{ flexGrow: 1, padding: 0 }}>
        <Grid container direction="row" spacing={0} alignItems="center" height="100%">
          <Grid item xs={12} lg={6} pr="5rem" display={{ xs: 'none', lg: 'initial' }}>
            <StyledOnboardingBackground />
          </Grid>
          <Grid item xs={12} lg={6} alignSelf={{ xs: 'start', lg: 'initial' }}>
            <FormProvider {...form}>
              <Box>
                <Stepper
                  currentStepNumber={currentStepNumber}
                  steps={steps}
                  submitHandler={form.handleSubmit(handleSubmit)}
                  disableButton={isLoading}
                  handleStepChange={setCurrentStepNumber}
                  validate={validate}
                />
              </Box>
            </FormProvider>
          </Grid>
        </Grid>
      </SetupContainer>
    </StyledCreateProfileContainer>
  );
};

export default CreateProfile;
