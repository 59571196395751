import React from 'react';

import { StepContainer } from 'common/components/container';
import { useSession } from 'common/hooks';
import ProfilePictureUploader from 'features/profiles/components/ProfilePictureUploader';

export const ProfilePhotoStep: React.FC = () => {
  const { account, profile } = useSession();
  return (
    <StepContainer
      heading={<>"Finally, upload your profile photo."</>}
      subheading="(Optional) You can add one later from your profile settings."
      minHeight="32rem"
      spacing={4}
    >
      <ProfilePictureUploader
        profileId={account.profileId}
        dropzone
        initialImage={profile.photo}
      ></ProfilePictureUploader>
    </StepContainer>
  );
};
