import { useState } from 'react';
import queryClient from 'common/utils/QueryClient';
import {
  getNextPageParam,
  getTotalCount,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { useSession, useInfiniteQuery } from 'common/hooks';
import { getCommentsApi } from '../comments.service';
import { DEFAULT_PAGE_SIZE } from '../comments.constants';
import { UseInfiniteQueryOptions } from 'common/types/Api.type';

export const useGetFeedComments = (
  commentableId: string,
  size = DEFAULT_PAGE_SIZE,
  options: UseInfiniteQueryOptions = {},
) => {
  const { account: user } = useSession();
  const [pageSize, setPageSize] = useState<number>(size);

  const getComments = (params: any) => {
    const page = params.pageParam?.page || 1;

    return getCommentsApi({
      page,
      size: pageSize,
      commentableId,
    });
  };

  const queryKey = [user?.organizationId, 'comments', commentableId, pageSize];

  const resetPaginatedComments = () => {
    queryClient.resetQueries(queryKey);
  };

  const queryResponse = useInfiniteQuery(queryKey, getComments, {
    enabled: !!commentableId,
    getNextPageParam,
    entityKey: 'comments',
    ...options,
  });

  const fetchAllComments = () => {
    const total = queryResponse.isSuccess ? getTotalCount(queryResponse.data) : pageSize;
    setPageSize(total);
  };

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse.data.pages : [],
    total: queryResponse.isSuccess ? getTotalCount(queryResponse.data) : 0,
    resetPaginatedComments,
    fetchAllComments,
  };
};
