import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Stack, Typography } from 'common/components/material';
import Card, { CardContent } from 'common/components/material/Card';

import { CXChip } from 'common/components/cx-chip';
import { EntitySectionCardProps } from './entity-section-card-props.type';
import { ShareButton } from 'features/social/components/share-button.component';
import { useView } from 'common/hooks';

export const EntitySectionCard: React.FC<EntitySectionCardProps> = ({
  title,
  badgeLabel,
  shareUrl,
  headerSideActionElement = <React.Fragment />,
  children,
}) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();

  return (
    <Card noElevation={true} className="full-width" sx={{ overflow: 'visible' }}>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h6" color={palette.Text.Headline}>
                {title}
              </Typography>
              {!!badgeLabel && <CXChip label={badgeLabel} />}
              {!!shareUrl && (
                <div style={{ marginLeft: '1rem' }}>
                  <ShareButton
                    url={shareUrl}
                    color={palette.Icons.Primary.BgDefault}
                    disableText={!isDesktopView}
                  />
                </div>
              )}
            </Stack>
            <Stack
              flex="1 1 0"
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              {headerSideActionElement}
            </Stack>
          </Stack>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};
