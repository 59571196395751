import React from 'react';
import MUITabs from '@mui/material/Tabs';
import { TabsProps } from './tabs-props.type';

export const Tabs: React.FC<TabsProps> = React.forwardRef((props, ref) => {
  const { children, ...tabsBaseProps } = props;
  return (
    <MUITabs {...tabsBaseProps} ref={ref}>
      {children}
    </MUITabs>
  );
});
