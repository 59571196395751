import React from 'react';
import { SxProps } from '@mui/material';

import { Stack } from 'common/components/material';
import { LargeCircularProgress } from 'common/components/loaders';

export const PageContainerTabContent: React.FC<{
  isLoading?: boolean;
  LoaderComponent?: React.FC;
  sx?: SxProps;
}> = ({ isLoading, LoaderComponent = LargeCircularProgress, children, sx = {} }) => {
  return (
    <Stack spacing={2} sx={sx} data-testid="page-container-tab-content">
      {isLoading ? <LoaderComponent /> : children}
    </Stack>
  );
};
