import { styled } from '@mui/material/styles';

import { Stack } from 'common/components/material';
import { getThemeImagePath } from 'common/utils/file.utils';

export const SingleJoinerWelcomeBanner = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundImage: `url(${getThemeImagePath(
    'NewJoiners',
    theme.palette.mode,
    'NewJoiners.svg',
  )})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '12px',
  padding: '2.5rem',
  cursor: 'pointer',
}));
