import React from 'react';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';

import { Stack, Typography } from 'common/components/material';
import { PageContainerTabHeaderProps } from './page-container-tab-header-props.type';

export const PageContainerTabHeader: React.FC<PageContainerTabHeaderProps> = ({
  title,
  hideTitle,
  titleAdornment = <></>,
  children,
  sx,
  ...props
}) => {
  const { palette } = useTheme();
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      px={{ xs: 1, md: 0 }}
      sx={sx}
      {...props}
    >
      {!hideTitle && (
        <Stack direction="row" alignItems="center" spacing={1} minHeight="2.75rem" flex={1}>
          <Typography variant="h3" color={palette.Text.Headline}>
            {title}
          </Typography>
          {titleAdornment}
        </Stack>
      )}
      {children}
    </Stack>
  );
};
