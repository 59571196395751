import { useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Tooltip } from 'common/components/material';
import { useSession } from 'common/hooks';
import { VisibleTo as VisibleToType } from 'common/types/visible-to.type';

const VisibleTo: React.FC<{ visibleTo?: VisibleToType[] }> = ({ visibleTo = [] }) => {
  const { profile } = useSession();
  const { palette } = useTheme();

  const isTagsVisible = profile.isSuperUser() || profile.isAdmin();

  if (!isTagsVisible || !visibleTo.length) {
    return <></>;
  }

  const visibleToLabel = `Visible To ${visibleTo
    .map((organization) => organization.name)
    .join(', ')}`;

  return (
    <>
      <Tooltip title={visibleToLabel}>
        <InfoOutlinedIcon
          fontSize="small"
          sx={{
            color: palette.Text.Caption,
            width: '1.25rem',
            height: '1.25rem',
          }}
        />
      </Tooltip>
    </>
  );
};

export default VisibleTo;
