import React from 'react';
import { styled, useTheme } from '@mui/material';
import { ThreeDotMenu } from 'common/components/core';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import { PersonLinkWithLabel } from 'common/components/person-link/person-link-with-label.component';
import { Container, Stack, Typography } from 'common/components/material';
import { useSession } from 'common/hooks';
import { getTimeStampString } from 'common/utils/date-time.utils';
import { CommentProps, DEFAULT_COMMENT_OPTIONS, useDeleteComment } from 'features/comments';

const StyledCommentSection = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.CommentBg,
  padding: 12,
  paddingLeft: 10,
  borderRadius: 4,
}));

const Comment: React.FC<CommentProps> = ({
  id,
  author,
  content,
  editedDate,
  commentableId,
  onDelete,
}) => {
  const { id: authorId } = author;
  const { profile } = useSession();
  const { palette } = useTheme();
  const isAdmin = profile?.isAdmin();
  const isLoggedInUserAuthor = profile?.id === authorId;
  const canEditComment = isAdmin || isLoggedInUserAuthor;
  const { mutate: deleteComment } = useDeleteComment();

  const handleSelect = (item: FloatingMenuItem) => {
    switch (item.value) {
      case 'delete':
        deleteComment({ id, commentableId });
        onDelete();
        break;
    }
  };

  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" spacing={0.75} justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <PersonLinkWithLabel profile={author} iconSize="small" textVariant="body3" />
          <Typography variant="caption" color={palette.Text.SubtleText} mx={1}>
            {getTimeStampString(editedDate, 'MMM D')}
          </Typography>
        </Stack>
        <Stack>
          {canEditComment && (
            <ThreeDotMenu items={DEFAULT_COMMENT_OPTIONS} onSelect={handleSelect} />
          )}
        </Stack>
      </Stack>
      <div style={{ marginLeft: '2.125rem' }}>
        <StyledCommentSection disableGutters>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body3">
            {content}
          </Typography>
        </StyledCommentSection>
      </div>
    </Stack>
  );
};

export default Comment;
