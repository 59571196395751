import React, { useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { useHistoryIndex } from 'common/context/hooks/historyIndex';
import { useView } from 'common/hooks';

/**
 * The history index keeps track of location changes. This is used
 * in the generic BackButton component to determine whether or not
 * it should be visible.
 *
 * The scope of this effect is determined by where this component is placed.
 * As long as this component exists, BackButton components without custom
 * routes or click handlers will be affected.
 */
const HistoryIndexManager: React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const { historyIndex, incrementHistoryIndex, decrementHistoryIndex, resetHistoryIndex } =
    useHistoryIndex();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const { isDesktopView } = useView();

  useEffect(() => {
    const state = location.state as any;
    const isMobileTabNavigation = !isDesktopView && state?.tabNavigation;
    /**
     * Scroll to top when we click on a link, but return to the last position when we go back.
     * Also disable scroll to top when switching tabs in small screens since in some cases,
     * such as in the profile page, the tabs and their content start below the initial viewport.
     */
    if (
      !isMobileTabNavigation &&
      (navigationType === 'PUSH' || navigationType === 'REPLACE')
    ) {
      document.querySelector('body')!.scrollTo(0, 0);
    }

    // Ignore search param changes and initial render
    if (currentPath === location.pathname) {
      return;
    }

    setCurrentPath(location.pathname);

    if (state?.resetHistoryIndex) {
      resetHistoryIndex();
    } else if (navigationType === 'PUSH') {
      incrementHistoryIndex();
    } else if (historyIndex && navigationType === 'POP') {
      decrementHistoryIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return <></>;
};

export default HistoryIndexManager;
