import React, { useEffect, useRef } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Stack } from 'common/components/material';
import StickyHeaderContainer from '../container/StickyHeaderContainer';
import { CXTabs } from '../cx-tabs/cx-tabs.component';
import { CXTabsRoute } from './cx-tabs-router.type';
import { getTabRoutePath, getTabsConfig } from './cx-tabs-router.utils';
import { useRouteMatch } from './useRouteMatch';
import { CXTabItem } from '../cx-tabs/cx-tabs.type';

export const CXTabsRouter: React.FC<{
  config: CXTabsRoute[];
  fullWidth?: boolean;
  scrollToTabOnNavigate?: boolean;
  renderInMobileView?: boolean;
  noBackground?: boolean;
  stickyHeader?: boolean;
}> = ({
  config,
  fullWidth,
  scrollToTabOnNavigate,
  renderInMobileView,
  stickyHeader,
  noBackground,
}) => {
  const currentTab = useRouteMatch(config.map((item) => item.path));
  const tabsConfig = getTabsConfig(config) as CXTabItem[];
  const activeItem = config.filter((item) => item.active)[0];

  const shouldDisplayTabs = tabsConfig.length > 1;

  const tabRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll to the top of the tab section when the tab changes
    if (scrollToTabOnNavigate) {
      tabRef?.current?.scrollIntoView();
    }
  }, [currentTab, scrollToTabOnNavigate]);

  const renderHeader = () => {
    if (!shouldDisplayTabs) {
      return <></>;
    }

    const header = (
      <CXTabs
        pt="0"
        items={tabsConfig}
        activeTab={currentTab || activeItem.path}
        fullWidth={fullWidth}
        noBackground={noBackground}
      />
    );

    if (stickyHeader) {
      return (
        <StickyHeaderContainer appBarHeight={renderInMobileView ? 0 : undefined}>
          {header}
        </StickyHeaderContainer>
      );
    }

    return header;
  };

  if (isEmpty(config)) {
    return <></>;
  }

  return (
    <Stack spacing={2} ref={tabRef}>
      {renderHeader()}
      <Routes>
        {activeItem && <Route index element={activeItem.component} />}
        {config.map((item) => (
          <Route key={item.path} path={getTabRoutePath(item)} element={item.component} />
        ))}
      </Routes>
      <Outlet />
    </Stack>
  );
};
