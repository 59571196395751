import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { StepContainer } from 'common/components/container';
import { RecommendedSkills, SkillSelector } from 'common/components/input';
import { Typography } from 'common/components/material';
import { Skill } from 'features/skills/';

const INPUT_NAME = 'endorsements';

export const SkillEndorsementsStep: React.FC = () => {
  const { getValues } = useFormContext();
  const { palette } = useTheme();
  const values = getValues(INPUT_NAME);
  const [selectedSkills, setSelectedSkills] = useState<Array<Skill>>(values);

  return (
    <StepContainer
      heading={<>"What are your top skills?"</>}
      subheading="Add skills you're considered advanced in"
    >
      <SkillSelector
        name={INPUT_NAME}
        label="Add Skills"
        value={selectedSkills}
        onChange={setSelectedSkills}
        formFieldProps={{
          autoFocus: true,
        }}
        headerHelperText={
          <Typography textAlign="right" variant="caption" color={palette.Text.SubtleText}>
            Adding {selectedSkills.length} Skills
          </Typography>
        }
      />
      <RecommendedSkills
        name={INPUT_NAME}
        selectedSkills={selectedSkills}
        onChange={setSelectedSkills}
      />
    </StepContainer>
  );
};
