import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CompanyNameOptionType } from 'features/company-names';
import { CompanyNamesSelector } from 'features/company-names/components';
import { useCreatePriorExperience, useUpdatePriorExperience } from '../hooks';
import { PriorExperienceForm, PriorExperience } from '../types';

export const CompanyNameField: React.FC<{
  priorExperience?: PriorExperience;
  onClose: () => void;
}> = ({ priorExperience, onClose }) => {
  const form = useForm({
    defaultValues: {
      ...priorExperience,
    },
  });
  const { getValues, reset } = form;

  const { mutate: createPriorExperience } = useCreatePriorExperience();
  const { mutate: updatePriorExperience } = useUpdatePriorExperience();

  const handleCloseSelector = () => {
    reset();
    onClose();
  };

  const handleCompanyNameSelect = (data?: CompanyNameOptionType) => {
    const companyNameTitle = data?.title;
    const id = getValues('id');

    if (companyNameTitle) {
      const selectedData: PriorExperienceForm = {
        id,
        companyNameTitle: data?.title,
      };

      // New option will have inputValue
      if (!data?.inputValue) selectedData.companyNameId = data?.id;

      id ? updatePriorExperience(selectedData) : createPriorExperience(selectedData);
    }

    !id && handleCloseSelector();
  };

  return (
    <FormProvider {...form}>
      <form noValidate>
        <CompanyNamesSelector
          name="companyName"
          label=""
          value={priorExperience}
          onChange={handleCompanyNameSelect}
          onClose={handleCloseSelector}
        />
      </form>
    </FormProvider>
  );
};
