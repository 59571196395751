import { useTheme } from '@mui/material';
import { Link } from 'common/components/material';
import Typography, { TypographyProps } from 'common/components/material/Typography';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { getTimeStampString } from 'common/utils/date-time.utils';

export const FeedTimestamp: React.FC<
  TypographyProps & {
    timestamp: string | undefined;
    feedId: string | undefined;
  }
> = ({ timestamp, feedId, ...props }) => {
  const { palette } = useTheme();
  let element = null;

  if (timestamp) {
    element = (
      <Typography
        variant="body4"
        color={palette.Text.SubHeadline}
        paddingLeft={1}
        sx={{ lineHeight: 'unset' }}
        {...props}
      >
        {getTimeStampString(timestamp)}
      </Typography>
    );
  }
  if (element && feedId) {
    element = (
      <Link href={AppRoutesEnum.HOME_FEED} params={{ feedId }}>
        {element}
      </Link>
    );
  }
  return element;
};
