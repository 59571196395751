import { UseQueryOptions } from 'common/types/Api.type';
import { useQuery } from 'common/hooks';
import { getPriorExperienceApi } from '../prior-experience.service';

export const useGetPriorExperience = (
  profileId: string = '',
  organizationId: string = '',
  options: UseQueryOptions = {},
) => {
  const queryKey = [organizationId, profileId, 'prior-experiences'];

  const queryResponse = useQuery(queryKey, () => getPriorExperienceApi(profileId), {
    enabled: !!profileId,
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.priorExperiences,
  };
};
