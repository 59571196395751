import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { TypographyProps } from 'common/components/material/Typography';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';

const ProfileHeadline: React.FC<
  TypographyProps & {
    headline?: string;
    lineclamp?: number;
    variant?: string;
  }
> = ({ headline, lineclamp = 1, variant = 'body3', ...typographyProps }) => {
  const { palette } = useTheme();

  return (
    <EllipsisText
      lineclamp={lineclamp}
      variant={variant}
      color={palette.Text.SubHeadline}
      {...typographyProps}
      sx={{ cursor: 'pointer', ...typographyProps.sx }}
    >
      {headline}
    </EllipsisText>
  );
};

export default ProfileHeadline;
