import { BackButton } from 'common/components/navigation';
import { suspendable } from 'common/utils/suspendable';
import RootContainer from 'features/org-root/components/RootContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrinciplesRoutesEnum } from '../principles.enums';

const PrinciplesAssessmentReports = suspendable(
  React.lazy(() => import('../components/principles-assessment-reports.component')),
);

const OrgPrinciplesRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path={PrinciplesRoutesEnum.REPORTS}
        element={
          <RootContainer spacing={1} fullWidth={true} headerNavButton={<BackButton />}>
            <PrinciplesAssessmentReports />
          </RootContainer>
        }
      />
    </Routes>
  );
};
export default OrgPrinciplesRouter;
