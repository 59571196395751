import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { StepContainer } from 'common/components/container';
import { FormTextField, SkillSelector } from 'common/components/input';
import { Box } from 'common/components/material';
import YearPicker from 'common/components/material/YearPicker';
import { useDeferredState } from 'common/hooks';
import { Skill } from 'features/skills';
import OrganizationRoleStaticDataFormFields from './organization-role-static-data-form-fields.component';
import { OrganizationRoleStaticDataFormFieldsProps } from '../types/organization-role-static-data-form-fields-props.type';
import MonthSelector from 'common/components/month-selector/month-selector.component';

export const OrganizationRoleStep: React.FC<OrganizationRoleStaticDataFormFieldsProps> = ({
  valueFieldPrefix = '',
  hidden = false,
}) => {
  const { getValues } = useFormContext();
  const skills = getValues(`role.${valueFieldPrefix}skills`);
  const [selectedSkills, setSelectedSkills] = useState<Array<Skill>>(skills || []);
  const [startYear, setStartYear] = useDeferredState<number>(
    getValues(`${valueFieldPrefix}startYear`),
  );
  const handleYearInputChange = (newStartYear: number | null) => {
    setStartYear(newStartYear!);
  };

  const hasOrgRole = !isEmpty(getValues('organizationRoleId'));
  const subHeading = hasOrgRole
    ? 'Confirm the details you provided during your last review. If you’ve changed roles, select Save & add new below. You can update this info at any time.'
    : 'You can update your role history at any time from your profile.';

  return (
    <StepContainer
      heading={<>"Share details about your current role."</>}
      subheading={subHeading}
      minHeight={{ lg: '32rem' }}
      sx={hidden ? { display: 'none' } : {}}
    >
      <FormTextField
        autoFocus
        required
        name={`${valueFieldPrefix}jobTitle`}
        label="Current Role"
        placeholder={'e.g. "Head of Client Support for Product ABC"'}
        helperText="Note: this will appear as your profile “headline” and on search results."
      />
      <OrganizationRoleStaticDataFormFields valueFieldPrefix={valueFieldPrefix} />
      <FormTextField
        name={`${valueFieldPrefix}jobDescription`}
        label="Role Summary"
        multiline
        rows={4}
        placeholder="Briefly describe your key activities and responsibilities"
        helperText=" "
      />
      <Box display="flex">
        <MonthSelector
          name={`${valueFieldPrefix}startMonth`}
          label="Role Start Month"
          sx={{ width: '50%', paddingRight: '10px' }}
          helperText=" "
        />
        <YearPicker
          name={`${valueFieldPrefix}startYear`}
          required={true}
          initYear={startYear}
          label="Role Start Year"
          onDateSelected={handleYearInputChange}
          sx={{ width: '50%' }}
          helperText=" "
        />
      </Box>
      <SkillSelector
        name={`${valueFieldPrefix}skills`}
        label="Key Skills"
        value={selectedSkills}
        onChange={setSelectedSkills}
        helperText=" "
      />
    </StepContainer>
  );
};
