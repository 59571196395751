import moment from 'moment';
import React, { useEffect, useState } from 'react';

import FeedCard from 'features/org-root/components/Feed/FeedCard';
import { Person } from 'features/profiles';
import HorizontalScrollablePreviewCards from 'common/components/horizontal-scrollable-preview-card/horizontal-scrollable-preview-cards.component';
import { FeedTypeEnum } from '../enums/feed-type.enum';
import { MultipleJoinersWelcomeBanner } from '../styles';
import { NewJoinersFeedCardProps } from '../types';
import { NewsFeedCardContentSkeleton } from './news-feed-card-content-skeleton.component';
import { SingleJoinerCardContent } from './single-joiner-card-content.component';
import ProfilePreviewCard from 'features/profiles/components/profile-preview-card.component';
import Typography from 'common/components/material/Typography';
import CircularProgress from 'common/components/material/CircularProgress';
import Box from 'common/components/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useGetNewJoiners } from 'features/profiles/hooks/useGetNewJoiners';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import Link from 'common/components/material/Link';

export const NewJoinersFeedCard: React.FC<NewJoinersFeedCardProps> = ({
  feed,
  feedId,
  displaySocialContent = false,
}) => {
  const { palette } = useTheme();
  const { isReactedByLoggedInUser, id, welcomeDate, totalComment, totalReaction } = feed;
  const [newJoiners, setNewJoiners] = useState<Person[]>([]);
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetNewJoiners(id);

  useEffect(() => {
    if (!data?.length) return;

    setNewJoiners(data);
    // eslint-disable-next-line
  }, [isLoading, isFetchingNextPage]);

  const isSingleJoiner = data?.length === 1;

  const renderCardContent = () => {
    if (isLoading || !newJoiners.length)
      return <NewsFeedCardContentSkeleton hideFooter={true} />;

    if (isSingleJoiner) return <SingleJoinerCardContent person={newJoiners[0]} />;

    const header = (
      <MultipleJoinersWelcomeBanner>
        <Typography
          variant="body1"
          fontWeight={500}
          color={palette.Text.Headline}
          lineHeight="1.625rem"
          margin="0 1rem"
        >
          New Joiners
        </Typography>
        <Typography
          variant="body4"
          color={palette.Text.Headline}
          lineHeight="1.125rem"
          margin="0 1rem"
        >
          <Link href={AppRoutesEnum.HOME_FEED} params={{ feedId }}>
            {moment(feed.createdDate).format('DD MMM YYYY')}
          </Link>
        </Typography>
      </MultipleJoinersWelcomeBanner>
    );

    return (
      <HorizontalScrollablePreviewCards
        header={header}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      >
        <Box display="flex" width="fit-content">
          {newJoiners.map((person) => (
            <ProfilePreviewCard profile={person} key={person.id} />
          ))}
          {isFetchingNextPage && (
            <CircularProgress sx={{ alignSelf: 'center', marginX: 1 }} size={50} />
          )}
        </Box>
      </HorizontalScrollablePreviewCards>
    );
  };

  return (
    <FeedCard
      key={feedId}
      headerUser={isSingleJoiner ? newJoiners?.[0] : undefined}
      timestamp={isSingleJoiner ? welcomeDate : undefined}
      feedType={FeedTypeEnum.NEW_JOINER}
      raiseOnHover={!displaySocialContent ? true : false}
      displaySocialContent={displaySocialContent}
      feedId={feedId}
      totalComment={totalComment}
      totalReaction={totalReaction}
      isReactedByLoggedInUser={isReactedByLoggedInUser}
      hideTopDivider={!isSingleJoiner}
    >
      {renderCardContent()}
    </FeedCard>
  );
};
