import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Stack, Typography } from 'common/components/material';
import { FEED_FOOTER_TEXT, FeedCardFooterProps } from 'features/feeds';
import { PersonLinkWithLabel } from 'common/components/person-link/person-link-with-label.component';

const FeedCardFooter: React.FC<FeedCardFooterProps> = ({ feedType, user }) => {
  const { palette } = useTheme();

  return (
    <Stack direction="column" alignItems="left">
      <Typography
        variant="body3"
        color={palette.Text.SubHeadline}
        sx={{ lineHeight: 'unset', paddingRight: 1, paddingBottom: '0.5rem' }}
      >
        {FEED_FOOTER_TEXT[feedType]}
      </Typography>
      <PersonLinkWithLabel profile={user} iconSize="medium" textVariant="body1" />
    </Stack>
  );
};

export default FeedCardFooter;
