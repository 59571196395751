import AddIcon from '@mui/icons-material/Add';
import { Stack } from 'common/components/material';
import { StyledAddIconButton } from './cx-add-icon.styles';

export const CXAddIcon: React.FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isVisible?: boolean;
  size?: 'xsmall' | 'small';
}> = ({ onClick, isVisible = true, size = 'small' }) => {
  if (!isVisible) {
    return <></>;
  }

  return (
    <Stack direction="row-reverse" alignItems="center">
      <StyledAddIconButton size={size} onClick={onClick}>
        <AddIcon fontSize="inherit" />
      </StyledAddIconButton>
    </Stack>
  );
};
