import LocationIcon from '@mui/icons-material/PlaceOutlined';
import React from 'react';
import { useTheme } from '@mui/material/styles';

import { PreviewCard } from 'common/components/preview-card';
import { UserAvatar } from 'features/profiles/components/user-avatar';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { Box, Stack } from 'common/components/material';
import { useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { Person } from 'features/profiles';
import ProfileHeadline from 'features/org-root/components/ProfileHeadline';

const ProfilePreviewCard: React.FC<{
  profile: Person;
  bannerColor?: string;
  userAvatarOverLappingElement?: React.ReactNode;
}> = ({ profile, bannerColor, userAvatarOverLappingElement }) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const { goTo } = useRouter();

  const goToProfile = () => {
    goTo(AppRoutesEnum.PROFILE, { profileId: profile.id });
  };

  const PreviewCardOverlappingElement = () => {
    return (
      <Box display="flex" justifyContent="center" position="relative">
        <UserAvatar
          sx={{
            boxShadow: AppBoxShadows.shadow1,
          }}
          size={isMobileView ? 'mediumLarge' : 'large'}
          src={profile.photo}
        ></UserAvatar>
        {userAvatarOverLappingElement}
      </Box>
    );
  };

  return (
    <PreviewCard
      bannerColor={bannerColor}
      onClick={goToProfile}
      overlappingElement={<PreviewCardOverlappingElement />}
      sx={(theme) => ({
        width: '175px',
        marginRight: '0.5rem',
        [theme.breakpoints.down('md')]: {
          width: '144px',
        },
        cursor: 'pointer',
      })}
    >
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        justifyContent="space-between"
        marginTop="-10%"
      >
        <Stack>
          <EllipsisText
            variant="body3"
            color={palette.Text.Headline}
            fontWeight={500}
            textAlign="center"
            lineclamp={2}
          >
            {profile?.fullName}
          </EllipsisText>
          <ProfileHeadline
            textAlign="center"
            lineclamp={2}
            variant="body4"
            headline={profile?.profileHeadline || '\u00A0'}
          />
        </Stack>
        {profile?.workLocation?.city ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            color={palette.Text.Caption}
            marginTop="1rem"
          >
            <LocationIcon sx={{ fontSize: '1rem' }} />
            <EllipsisText variant="body4" lineclamp={1}>
              {profile.workLocation?.city?.name}
            </EllipsisText>
          </Box>
        ) : (
          '\u00A0'
        )}
      </Box>
    </PreviewCard>
  );
};

export default ProfilePreviewCard;
