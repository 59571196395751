import { useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import queryClient from 'common/utils/QueryClient';
import { deleteCommentApi } from '../comments.service';

export const useDeleteComment = (options: UseMutationOptions = {}) => {
  const { account: user } = useSession();

  return useMutation(({ id }) => deleteCommentApi(id), {
    showSuccessSnackbar: true,
    onSuccess: (result, comment) => {
      queryClient.invalidateQueries([
        user?.organizationId,
        'comments',
        comment.commentableId,
      ]);
    },
    ...options,
  });
};
