import queryClient from 'common/utils/QueryClient';
import { useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deletePriorExperienceApi } from '../prior-experience.service';
import { useGetPriorExperience } from './useGetPriorExperience';

export const useDeletePriorExperience = (
  profileId: string | undefined,
  options: UseMutationOptions = {},
) => {
  const { account: user } = useSession();
  const { refetch: refetchPriorExperience } = useGetPriorExperience(
    profileId,
    user.organizationId,
  );

  return useMutation(deletePriorExperienceApi, {
    showSuccessSnackbar: true,
    ...options,
    onSuccess: () => {
      refetchPriorExperience();
      queryClient.invalidateQueries([user?.organizationId, 'company-names']);
    },
  });
};
