import axios from 'common/utils/axios';

const commentsApi = axios('comments');

export const createCommentApi = ({ ...data }: any) => {
  return commentsApi.post('', data);
};

export const getCommentsApi = (params: any) => {
  const { page, size, commentableId } = params;
  return commentsApi.get('', { params: { page, size, commentableId } });
};

export const deleteCommentApi = (id: string) => {
  return commentsApi.delete(id);
};
