import React from 'react';

import { useDrawer, useParamsProfileId, useSession } from 'common/hooks';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import OrganizationRoleCardContent from './organization-role-card-content.component';
import { useGetProfile } from 'features/profiles';
import { ProfileSeeAllButton } from 'features/profiles/components/profile-see-all-button.component';
import { useGetPriorExperience } from 'features/prior-experiences';
import { EntitySectionCard } from 'common/components/entity-section-card';
import RectangularSkeleton from 'common/components/loaders/RectangularSkeleton';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { OrganizationRole } from '../types';
import { useGetOrganizationRole } from '../hooks';
import { Stack } from 'common/components/material';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { CXAddIcon } from 'common/components/cx-add-icon';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

const OrganizationRoleSectionCard: React.FC = () => {
  const { openDrawer } = useDrawer();
  const { account: user } = useSession();
  const { profileId } = useParamsProfileId();
  const { data: profile, isLoading: profileIsLoading } = useGetProfile({
    id: profileId,
  });
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );

  const { data, isLoading: isLoadingRoles } = useGetOrganizationRole(
    profile?.id,
    user.organizationId,
  );
  const { data: priorExperiences } = useGetPriorExperience(profileId, user.organizationId);

  if (isLoadingRoles || profileIsLoading) return <RectangularSkeleton height="17.75rem" />;

  const currentRoles = data.currentOrganizationRoles;
  const previousRoles = data.previousOrganizationRoles;
  const hasMoreExperiences = !!previousRoles?.length || !!priorExperiences?.length;

  const openOrganizationRoleDrawer = () => {
    openDrawer(DrawerIdEnum.ORGANIZATION_ROLE, {
      organizationRole: {},
      profileId,
      isNewRole: true,
    });
  };

  return (
    <EntitySectionCard
      title="Experience"
      headerSideActionElement={
        <>
          {hasMoreExperiences && (
            <ProfileSeeAllButton
              profileId={profileId}
              path={AppRoutesEnum.PROFILE_EXPERIENCE}
            />
          )}
          <CXAddIcon isVisible={permissions.edit} onClick={openOrganizationRoleDrawer} />
        </>
      }
    >
      <Stack spacing={1} m={0}>
        {currentRoles.map((role: OrganizationRole, index: number) => (
          <OrganizationRoleCardContent
            order={index}
            profile={profile}
            roleData={role}
            isRoleNotRemovable={currentRoles.length === 1}
            key={role.id}
          />
        ))}
      </Stack>
    </EntitySectionCard>
  );
};

export default OrganizationRoleSectionCard;
