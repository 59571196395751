import { styled } from '@mui/material/styles';
import { Stack } from 'common/components/material';
import Paper from 'common/components/material/Paper';
import Button, { ButtonProps } from 'common/components/material/Button';
import { useView, useVirtualKeyboardVisibility } from 'common/hooks';
import React from 'react';

type ResponsiveActionButtonProps = ButtonProps & {
  hideFloatingContainer?: boolean;
};

type FloatingContainerProps = {
  hideBg?: boolean;
};

const FloatingContainer = styled(Paper, {
  shouldForwardProp: (prop) => {
    return prop !== 'hideBg';
  },
})<FloatingContainerProps>(({ hideBg, theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  padding: '8px 16px 24px',
  background: hideBg ? 'none' : theme.palette.Container.Bg,
}));

const ResponsiveActionButton: React.FC<ResponsiveActionButtonProps> = ({
  children,
  hideFloatingContainer,
  ...props
}) => {
  const { isDesktopView } = useView();
  const isVirtualKeyboardOpen = useVirtualKeyboardVisibility();

  const actionButton = (
    <Stack alignItems={isDesktopView ? 'flex-end' : 'center'}>
      <Button btntype="primary" size="large" {...props}>
        {children}
      </Button>
    </Stack>
  );

  if (isDesktopView || isVirtualKeyboardOpen) {
    return actionButton;
  }

  return (
    <FloatingContainer hideBg={hideFloatingContainer}>
      {!isVirtualKeyboardOpen && actionButton}
    </FloatingContainer>
  );
};

export default ResponsiveActionButton;
