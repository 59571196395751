import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, isEmpty } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { FormTextField, StyledForm } from 'common/components/input';
import { Box, Stack, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { useDeferredState, useDrawer, useForm, useSession, useView } from 'common/hooks';
import { TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';

import { CXEditorField } from 'libs/toast-ui';
import { PostFormSchema, PostsFormValue, useCreatePost, useUpdatePost } from 'features/posts';
import { Post, PostDrawerProps } from '../types';

import { Tag } from 'features/tags/types';

import TagSelector from 'features/tags/components/tag-selector.component';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { POST_DRAWER_HELPER } from '../posts.constants';
import CXCheckbox from 'common/components/cx-checkbox/cx-checkbox.component';
import YearPicker from 'common/components/material/YearPicker';
import { getFormattedMonth } from 'common/utils/date-time.utils';
import moment from 'moment';
import MonthSelector from 'common/components/month-selector/month-selector.component';
import { Media, toMediaRequestDto } from 'features/media/types/media.types';
import { MediaLoader } from 'features/media/components/media-uploaders/media-loader.component';
import PublishingProgress from 'common/components/loaders/publishing-progress.component';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureAccessEnum } from 'features/feature-access/enums/feature-access.enum';
import VisibileToSelector from 'common/components/role-access/VisibleToSelector';
import { ContentType } from 'common/enum/ContentType.enum';
import VisibleToOrganizationText from 'features/org-root/components/VisibleToOrganizationText';

const DRAWER_TITLE = 'Post';

export const INIT_PROPS: PostDrawerProps = {
  post: {} as Post,
  selectedTags: [] as Tag[],
  mandatorySelectBob: false,
};

const PostDrawer: React.FC<PostDrawerProps> = ({
  post = {} as Post,
  selectedTags = [] as Tag[],
  mandatorySelectBob = false,
}) => {
  const { profile } = useSession();
  const [isAddToSelfBobChecked, setIsAddToSelfBobChecked] = useState<boolean>(
    mandatorySelectBob || post?.isBestOfBest ? true : false,
  );
  const [accomplishedYear, setAccomplishedYear] = useDeferredState<number>(
    post?.relatedBob ? post?.relatedBob?.accomplishedYear : new Date().getFullYear(),
  );

  const [tags, setTags] = useDeferredState<Tag[]>(selectedTags || post?.tags);
  const { closeDrawer } = useDrawer();
  const { palette } = useTheme();

  const { isMobileView } = useView();
  const { currentOrganization } = useSession();
  const company = currentOrganization();
  const { submit: createPost, isLoading: isCreating } = useCreatePost();
  const { submit: updatePost, isLoading: isUpdating } = useUpdatePost();

  const postId = get(post, 'id');

  const isPostUpdate = !!postId && !mandatorySelectBob;
  const action = isPostUpdate ? 'Update' : 'Create';

  const showBobCheckbox = !postId || post?.creator?.id === profile.id;

  const currentMonth = post?.relatedBob
    ? post?.relatedBob?.accomplishedMonth
    : getFormattedMonth(new Date().getMonth(), 'MMMM');

  const [mediaAttachments, setMediaAttachments] = useState<Media[]>(post?.attachments || []);

  const [isMediaUpdated, setIsMediaUpdated] = useState(false);

  const form = useForm<PostsFormValue>({
    defaultValues: {
      ...post,
      visibleTo: post?.visibleTo || [company],
      accomplishedYear: !!isAddToSelfBobChecked ? accomplishedYear : undefined,
      accomplishedMonth: !!isAddToSelfBobChecked ? currentMonth : undefined,
      addToSelfBob: !!isAddToSelfBobChecked ? true : false,
    },
    mode: 'onChange',
    resolver: yupResolver(PostFormSchema),
  });

  const {
    formState: { isDirty, isValid },
    getValues,
    reset,
    setValue,
    trigger,
  } = form;

  const hasChangedForm = isDirty || isMediaUpdated;
  const shouldDisableActions =
    !isValid || !(hasChangedForm || mandatorySelectBob) || isCreating || isUpdating;

  const onDiscard = () => {
    reset();
    closeDrawer(DrawerIdEnum.POST);
  };

  const onMediaAttachmentsChange = (attachments: Media[]) => {
    setMediaAttachments(attachments);
    setIsMediaUpdated(true);
  };

  const onAddToSelfBob = () => {
    setIsAddToSelfBobChecked((isAddToSelfBobChecked) => {
      let accomplishedYear = undefined;
      if (!isAddToSelfBobChecked) {
        accomplishedYear = new Date().getFullYear();
      }
      setValue('accomplishedMonth', currentMonth);
      setValue('accomplishedYear', accomplishedYear);
      setAccomplishedYear(accomplishedYear);
      return !isAddToSelfBobChecked;
    });
  };

  const handleAccomplishedYearInputChange = (newEndYear: number | null) => {
    setAccomplishedYear(newEndYear!);
  };

  const onSubmit = () => {
    const data = getValues();
    //transform form data to incorporate image
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('tags', JSON.stringify(data.tags ?? []));
    formData.append('visibleTo', JSON.stringify(data.visibleTo.map((v) => v.id)));

    if (isAddToSelfBobChecked) {
      formData.set('addToSelfBob', 'true');
      formData.append('accomplishedMonth', data?.accomplishedMonth!); //should be safe enough because of form validation
      formData.append('accomplishedYear', data?.accomplishedYear!.toString());
    }

    if (!isEmpty(mediaAttachments)) {
      const attachments = [];
      for (const mediaAttachment of mediaAttachments) {
        if (mediaAttachment.file?.file) {
          formData.append('files', mediaAttachment.file.file);
        }
        if (mediaAttachment.thumbnailFile?.file) {
          formData.append('files', mediaAttachment.thumbnailFile.file);
        }
        attachments.push(toMediaRequestDto(mediaAttachment));
      }
      formData.append('attachments', JSON.stringify(attachments));
    }

    if (postId) {
      formData.append('id', postId);
      updatePost(formData);
    } else {
      createPost(formData);
    }
    onDiscard();
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        borderBottom: `1px solid ${palette.Divider}`,
        padding: 2,
      }}
    >
      <Typography
        variant={isMobileView ? 'h4' : 'h2'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {`${action} ${DRAWER_TITLE}`}
      </Typography>
      <VisibleToOrganizationText visibleTo={form.getValues().visibleTo} />
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isCreating || isUpdating}
      isPrimaryActionDisabled={shouldDisableActions}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
      primaryActionLabel={action}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={3} m={2} pb={2}>
            <FormTextField
              name="title"
              label="Headline"
              autoFocus
              inputProps={{
                maxLength: TITLE_CHARACTER_LIMIT,
              }}
            />
            <CXEditorField name="description" placeholder="What would you like to share?" />

            <FeatureGuard feature={FeaturesEnum.TAGGINGS}>
              <Box>
                <TagSelector value={tags} onChange={setTags} />
                <Box mt={1}>
                  <Typography variant="body4" color={palette.Text.SubHeadline} mt={1.5}>
                    <b>Note: </b>
                    {POST_DRAWER_HELPER}
                  </Typography>
                </Box>
              </Box>
            </FeatureGuard>

            {/**
             * Admins can edit a user's posts but they can't add the post to someone elses BOB so not showing them the checkbox
             */}
            <FeatureGuard feature={FeaturesEnum.BEST_OF} permission={FeatureAccessEnum.WRITE}>
              <>
                {showBobCheckbox && (
                  <>
                    <CXCheckbox
                      name="addToSelfBob"
                      value={isAddToSelfBobChecked}
                      onChange={onAddToSelfBob}
                      disabled={mandatorySelectBob}
                      label={
                        <Typography
                          variant="body3"
                          color={palette.Text.Headline}
                          ml="0.375rem"
                        >
                          Add this post as my "Best of Best"
                        </Typography>
                      }
                    />
                    {isAddToSelfBobChecked && (
                      <Stack
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <MonthSelector
                          name="accomplishedMonth"
                          label="Accomplished Month"
                          sx={{ width: '50%', paddingRight: '10px' }}
                          autoFocus={!!postId && !!mandatorySelectBob}
                          onChange={() => {
                            trigger('accomplishedMonth');
                            accomplishedYear && trigger('accomplishedYear');
                          }}
                        />
                        <YearPicker
                          name="accomplishedYear"
                          initYear={accomplishedYear}
                          label="Accomplished Year"
                          onDateSelected={handleAccomplishedYearInputChange}
                          sx={{ width: '50%' }}
                          onYearChange={() => {
                            trigger('accomplishedMonth');
                          }}
                          maxDate={moment().toDate()}
                          minDate={moment().subtract(35, 'years').toDate()}
                        />
                      </Stack>
                    )}
                  </>
                )}
              </>
            </FeatureGuard>
            <VisibileToSelector
              name="visibleTo"
              label="Visible to"
              contentType={ContentType.POSTS}
              value={form.getValues().visibleTo}
              onChange={() => {
                trigger('visibleTo');
              }}
            />
            <Stack>
              <Typography
                variant="body1"
                fontWeight={600}
                pb={1.25}
                color={palette.Text.Headline}
              >
                Attachments
              </Typography>
              <MediaLoader onChange={onMediaAttachmentsChange} media={mediaAttachments} />
            </Stack>
          </Stack>
          {(isCreating || isUpdating) && <PublishingProgress />}
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default PostDrawer;
