import React from 'react';

import { ThreeDotMenu } from 'common/components/core';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';

import { useDrawer, useSession } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { ProjectCardOptionsProps, useDeleteProject } from 'features/projects';
import { DEFAULT_FEED_CARD_OPTIONS } from 'features/feeds';

const ProjectCardOptions: React.FC<ProjectCardOptionsProps> = ({
  project,
  onEdit,
  onOpen,
}) => {
  const { openDrawer, closeDrawer } = useDrawer();
  /**
   * If we open the delete confirmation before project details (tags) are loaded, they won't
   * be passed into the deleteProject function so we are using the meta as a fallback.
   * We use the project tags for cache clearing after a successful deletion.
   */
  const { mutate: deleteProject } = useDeleteProject({ meta: { project } });
  const { profile } = useSession();

  const openDeleteConfirmation = () => {
    const isLoggedInUser = !!project.owner && project.owner?.id === profile?.id;
    const ownernership = isLoggedInUser
      ? 'your profile'
      : project.owner
      ? `${project.owner?.firstName}'s profile`
      : 'your organization';
    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      headerTitle: 'Delete WIN?',
      subTitle: `This will be removed from ${ownernership}`,
      confirmButtonText: 'Delete',
      confirmButtonHandler: () => {
        deleteProject(project);
        closeDrawer(DrawerIdEnum.LEAVE_FORM);
      },
    });
  };

  const handleSelect = (item: FloatingMenuItem) => {
    switch (item.value) {
      case 'edit':
        onEdit();
        break;
      case 'delete':
        openDeleteConfirmation();
        break;
    }
  };

  return (
    <ThreeDotMenu
      data-testid="win-item-options"
      items={DEFAULT_FEED_CARD_OPTIONS}
      onSelect={handleSelect}
      onOpen={onOpen}
      sx={{ p: 0.5 }}
    />
  );
};

export default ProjectCardOptions;
