import { Typography, Stack } from 'common/components/material';
import { styled, useTheme } from '@mui/material/styles';
import { PraiseProjectCardProps } from 'features/praise';

const IconContainer = styled(Stack)({
  marginRight: '0.5rem',
});

const PraiseProjectCard: React.FC<PraiseProjectCardProps> = ({ project }) => {
  const { palette } = useTheme();

  return (
    <Stack direction="row" alignItems="center">
      <IconContainer>
        <img src="/images/projectIconBg900.svg" alt="Project Icon" />
      </IconContainer>
      {project?.title && (
        <Typography variant="caption" fontWeight={500} color={palette.Text.Headline}>
          {project?.title.toLocaleUpperCase()}
        </Typography>
      )}
      {!!project && !project.isActive && (
        <>
          <Typography ml={1} variant="body4" color={palette.Text.SubHeadline}>
            |
          </Typography>
          <Typography
            ml={1}
            variant="body4"
            fontWeight={500}
            color={palette.Text.SubHeadline}
          >
            Deleted
          </Typography>
        </>
      )}
    </Stack>
  );
};
export default PraiseProjectCard;
