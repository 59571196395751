import IconButton from 'common/components/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledAddIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.Icons.Primary.IconFill,
  backgroundColor: theme.palette.Icons.Primary.BgDefault,
  '&:hover': {
    backgroundColor: theme.palette.Icons.Primary.BgHover,
  },
}));
