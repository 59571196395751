import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { NavigateToOrgRoute } from 'common/components/navigation';
import AdminRoutesEnum from './AdminRoutes.enum';

import Admin from '..';
import { suspendable } from 'common/utils/suspendable';
const InviteUsers = suspendable(React.lazy(() => import('../components/InviteUsers')));
const ManageOrganization = suspendable(
  React.lazy(() => import('../components/ManageOrganization')),
);
const ManageOrganizationUsers = suspendable(
  React.lazy(() => import('../components/ManageOrganizationUsers')),
);
const ManageStaticData = React.lazy(() => import('../components/ManageStaticData'));

const AdminRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={AdminRoutesEnum.ADMIN} element={<Admin />}>
        <Route path={AdminRoutesEnum.INVITE_USERS} element={<InviteUsers />} />
        <Route path={AdminRoutesEnum.MANAGE_ORGANIZATION} element={<ManageOrganization />} />
        <Route path={AdminRoutesEnum.MANAGE_USERS} element={<ManageOrganizationUsers />} />
        <Route
          path={AdminRoutesEnum.MANAGE_STATIC_DATA_ROOT}
          element={<ManageStaticData />}
        />
        <Route
          path={AdminRoutesEnum.MANAGE_STATIC_DATA_TYPE}
          element={<ManageStaticData />}
        />
        <Route
          path="*"
          element={<NavigateToOrgRoute route={AdminRoutesEnum.INVITE_USERS} />}
        />
      </Route>
    </Routes>
  );
};

export default AdminRouter;
