import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { Box, Stack, Typography } from 'common/components/material';
import { useRouter, useSession } from 'common/hooks';
import { SvgIconState } from 'common/components/image';

import { StyledListItem } from 'common/components/container/StyledListItem';
import { HoverArrowIcon } from 'common/components/navigation/HoverArrowIcon';
import { ExploreOrganizationTabType } from 'features/org-root/config/explore-organization-tabs.config';
import { EXPLORE_HOME_TABS } from 'features/explore/explore.constants';
import { CXChip } from 'common/components/cx-chip';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import CollapsibleCard from 'common/components/cards/collapsible-card.component';
import { deleteDataGridParams } from 'features/explore';
import { useExploreTabs } from 'features/explore/hooks/useExploreTabs';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

const ExploreTabs: React.FC<{
  tabs?: ExploreOrganizationTabType[];
  title?: string;
  initialSelectedTab?: ExploreTabEnum;
  isConciseView?: boolean;
}> = ({ tabs, title, initialSelectedTab, isConciseView = false }) => {
  const { goTo } = useRouter();
  const { profile } = useSession();
  const isAdmin = profile?.isAdmin();
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const { typography } = useTheme();
  const [searchParams] = useSearchParams();
  const { exploreTabs } = useExploreTabs(tabs ?? EXPLORE_HOME_TABS ?? []);

  if (selectedTab !== initialSelectedTab) {
    setSelectedTab(initialSelectedTab);
  }

  const onItemSelected = (value: string) => {
    let pathname = AppRoutesEnum.EXPLORE as string;
    if (value) {
      pathname += `/${value}`;
    }

    setSelectedTab(value as ExploreTabEnum);
    deleteDataGridParams(searchParams);

    goTo({
      pathname,
      search: searchParams,
    });
  };

  const label = title ?? 'Explore';

  return (
    <CollapsibleCard label={label} isConciseView={isConciseView}>
      <Stack mt={1}>
        {exploreTabs.map(
          ({
            adminOnly,
            value,
            IconComponent,
            defaultIcon = React.Fragment,
            activeIcon = React.Fragment,
            label,
            badgeLabel,
          }) => {
            if (adminOnly && !isAdmin) {
              return null;
            }

            return (
              <StyledListItem
                alignItems="center"
                justifyContent={isConciseView ? 'center' : 'flex-start'}
                key={label}
                direction="row"
                onClick={() => onItemSelected(value)}
                className={selectedTab === value ? 'selected' : ''}
                lineHeight="1rem"
              >
                <Stack flex="1 1 0" direction="row" alignItems="center">
                  <Box className="list-item-icon">
                    {IconComponent ? (
                      <IconComponent />
                    ) : (
                      <SvgIconState DisabledIcon={defaultIcon} ActiveIcon={activeIcon} />
                    )}
                  </Box>
                  {!isConciseView && (
                    <Typography
                      color="inherit"
                      variant="caption"
                      whiteSpace="nowrap"
                      sx={{ paddingLeft: '0.625rem' }}
                    >
                      {label}
                    </Typography>
                  )}
                  {!badgeLabel && !isConciseView && <HoverArrowIcon id="arrow-icon" />}
                </Stack>
                {!!badgeLabel && !isConciseView && (
                  <CXChip label={badgeLabel} sx={{ ...typography.body3 }} />
                )}
              </StyledListItem>
            );
          },
        )}
      </Stack>
    </CollapsibleCard>
  );
};

export default ExploreTabs;
