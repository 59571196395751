import { UseQueryOptions } from 'common/types/Api.type';
import { useQuery, useSession } from 'common/hooks';
import { SearchParams } from 'features/search';
import { getCompanyNamesApi } from '../company-name.service';

export const useGetCompanyNames = (params: SearchParams, options: UseQueryOptions = {}) => {
  const { account: user } = useSession();
  const queryKey = [user?.organizationId, 'company-names'];

  if (params.term) {
    queryKey.push(params.term);
  }

  const queryResponse = useQuery(queryKey, () => getCompanyNamesApi(params), { ...options });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.companyNames,
  };
};
