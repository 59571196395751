import { StepElementProps } from 'features/create-profile/types/step-element-props.type';
import { OrganizationRoleJobFunctionStep } from '../components/organization-role-job-function-step.component';
import OrganizationRoleStepContainer from '../components/organization-role-step-container.component';

export const CREATE_ORGANIZATION_ROLE_STEPS = {
  JOB_FUNCTION: {
    element: <OrganizationRoleJobFunctionStep />,
    fields: ['jobFunction'],
    requiredTouchedFields: ['jobFunction'],
  },
  ROLE: {
    getElement: (props: StepElementProps) => <OrganizationRoleStepContainer {...props} />,
    fields: [
      'jobTitle',
      'department',
      'division',
      'products',
      'location',
      'jobDescription',
      'skills',
      'startMonth',
      'startYear',
    ],
    requiredTouchedFields: ['jobTitle', 'department', 'division', 'location', 'startMonth'],
  },
};
