import { styled, useTheme } from '@mui/material';
import { Button, Typography } from 'common/components/material';

import { CommentButtonProps } from 'features/comments';
import { ReactComponent as CommentIcon } from 'common/svg-icons/comment.svg';
import { useView } from 'common/hooks';

const StyledButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.SpecialButtons.Comment.LabelDefault,
  marginLeft: 5,
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
}));

const CommentButton: React.FC<CommentButtonProps> = ({
  onCommentButtonAction,
  disableText = false,
}) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();
  return (
    <Button
      variant="text"
      size="small"
      onClick={onCommentButtonAction}
      sx={{
        borderRadius: '10px',
        minWidth: !isDesktopView ? '20px' : 'initial',
        '&:hover': {
          backgroundColor: palette.SpecialButtons.Comment.BgHover,
        },
      }}
    >
      <CommentIcon width={16} color={palette.SpecialButtons.Comment.LabelDefault} />
      {isDesktopView && !disableText && (
        <StyledButtonText variant="body3">Comment</StyledButtonText>
      )}
    </Button>
  );
};

export default CommentButton;
