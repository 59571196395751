import { get } from 'lodash';

import queryClient from 'common/utils/QueryClient';
import { useMutation, useParamsProfileId, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createPriorExperienceApi } from '../prior-experience.service';
import { CreatePriorExperienceRequest, PriorExperienceForm } from '../types';
import { useGetPriorExperience } from './useGetPriorExperience';

export const useCreatePriorExperience = (options: UseMutationOptions = {}) => {
  const { profileId } = useParamsProfileId();
  const { account: user } = useSession();
  const { refetch: refetchPriorExperience } = useGetPriorExperience(
    profileId,
    user.organizationId,
    { enabled: !!profileId },
  );

  const mutationResponse = useMutation(createPriorExperienceApi, {
    showSuccessSnackbar: true,
    transformInput: (priorExperienceForm: PriorExperienceForm) => {
      const companyNameId = get(priorExperienceForm, 'companyNameId', null);
      const rawTitle = get(priorExperienceForm, 'companyNameTitle', '');
      const companyNameTitle = companyNameId ? rawTitle : rawTitle?.split('"')[1];

      const body: CreatePriorExperienceRequest = {
        profileId,
        companyNameTitle,
      };
      if (companyNameId) body.companyNameId = companyNameId;

      return body;
    },
    onSuccess: () => {
      if (!profileId) {
        return;
      }
      refetchPriorExperience();
      queryClient.invalidateQueries([user?.organizationId, 'company-names']);
    },
    ...options,
  });
  return mutationResponse;
};
