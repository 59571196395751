import { useTheme } from '@mui/material/styles';
import { Container, Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { formatDate } from 'common/utils/date-time.utils';
import { ReviewDetailsHeaderProps } from 'features/reviews';

const ReviewDetailsHeader: React.FC<ReviewDetailsHeaderProps> = ({
  isLoading,
  reviewerName,
  revieweeName,
  periodStart,
  periodEnd,
}) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();

  return (
    <Container disableGutters sx={{ margin: 0 }}>
      {!isLoading && (
        <>
          <Typography
            variant={isDesktopView ? 'h6' : 'body1'}
            fontWeight="bolder"
            color={palette.Text.Headline}
          >{`${reviewerName}'s Review of ${revieweeName}`}</Typography>
          <Typography variant="caption" color={palette.Text.SubHeadline}>
            {`${formatDate(periodStart, 'MMM DD, YYYY')} - ${formatDate(
              periodEnd,
              'MMM DD, YYYY',
            )}`}
          </Typography>
        </>
      )}
    </Container>
  );
};

export default ReviewDetailsHeader;
