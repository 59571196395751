import { FloatingMenuItem } from 'common/components/core/FloatingMenu';

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_COMMENT_OPTIONS: FloatingMenuItem[] = [
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];
