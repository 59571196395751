import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NavigateToOrgRoute } from 'common/components/navigation';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { OrgRootRoutesEnum } from './org-root.enums';
import { FilterViewTabEnum } from 'common/enum/filter-view-tab.enum';
import { suspendable } from 'common/utils/suspendable';
import PageLoader from 'common/components/loaders/PageLoader';
import ReviewRouter from 'features/reviews/review.router';
import ResponsiveAppBarContainer from 'features/org-root/components/ResponsiveAppBarContainer';
import ProfileRouter from 'features/profiles/routes/profile.router';
import OrgPrinciplesRouter from 'features/principles/routes/org-principles.router';
import OrgChartRouter from 'features/org-chart/org-chart-router';
import AdminRouter from 'features/admin/routes/AdminRouter';
import { useSession } from 'common/hooks';
import { ExperimentalFeatureEnum } from 'features/feature-access/experimental-feature.enum';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeatureAccessEnum } from 'features/feature-access/enums/feature-access.enum';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';

const Home = suspendable(React.lazy(() => import('features/home')));
const Explore = suspendable(React.lazy(() => import('features/explore')));
const FeedView = suspendable(
  React.lazy(() => import('features/org-root/components/Feed/FeedView')),
);
const StaticDataView = suspendable(
  React.lazy(() => import('features/static-data/static-data-view.component')),
);
const SettingsView = suspendable(
  React.lazy(() => import('./components/settings-view.component')),
);

export const OrgRootRouter: React.FC = () => {
  const { hasAccessToExperimentalFeature: hasAccessToFeature } = useSession();
  const { canRead: canFollow } = useFeatureAccess(FeaturesEnum.FOLLOW);

  const defaultOrgHomeRoute = canFollow
    ? AppRoutesEnum.HOME_FOLLOWING
    : AppRoutesEnum.HOME_ALL;

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route element={<ResponsiveAppBarContainer />}>
          <Route
            path={OrgRootRoutesEnum.HOME}
            element={<NavigateToOrgRoute route={defaultOrgHomeRoute} replace={true} />}
          />
          <Route
            path={OrgRootRoutesEnum.HOME_FOLLOWING}
            element={
              <FeatureGuard
                feature={FeaturesEnum.FOLLOW}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <Home selectedTab={FilterViewTabEnum.FOLLOWING} />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.HOME_ALL}
            element={<Home selectedTab={FilterViewTabEnum.ALL} />}
          />
          <Route path={OrgRootRoutesEnum.PROFILE} element={<ProfileRouter />} />
          <Route
            path={OrgRootRoutesEnum.BEHAVIORS}
            element={
              <FeatureGuard
                feature={FeaturesEnum.PRINCIPLES_US}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <OrgPrinciplesRouter />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.ORG_CHART}
            element={
              <FeatureGuard
                feature={FeaturesEnum.ORG_CHART}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <OrgChartRouter />
              </FeatureGuard>
            }
          />
          <Route path={OrgRootRoutesEnum.EXPLORE} element={<Explore />} />
          <Route
            path={OrgRootRoutesEnum.FEED}
            element={
              <FeatureGuard
                feature={FeaturesEnum.FEEDS}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <FeedView />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.STATIC_DATA}
            element={
              <FeatureGuard
                feature={FeaturesEnum.STATIC_DATA_PAGES}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <StaticDataView />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.COMPANY_OVERVIEW}
            element={
              <FeatureGuard
                feature={FeaturesEnum.STATIC_DATA_PAGES}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <StaticDataView />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.ADMIN}
            element={
              <FeatureGuard
                feature={FeaturesEnum.ADMIN_SETTINGS}
                permission={FeatureAccessEnum.WRITE}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <AdminRouter />
              </FeatureGuard>
            }
          />
          <Route
            path={OrgRootRoutesEnum.NOTIFICATIONS}
            element={
              <FeatureGuard
                feature={FeaturesEnum.MANAGE_NOTIFICATIONS}
                permission={FeatureAccessEnum.WRITE}
                fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
              >
                <SettingsView />
              </FeatureGuard>
            }
          />
          <Route path="*" element={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />} />
        </Route>

        {/* Experimental features specific routes: */}
        {hasAccessToFeature(ExperimentalFeatureEnum.X_REVIEW) && (
          <Route path={OrgRootRoutesEnum.REVIEW} element={<ReviewRouter />} />
        )}
      </Routes>
    </Suspense>
  );
};
