import { useLocation } from 'react-router-dom';

export function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();
  const parts = pathname.split('/');
  // Iterate all the pathanme parts, from the last to the first one, until
  // one is found that matches any of the pattern.
  //
  // Most of the times we won't need to scan more than one path segment to find a match;
  // however, sometimes we need different paths to have to the same selcted tab (e.g., we
  // want Archetypes to be selected when we are viewing results (/archetypes), but also
  // when we are completing the assessment (/archetypes/assessment).
  while (parts.length) {
    const tab = parts.pop();
    const matchedPath = patterns.find((pattern: string) => pattern === tab);
    if (matchedPath) return matchedPath;
  }
  return null;
}
