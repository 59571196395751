import { styled, useTheme } from '@mui/material';
import { EmptyReviewImage } from 'common/components/image';
import { Container, Typography } from 'common/components/material';

const StyledEmptyContainer = styled(Container)({
  height: '60vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const EmptyAttachedEntities: React.FC = () => {
  const { palette } = useTheme();

  return (
    <StyledEmptyContainer>
      <EmptyReviewImage />
      <Typography sx={{ mt: 2 }} variant="h3" color={palette.Text.Headline}>
        Nothing here to review
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Looks like there are no Praises and Projects for this review
      </Typography>
    </StyledEmptyContainer>
  );
};

export default EmptyAttachedEntities;
