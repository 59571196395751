import { ReactionEnum } from 'features/reactions';

const ReactionIconSrc = {
  [ReactionEnum.LIKE]: '/images/likeActive.svg',
  [ReactionEnum.COMMENT]: '/images/speechBalloon.svg',
};

const ReactionIcon: React.FC<{ reactionType: ReactionEnum }> = ({ reactionType }) => {
  return <img src={ReactionIconSrc[reactionType]} width={16} alt="icon" />;
};

export default ReactionIcon;
