import { styled } from '@mui/material/styles';
import { Box } from 'common/components/material';

export const Dropzone = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: '1.5rem',
  margin: '0 auto',
  maxWidth: '23rem',
  [theme.breakpoints.down('md')]: {
    maxWidth: '16rem',
  },
  border: `2px dashed ${theme.palette.Stroke.Active}`,
  borderRadius: '0.75rem',
  backgroundColor: theme.palette.Buttons.Secondary.BgDefault,
  color: theme.palette.Buttons.Secondary.SecondaryLabel,
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

export const BaseBadge = styled(Box)(({ theme }) => ({
  width: '1.5rem',
  height: '1.5rem',
  border: `1px solid ${theme.palette.Stroke.Active}`,
  borderRadius: '50%',
  backgroundColor: theme.palette.Badge.BgDefault,
  lineHeight: '2rem',
}));

export const SuccessBadge = styled(BaseBadge)(({ theme }) => ({
  backgroundColor: theme.palette.Success,
  border: 'none',
}));

export const ErrorBadge = styled(BaseBadge)(({ theme }) => ({
  backgroundColor: theme.palette.Failure,
  border: 'none',
}));
