import { useTheme } from '@mui/material/styles';
import { Container, Stack, Typography } from 'common/components/material';
import { ReviewStepProps } from 'features/reviews';

const ReviewStep: React.FC<ReviewStepProps> = ({
  stepNumber,
  stepHeader,
  children,
  isFixed,
  flex = '',
}) => {
  const { palette } = useTheme();

  return (
    <Container sx={{ mt: '1rem', flex, flexDirection: 'column' }} maxWidth={false}>
      <Stack sx={isFixed ? { position: 'fixed', top: '6rem', width: '43vw' } : undefined}>
        <Typography
          variant="caption"
          color={palette.Text.Caption}
        >{`STEP ${stepNumber}`}</Typography>
        <Typography variant="h4" color={palette.Text.Headline} sx={{ mb: 2 }}>
          {stepHeader}
        </Typography>
        {children}
      </Stack>
    </Container>
  );
};

export default ReviewStep;
