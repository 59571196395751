import React from 'react';
import { useParams } from 'react-router-dom';

import { FeedTypeEnum } from 'features/feeds/enums/feed-type.enum';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import FeedCard from '../Feed/FeedCard';
import PraiseCardContent from './PraiseCardContent';
import PraiseCardOptions from './PraiseCardOptions';
import { PraiseFeed, PraiseFeedCardProps } from 'features/praise';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

const PraiseFeedCard: React.FC<PraiseFeedCardProps> = ({
  praise,
  feedId,
  displaySocialContent = true,
  showCommentsInFeedPage,
  readOnly,
  sx,
}) => {
  const {
    receiver,
    feedDate,
    createdDate,
    editedDate,
    totalComment,
    totalReaction,
    isReactedByLoggedInUser,
    recentComment,
    publishedBy,
    visibleTo,
  } = praise;
  const feedType = FeedTypeEnum.PRAISE;
  const { permissions } = useInstancePermissions<PraiseFeed>(
    InstancePermissionsResourcesEnum.PRAISE,
    praise,
  );
  const { profileId } = useParams();

  return (
    <FeedCard
      headerUser={receiver}
      feedType={feedType}
      feedId={feedId}
      timestamp={feedDate || createdDate}
      editedTimestamp={editedDate}
      totalComment={totalComment}
      totalReaction={totalReaction}
      isReactedByLoggedInUser={isReactedByLoggedInUser}
      displaySocialContent={displaySocialContent}
      headerOptions={!readOnly && permissions.edit && <PraiseCardOptions praise={praise} />}
      fullWidthOnMobile={!profileId}
      showCommentsInFeedPage={showCommentsInFeedPage}
      recentComment={recentComment}
      sx={sx}
      publishedBy={publishedBy}
      visibleTo={visibleTo}
    >
      <PraiseCardContent praise={praise} />
    </FeedCard>
  );
};

export default React.memo(PraiseFeedCard);
