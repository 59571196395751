import React from 'react';

import HistoryIndexManager from 'common/components/navigation/HistoryIndexManager';
import { OrgRootRouter } from './org-root.router';

const OrgRoot: React.FC = () => {
  return (
    <>
      <OrgRootRouter />
      <HistoryIndexManager />
    </>
  );
};

export default OrgRoot;
