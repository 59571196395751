enum AdminRoutesEnum {
  ADMIN = '',
  INVITE_USERS = 'invite-users',
  MANAGE_ORGANIZATION = 'manage-organization',
  MANAGE_USERS = 'manage-users',
  MANAGE_STATIC_DATA_ROOT = 'static-data',
  MANAGE_STATIC_DATA_TYPE = 'static-data/:staticDataType',
}

export default AdminRoutesEnum;
