import { useState } from 'react';
import { useTheme } from '@mui/material';

import { Button } from 'common/components/material';
import { useView } from 'common/hooks';
import { StyledShareButtonText, StyledShareIcon } from '../styles/share-button.styles';
import { ShareModal } from 'features/org-root/components/Social/share-modal.component';
import { BtnType } from 'common/components/material/Button';

export const ShareButton: React.FC<{
  disableText?: boolean;
  color?: string;
  btntype?: BtnType;
  url: string;
}> = ({ url, disableText = false, color, btntype = 'tertiary' }) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();

  const [isShareUrlModalOpen, setIsShareUrlModalOpen] = useState<boolean>(false);

  const openModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsShareUrlModalOpen(true);
  };

  const closeModal = () => {
    setIsShareUrlModalOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        btntype={btntype}
        sx={{
          borderRadius: '8px',
          minWidth: !isDesktopView ? '20px' : 'initial',
        }}
        onClick={openModal}
      >
        <StyledShareIcon height={18} width={18} color={color} />
        {!disableText && (
          <StyledShareButtonText
            lineHeight="18px"
            variant="caption"
            color={color || palette.Icons.Tertiary.IconFillPressed}
            fontWeight={btntype === 'tertiary' ? 500 : 700}
          >
            Share
          </StyledShareButtonText>
        )}
      </Button>
      <ShareModal
        isShareUrlModalOpen={isShareUrlModalOpen}
        closeModal={closeModal}
        urlToShare={url}
      />
    </>
  );
};
