import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import ReviewRating from './ReviewRating';

import { ReviewRatingsPickerProps, REVIEW_RATINGS } from 'features/reviews';

const RatingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

const ReviewRatingsPicker: React.FC<ReviewRatingsPickerProps> = ({
  selectedRating = undefined,
  readOnly = false,
  onRatingsSelected,
}) => {
  const [rating, setRating] = useState<number | undefined>(selectedRating);

  useEffect(() => {
    setRating(selectedRating);
  }, [selectedRating]);

  const handleRatingsClick = (rating: number) => {
    setRating(rating);
    onRatingsSelected(rating);
  };

  return (
    <RatingsContainer>
      {REVIEW_RATINGS.map((rate) => (
        <ReviewRating
          key={rate.value}
          image={rate.image}
          ratingValue={rate.value}
          displayText={rate.displayText}
          color={rate.color}
          selectedRating={rating}
          readMode={readOnly}
          onRatingSelection={handleRatingsClick}
        />
      ))}
    </RatingsContainer>
  );
};

export default ReviewRatingsPicker;
