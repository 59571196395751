import { useDrawer } from 'common/hooks';
import QuickActionButton from 'features/org-root/components/QuickActionButton';
import {
  SEND_FEEDBACK_ICON_LINK,
  SEND_FEEDBACK_TITLE,
  SendFeedbackButtonProps,
} from 'features/projects';
import React from 'react';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';

const SendFeedbackButton: React.FC<SendFeedbackButtonProps> = ({ person, project }) => {
  const { openDrawer } = useDrawer();
  const togglePraiseDrawer = (e: React.MouseEvent) => {
    e.stopPropagation();
    const praise = {
      receiver: person,
      relatedProject: project,
    };
    openDrawer(DrawerIdEnum.PRAISE, { praise });
  };

  return (
    <QuickActionButton
      icon={SEND_FEEDBACK_ICON_LINK}
      title={SEND_FEEDBACK_TITLE}
      onClick={(e: React.MouseEvent) => togglePraiseDrawer(e)}
    />
  );
};

export default SendFeedbackButton;
