import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createReactionApi } from '../reactions.service';

export const useCreateSocialReaction = (options: UseMutationOptions = {}) => {
  const mutationResponse = useMutation(createReactionApi, {
    ...options,
  });
  return mutationResponse;
};
