import { isEmpty } from 'lodash';
import { Typography, useTheme } from '@mui/material';

import { HorizontalScrollableContainer } from 'common/components/container';
import { Grid, Stack } from 'common/components/material';
import { useDrawer, useView } from 'common/hooks';
import SkillChip from 'features/org-root/components/SkillChip';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { Profile } from 'features/profiles/models/profile.model';
import { Skill } from 'features/skills';

export const OrganizationRoleSkillsView: React.FC<{ skills: Skill[]; profile: Profile }> = ({
  skills,
  profile,
}) => {
  const { isDesktopView } = useView();
  const { openDrawer } = useDrawer();
  const { palette } = useTheme();

  const handleSkillClick = (skill: Skill) => {
    openDrawer(DrawerIdEnum.SKILLS_ACTION, {
      skill,
      person: profile,
    });
  };

  if (isEmpty(skills)) {
    return <></>;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="caption" color={palette.Text.Caption} fontWeight={500}>
        Key skills
      </Typography>
      <Grid width={isDesktopView ? '90%' : '75%'}>
        <HorizontalScrollableContainer
          scrolledListElement={
            <Stack py={0.5} direction="row" spacing={1}>
              {skills.map(({ ...skill }) => (
                <SkillChip
                  key={skill.id}
                  skill={skill}
                  onClick={handleSkillClick}
                  sx={{ fontSize: '0.75rem' }}
                />
              ))}
            </Stack>
          }
        />
      </Grid>
    </Stack>
  );
};

export default OrganizationRoleSkillsView;
