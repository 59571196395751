import React, { useState } from 'react';

import { Stack, Typography } from 'common/components/material';
import useDrawer from 'common/hooks/useDrawer';
import { ThreeDotMenu } from 'common/components/core';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { useParamsProfileId } from 'common/hooks';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { DEFAULT_FEED_CARD_OPTIONS } from 'features/feeds';
import { useGetProfile } from 'features/profiles';
import { useDeletePriorExperience } from '../hooks';
import { PriorExperience } from '../types';
import { CompanyNameField } from './company-name-field.component';

export const PriorExperienceContent: React.FC<{ priorExperience: PriorExperience }> = ({
  priorExperience,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { profileId } = useParamsProfileId();
  const { openDrawer, closeDrawer } = useDrawer();
  const { data: profile } = useGetProfile({
    id: profileId,
  });
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );

  const { mutate: deletePriorExperience, isLoading } = useDeletePriorExperience(profileId);

  const openDeleteConfirmation = () => {
    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      headerTitle: 'Delete Prior Experience?',
      confirmButtonText: 'Delete',
      subTitle: 'Prior Experience data will be permanently deleted',
      confirmButtonHandler: () => {
        deletePriorExperience(priorExperience);
        closeDrawer(DrawerIdEnum.LEAVE_FORM);
      },
    });
  };

  const handleSelect = (item: FloatingMenuItem) => {
    switch (item.value) {
      case 'edit':
        setEditMode(true);
        break;
      case 'delete':
        !isLoading && openDeleteConfirmation();
        break;
    }
  };

  return (
    <Stack width="100%" mr={2} height="46px" justifyContent="center">
      {editMode ? (
        <Stack ml={1} mr={1}>
          <CompanyNameField
            priorExperience={priorExperience}
            onClose={() => setEditMode(false)}
          />
        </Stack>
      ) : (
        <Stack ml={2} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body3" fontWeight={500}>
            {priorExperience.companyNameTitle}
          </Typography>
          {permissions.edit && (
            <ThreeDotMenu items={DEFAULT_FEED_CARD_OPTIONS} onSelect={handleSelect} />
          )}
        </Stack>
      )}
    </Stack>
  );
};
