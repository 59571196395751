import React from 'react';

import { TypographyProps } from 'common/components/material/Typography';
import { Typography } from 'common/components/material';
import { FontWeightType } from 'common/types/FontWeight.type.';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { CommonStyledLink } from '../links/styles/common-link.style';
import { useTheme } from '@mui/material';
import { useView } from 'common/hooks';

type PersonLinkProps = {
  id: string;
  name?: string;
  variant?: TypographyProps['variant'];
  fontWeight?: FontWeightType | number;
  color?: string;
  sx?: React.CSSProperties;
  readonly?: boolean;
};

export const PersonLink: React.FC<PersonLinkProps> = ({
  id,
  name,
  variant,
  fontWeight = 'normal',
  color,
  sx,
  readonly = false,
}) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();

  if (readonly) {
    return (
      <Typography
        variant={isMobileView ? 'body4' : 'body3'}
        sx={{
          paddingLeft: 1,
          color: palette.Text.SubHeadline,
        }}
      >
        {name}
      </Typography>
    );
  }

  return (
    <CommonStyledLink
      color={color}
      sx={{ fontWeight, ...sx }}
      href={AppRoutesEnum.PROFILE}
      params={{ profileId: id }}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      variant={variant}
    >
      {name}
    </CommonStyledLink>
  );
};
