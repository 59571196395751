import { Button, Stack } from 'common/components/material';
import { useRouter } from 'common/hooks';

export const ProfileSeeAllButton: React.FC<{
  profileId: string;
  path: string;
  state?: Object;
}> = ({ profileId, path, state = {} }) => {
  const { goTo } = useRouter();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    goTo(path, { profileId }, { state: { tabNavigation: true, ...state } });
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Button btntype="label" size="small" onClick={handleClick}>
        See All
      </Button>
    </Stack>
  );
};
