import { useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

import { Button, Stack, TextField, Tooltip, Typography } from 'common/components/material';

import { StyledModal } from 'features/static-data/styles';
import { useState } from 'react';

export const ShareModal: React.FC<{
  isShareUrlModalOpen: boolean;
  closeModal: () => void;
  urlToShare: string;
}> = ({ isShareUrlModalOpen, closeModal, urlToShare }) => {
  const { palette } = useTheme();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  // We need to stop the propagation of event if user click on modal popup
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClose = () => {
    setIsCopied(false);
    closeModal();
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(urlToShare);
    setIsCopied(true);
  };

  return (
    <StyledModal
      open={isShareUrlModalOpen}
      title="Share URL"
      onClick={stopPropagation}
      onClose={handleClose}
      closeButtonEnabled={true}
    >
      <Stack justifyContent="center" padding="1rem">
        <Stack flexDirection="row" alignItems="center">
          <TextField
            onClick={copyUrl}
            autoFocus={true}
            fullWidth={true}
            label="Link Url"
            value={urlToShare}
            InputProps={{ readOnly: true }}
          >
            {urlToShare}
          </TextField>
          <Tooltip title="Copy">
            <div>
              <Button onClick={copyUrl} sx={{ padding: '4px', minWidth: '40px' }}>
                <ContentCopyIcon />
              </Button>
            </div>
          </Tooltip>
        </Stack>
        <Stack alignItems="center" mt={2} mb={1}>
          <Typography
            variant="body4"
            fontSize="1rem"
            display="flex"
            justifyContent="center"
            color={isCopied ? palette.success.light : palette.Text.SubHeadline}
            onClick={copyUrl}
          >
            {isCopied ? 'Copied to clipboard' : 'Click to copy'}
            <Typography
              variant="body4"
              fontSize="1rem"
              mx={1}
              color={isCopied ? palette.success.light : palette.Text.SubHeadline}
              onClick={copyUrl}
            >
              {isCopied && <CheckIcon />}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </StyledModal>
  );
};
