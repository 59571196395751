import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';

import { FormAutocomplete } from 'common/components/input';
import { InputAdornment, Typography } from 'common/components/material';
import { useDebounce } from 'common/hooks';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import { useGetCompanyNames } from '../hooks';
import { CompanyNamesSelectorProps } from '../types';
import { KeyboardEventKeyEnums } from 'common/enum/KeyboardEventKey.enum';

export const CompanyNamesSelector: React.FC<CompanyNamesSelectorProps> = ({
  onClose,
  onChange,
  value,
  ...props
}) => {
  const { palette } = useTheme();
  const [inputText, setInputText] = useState(value?.companyNameTitle || '');
  const debouncedInputValue = useDebounce(inputText);
  const initialValue = value
    ? { id: value?.companyNameId, title: value?.companyNameTitle }
    : undefined;

  const { data, isLoading } = useGetCompanyNames({ term: debouncedInputValue });

  const formFieldProps = {
    fullWidth: true,
    size: 'small' as const,
    InputProps: {
      onBlur: () => {
        onClose();
      },
      endAdornment: (
        <InputAdornment position="end">
          <InlineIconButton onClick={onClose}>
            <CloseIcon color="primary" />
          </InlineIconButton>
        </InputAdornment>
      ),
    },
    inputProps: {
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === KeyboardEventKeyEnums.ENTER) {
          e.stopPropagation();
          e.preventDefault();
        }
      },
    },
  };

  const filterOptions = useCallback(
    (options, params) => {
      const { inputValue } = params;

      // Suggest create new option
      const isExisting = options.some((option: any) => inputValue === option.title);
      if (inputValue !== '' && inputValue !== value?.companyNameTitle && !isExisting) {
        options.push({ inputValue, title: `+ Add "${inputValue}"` });
      }

      return options;
    },
    [value],
  );

  const renderOption = (
    props: any,
    option: any,
    { inputValue }: AutocompleteRenderOptionState,
  ) => {
    const title = option.title;
    const optionKey = option.id || option.inputValue;
    const matches = match(title, inputValue, { insideWords: true });
    const prunedTexts = parse(title, matches);
    const isNewOption = !!option.inputValue;

    return (
      <li {...props} key={optionKey} className={`MuiAutocomplete-option highlight`}>
        <Typography
          variant="body3"
          color={
            isNewOption ? palette.Buttons.Tertiary.LabelDefault : palette.DropdownList.Label
          }
        >
          {prunedTexts.map((item, index) => (
            <span key={index} style={{ fontWeight: item.highlight ? 600 : 400 }}>
              {item.text}
            </span>
          ))}
        </Typography>
      </li>
    );
  };

  return (
    <FormAutocomplete
      {...props}
      freeSolo
      blurOnSelect
      autoFocus
      limit={1}
      value={initialValue}
      options={data || []}
      loading={isLoading}
      formFieldProps={formFieldProps}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onChange={(value) => {
        setInputText('');
        onChange && onChange(value);
      }}
      onInputChange={(e, value, reason) => {
        if (reason === 'reset') return;
        setInputText(value);
      }}
      getOptionLabel={(option) => option.title || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};
