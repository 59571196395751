import React from 'react';

import { Divider, Stack } from 'common/components/material';
import Skeleton from 'common/components/material/Skeleton';
import { useTheme } from '@mui/material/styles';

export const NewsFeedCardContentSkeleton: React.FC<{ hideFooter?: boolean }> = ({
  hideFooter,
}) => {
  const { palette } = useTheme();
  return (
    <>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        paddingBottom="0.625rem"
        columnGap={1}
      >
        <Skeleton variant="circular" height="2.5rem" width="2.5rem" />
        <Stack direction="column" alignItems="flex-start">
          <Skeleton variant="text" width="15.625rem" />
          <Skeleton variant="text" width="9.375rem" />
        </Stack>
      </Stack>

      <Divider sx={{ color: palette.Divider }} />

      <Stack paddingY={4} sx={{ fontSize: '1.25rem' }}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
      </Stack>

      {!hideFooter && (
        <>
          <Divider />
          <Stack
            display="flex"
            columnGap={2}
            flexDirection="row"
            paddingTop={2}
            sx={{ fontSize: '1.25rem' }}
          >
            <Skeleton variant="text" width="15%" />
            <Skeleton variant="text" width="15%" />
          </Stack>
        </>
      )}
    </>
  );
};
