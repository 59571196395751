import { ReactElement } from 'react';

import { FeaturesEnum } from '../features.enum';
import { FeatureAccessEnum } from '../enums/feature-access.enum';
import { useFeatureAccess } from '../hooks/useFeatureAccess';

export const FeatureGuard: React.FC<{
  feature: FeaturesEnum;
  permission?: FeatureAccessEnum;
  fallback?: ReactElement;
}> = ({ feature, permission = FeatureAccessEnum.READ, children, fallback = <></> }) => {
  const accessPermission = useFeatureAccess(feature) as Record<string, any>;
  const hasAccess = accessPermission[permission];

  return hasAccess ? <>{children}</> : fallback;
};
