import { getNextPageParam } from 'features/org-root/utils/infiniteScrollApi.utils';
import { useSession, useInfiniteQuery, useEvents } from 'common/hooks';
import { EVENTS_TO_REFETCH_FEEDS, transformFeed } from '../feeds.utils';
import { getFeedsApi } from '../feeds.service';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { FeedTypeEnum } from '../enums/feed-type.enum';

export const useGetFeeds = (feedType?: FeedTypeEnum) => {
  const { account: user } = useSession();

  const queryResponse = useInfiniteQuery(
    ['feeds', user?.organizationId],
    (params) => getFeedsApi({ ...params, feedType }),
    {
      getNextPageParam,
      entityKey: 'feeds',
      transformInput: transformFeed,
    },
  );

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  const response = {
    ...queryResponse,
    data,
  };

  /**
   * Automatically refetch the list of items when something is added, edited, or removed.
   * When added, because the item might now be pushed into your feed
   * When edited, because the item might now be visible in your feed
   * When deleted, because the item might now not be in your feed anymore.
   */
  useEvents(EVENTS_TO_REFETCH_FEEDS as Event[], () => response.refetch());

  return response;
};
