import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { useParamsProfileId, useSession } from 'common/hooks';
import { StackedListItemContainer } from 'common/components/container';
import { Divider, Stack, Typography } from 'common/components/material';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';

import { useGetProfile } from 'features/profiles';
import StackedDataListItemCard from 'features/profiles/components/StackedDataListItemCard';
import { useGetPriorExperience } from '../hooks';
import { PriorExperience } from '../types';
import { AddPriorExperienceContainer } from './add-prior-experience-container.component';
import { PriorExperienceContent } from './prior-experience-content.component';
import { useTheme } from '@mui/material/styles';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

export const PriorExperiencesView: React.FC = () => {
  const { palette } = useTheme();
  const { profileId } = useParamsProfileId();
  const { account: user } = useSession();
  const { data: priorExperiences } = useGetPriorExperience(profileId, user.organizationId);
  const { data: profile } = useGetProfile({
    id: profileId,
  });
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );
  const organizationData = profile?.organizationProfile?.data;
  const joinDate = moment(organizationData?.joinDate).format('DD MMMM yyyy');
  const currentOrganizationName = organizationData?.company?.name;
  const hasPriorExperience = !isEmpty(priorExperiences);

  return (
    <Stack>
      {(permissions.edit || organizationData?.joinDate || hasPriorExperience) && (
        <Divider sx={{ mt: '0.5rem', mb: '1.5rem' }} className="divider" />
      )}
      {organizationData?.joinDate && (
        <Stack mb={4} spacing={0.5}>
          <Typography variant="body2" fontWeight={600} color={palette.Text.Headline}>
            <img src="/images/partyPopper.svg" alt="" />
            {` Joined ${currentOrganizationName}!`}
          </Typography>
          <Typography variant="caption" color={palette.Text.SubHeadline}>
            {joinDate}
          </Typography>
        </Stack>
      )}
      {(permissions.edit || hasPriorExperience) && (
        <>
          <Typography
            variant="caption"
            fontWeight={500}
            color={palette.Text.SubHeadline}
            mb={1}
          >
            Prior Companies
          </Typography>
          <StackedListItemContainer>
            {priorExperiences?.map((priorExperience: PriorExperience) => (
              <StackedDataListItemCard key={priorExperience.id}>
                <PriorExperienceContent priorExperience={priorExperience} />
              </StackedDataListItemCard>
            ))}
            {permissions.edit && <AddPriorExperienceContainer />}
          </StackedListItemContainer>
        </>
      )}
    </Stack>
  );
};
