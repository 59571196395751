import CheckIcon from '@mui/icons-material/Check';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider } from 'react-hook-form';

import { Avatar, Badge, Box, CircularProgress, Typography } from 'common/components/material';
import { useDrawer, useForm, useSession } from 'common/hooks';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { FileSubmitData } from 'common/types/File.type';
import { transformFileToFormData } from 'common/utils/file.utils';
import { CXAvatarUploader } from 'common/components/cx-avatar/cx-avatar-uploader';
import { useUploadProfilePicture } from '../hooks/useUploadProfilePicture';
import {
  BaseBadge,
  Dropzone,
  ErrorBadge,
  SuccessBadge,
} from '../styles/profile-picture-uploader.style';
import { ProfilePictureUploaderProps } from '../types';
import queryClient from 'common/utils/QueryClient';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

const ProfilePictureUploader: React.FC<ProfilePictureUploaderProps> = ({
  initialImage,
  profileId,
  readOnly,
  showLabel,
  size,
  sx,
  dropzone,
}) => {
  const { openDrawer, closeDrawer } = useDrawer();
  const imageForm = useForm<FileSubmitData>();
  const [imageSrc, setImageSrc] = useState(initialImage);
  const { account } = useSession();
  const {
    mutate: uploadProfilePicture,
    isLoading,
    isSuccess,
    isError,
  } = useUploadProfilePicture({
    showSuccessSnackbar: true,
    onSuccess: (response) => {
      const updatedImageSrc = get(response, 'data.photo');
      setImageSrc(updatedImageSrc);
      queryClient.invalidateQueries([account?.organizationId, 'profile']);
    },
  });

  const openDeletePhotoConfirmation = (newImageSrc: string) => {
    openDrawer(DrawerIdEnum.DELETE_CONFIRMATION, {
      headerTitle: 'Delete Profile Photo?',
      subTitle: "You'll be able to add a new one after",
      confirmButtonHandler: () => {
        setImageSrc(newImageSrc);
        imageForm.handleSubmit(updateProfilePicture)();
        closeDrawer(DrawerIdEnum.DELETE_CONFIRMATION);
      },
    });
  };

  const updateProfilePicture = (data: FileSubmitData) => {
    const formData = transformFileToFormData(data, profileId);

    if (formData) {
      uploadProfilePicture(formData);
    }
  };

  const handleImageChange = (newImageSrc: string) => {
    if (isEmpty(newImageSrc)) {
      return openDeletePhotoConfirmation(newImageSrc);
    }

    setImageSrc(newImageSrc);
    imageForm.handleSubmit(updateProfilePicture)();
  };
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
    };
    reader.readAsDataURL(acceptedFiles[0]);
    updateProfilePicture({ file: acceptedFiles });
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: isLoading,
  });

  useEffect(() => {
    setImageSrc(initialImage);
  }, [initialImage]);

  const label = isLoading ? 'Uploading picture...' : 'Update profile picture';

  const renderBadge = () => {
    if (isSuccess) {
      return (
        <SuccessBadge key="success">
          <CheckIcon sx={{ fontSize: '1rem', color: 'white' }} />
        </SuccessBadge>
      );
    }
    if (isError) {
      return (
        <ErrorBadge key="error">
          <ReportOutlinedIcon sx={{ fontSize: '1rem', color: 'white' }} />
        </ErrorBadge>
      );
    }
    if (isLoading) {
      return (
        <BaseBadge key="loading">
          <CircularProgress size="1.15rem" />
        </BaseBadge>
      );
    }
    return (
      <BaseBadge lineHeight="1.5rem" key="default">
        <FileUploadOutlinedIcon sx={{ fontSize: '0.75rem' }} />
      </BaseBadge>
    );
  };

  return (
    <FormProvider {...imageForm}>
      <form noValidate encType="multipart/form-data">
        {dropzone ? (
          <Dropzone {...getRootProps()}>
            <input {...getInputProps()} />
            <Box
              width="4.5rem"
              height="4.5rem"
              borderRadius="50%"
              margin="0.5rem auto"
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxShadow={AppBoxShadows.shadow4}
              sx={{ backgroundColor: 'white' }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={renderBadge()}
              >
                <Avatar src={imageSrc} sx={{ width: '3.5rem', height: '3.5rem' }} />
              </Badge>
            </Box>
            <Typography variant="caption">
              {isDragActive ? (
                'Drop your photo here...'
              ) : (
                <>
                  {'Drop your photo here, '}
                  <b>Select a file</b>
                </>
              )}
            </Typography>
          </Dropzone>
        ) : (
          <CXAvatarUploader
            sx={sx}
            label={showLabel ? label : ''}
            src={imageSrc}
            isDisabled={isLoading}
            readOnly={readOnly}
            onChange={handleImageChange}
            showLabel={showLabel}
            size={size}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default ProfilePictureUploader;
