import * as yup from 'yup';

export const CreateProfileSchema = yup.object({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  endorsements: yup.array().label('Skills').required().min(1),
  aspirations: yup.array().label('Aspirations').required().min(1),
  // One WIN is required
  win1: yup.string().label('First WIN').required(),
});
