import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

// Wrapper for NavLink used in CXTabs since we can't pass props directly to it from there
const CXTabLink: React.FC<NavLinkProps> = React.forwardRef(
  (props, ref: React.Ref<HTMLAnchorElement>) => {
    // replace = true ensures tabs don't add to the browser history
    // so clicking back goes to previous page, not previous tab
    return <NavLink replace={true} ref={ref} {...props} />;
  },
);

export default CXTabLink;
