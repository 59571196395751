import React from 'react';
import { Route, Routes, useLocation, Navigate, useParams } from 'react-router-dom';

import { NavigateToOrgRoute } from 'common/components/navigation';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { Profile } from '../profiles.component';
import { ProfileRoutesEnum } from '../profiles.enum';
import useSession from 'common/hooks/useSession';
import { suspendable } from 'common/utils/suspendable';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { PROFILE_ROUTER_PROFILE_ID_KEY } from '../profiles.constants';

const EditProfileView = suspendable(
  React.lazy(() => import('../components/EditProfileView')),
);
const ProfileView = suspendable(React.lazy(() => import('../components/profile-view')));

const ProfileRouter: React.FC = () => {
  const { profile } = useSession();
  const location = useLocation();
  const params = useParams();
  const { canRead: canAccessSelfProfile } = useFeatureAccess(FeaturesEnum.OWN_PROFILE);
  const { canRead: canAccessOtherProfile } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  /**
   * Nested Profile Route does not have dynmic path configured properly, that is the reason. When we are asking to provide profileId
   * via useParams, it returns either key: * with value profileId when on parent route or when the route is on nested profile route - activity etc,
   * it returns key: profileId but value is <id>/activities etc.
   * TODO: HL: We need to fix this in nested profile routes.
   */
  const hasAccess =
    params[PROFILE_ROUTER_PROFILE_ID_KEY]?.includes(profile?.id) ||
    params['profileId']?.includes(profile?.id)
      ? canAccessSelfProfile
      : canAccessOtherProfile;

  const RewriteUrl = () => {
    const pathname = location.pathname
      .split('/')
      .map((e) => (e === 'me' ? profile.id : e))
      .join('/');
    return (
      <Navigate
        to={{
          ...location,
          pathname,
        }}
      />
    );
  };

  return (
    <Routes>
      <Route path="/me/*" element={<RewriteUrl />} />
      <Route
        path={ProfileRoutesEnum.PROFILE}
        element={
          hasAccess ? <ProfileView /> : <NavigateToOrgRoute route={AppRoutesEnum.HOME} />
        }
      />
      <Route path="/" element={<Profile />}>
        <Route path={ProfileRoutesEnum.PROFILE_EDIT} element={<EditProfileView />} />
        <Route path="*" element={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />} />
      </Route>
    </Routes>
  );
};

export default ProfileRouter;
