import { styled } from '@mui/system';
import Dialog from 'common/components/material/Dialog';

export const StyledModal = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '0.75rem',
    width: '40rem',
  },
  '& .MuiContainer-root': {
    padding: 0,
  },
});
