import { styled } from '@mui/material';

import { ReviewsAttachedEntitiesListProps, useGetReviewDetails } from 'features/reviews';

import PraiseFeedCard from '../Praise/PraiseFeedCard';
import ProjectCard from '../Project/ProjectCard';

const FeedCardWrapper = styled('div')({
  marginBottom: '0.75rem',
});

const ReviewsAttachedEntitiesList: React.FC<ReviewsAttachedEntitiesListProps> = ({
  reviewId,
  readOnly = false,
}) => {
  const { data: review } = useGetReviewDetails(reviewId);

  return (
    <>
      {review?.attachedEntities?.map((entity) => (
        <FeedCardWrapper key={entity.id}>
          {entity.giver ? (
            <PraiseFeedCard praise={entity} readOnly={readOnly} />
          ) : (
            <ProjectCard project={entity} disableFeedback readOnly={readOnly} />
          )}
        </FeedCardWrapper>
      ))}
    </>
  );
};

export default ReviewsAttachedEntitiesList;
