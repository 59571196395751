import React, { useEffect, useState } from 'react';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import _, { cloneDeep, isEmpty, mapValues, set } from 'lodash';
import { useTheme } from '@mui/material';

import { Grid, Stack } from 'common/components/material';
import { useDrawer, useEventEmitter, useEvents, useRouter, useView } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import {
  ActiveFilters,
  Filter,
  FilterSearchParams,
  useFetchFilterMetadata,
} from 'features/search';
import FilterModal from './FilterModal';
import { ExploreContainerProps } from '../types';
import {
  ADDITIONAL_SEARCH_PARAM_KEYS,
  EXPLORE_PAGE_PADDING,
  EXPLORE_PAGE_PADDING_WITHOUT_HEADER,
  META_FILTERS_MAPPING,
} from '../explore.constants';
import { MobileExploreHeaderComponent } from './mobile-explore-header.component';
import { DesktopExploreHeaderComponent } from './desktop-explore-header.component';
import ExploreTabs from 'features/home/components/explore-tabs.component';
import { useExploreTabs } from '../hooks/useExploreTabs';
import StickyHeaderContainer from 'common/components/container/StickyHeaderContainer';
import {
  deleteDataGridParams,
  getTransformedFilterList,
  omitFilterParams,
} from '../explore.utils';
import { SearchRelatedPages } from 'features/related-pages/components/search-related-pages.component';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { ButtonTabsContainer } from './ExploreView';
import { ButtonTabs } from 'common/components/navigation';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { BASE_API_PARAM_KEYS_WITH_RELATED_PAGES } from 'features/data-explorer/filter-explorer.config';
import PeopleExploreViewToggleButton from './profile-explore-view-toggle-button.component';
import { PeopleSearchRoutesEnum } from '../explore.enums';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useGetFilterMenuItems } from '../hooks';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';

const ExploreContainer: React.FC<ExploreContainerProps> = ({
  selectedTab,
  SideBarPanel,
  children,
}) => {
  const { openDrawer } = useDrawer();
  const { isDesktopView } = useView();
  const { exploreTabs } = useExploreTabs();
  const { goTo } = useRouter();
  const { palette } = useTheme();

  const { canRead: hasAccessToOtherProfiles } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const childRouteParam = params['*'];
  const showDataGrid =
    selectedTab === ExploreTabEnum.PEOPLE && childRouteParam === PeopleSearchRoutesEnum.TABLE;
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({});
  const [filtersList, setFiltersList] = useState<Filter[]>([]);
  const location = useLocation();
  const state = location.state as any;
  const { emit: emitFiltersUpdated } = useEventEmitter<ActiveFilters>('FiltersUpdated');
  const { emit: emitFiltersOpen } = useEventEmitter<ActiveFilters>('FiltersOpen');
  useEvents('FiltersUpdated', (filters) => updateFilters(filters as ActiveFilters, false));
  useEvents('FiltersOpen', () =>
    isDesktopView ? setFilterModalOpen(true) : openFilterDrawer(),
  );
  const collapseLeftPanel = !!SideBarPanel || showDataGrid;

  const { refetch: refetchFilterMetadata } = useFetchFilterMetadata({
    onSuccess: ({ data }) => {
      updateFilters(data.result);
    },
  });

  const { menuItems: filterMenuItems } = useGetFilterMenuItems(selectedTab);
  const hasAccessToFilters = !!filterMenuItems.length;
  const isHeaderVisible = hasAccessToOtherProfiles && hasAccessToFilters;

  useEffect(() => {
    if (!state?.refetchMetaData) {
      return;
    }
    // Triggered when search param changes from outside of the filter menu
    // such as when we click on Find Resources from the opportunity tab results.
    refetchFilterMetadata();
  }, [state?.refetchMetaData, refetchFilterMetadata]);

  useEffect(() => {
    return () => {
      // Clear filters when navigating to another page
      emitFiltersUpdated({});
    };
  }, [emitFiltersUpdated]);

  useEffect(() => {
    if (!state?.openFilters) {
      return;
    }
    emitFiltersOpen();
    // Clear state to prevent opening filter modal again when reloading
    window.history.replaceState({}, '');
  }, [state?.openFilters, emitFiltersOpen]);

  const isFilterApplied = () => {
    if (_.isEmpty(activeFilters)) return false;
    return Object.values(activeFilters).some((filterValue) => !_.isEmpty(filterValue));
  };

  const openFilterDrawer = () => {
    openDrawer(DrawerIdEnum.FILTER, {
      items: filterMenuItems,
      initialFilters: activeFilters,
      selectedTab,
      isFilterApplied,
      applyButtonHandler: (
        newFilters: ActiveFilters,
        additionalSearchParams?: FilterSearchParams,
      ) => {
        applyFilters(newFilters, additionalSearchParams);
      },
    });
  };

  const closeFilterModal = () => {
    setFilterModalOpen(false);
  };

  const updateFilters = (filters: ActiveFilters, emitEvent = true) => {
    setActiveFilters(filters);
    const newFiltersList = getTransformedFilterList(filters);
    setFiltersList(newFiltersList);
    if (emitEvent) {
      emitFiltersUpdated(filters);
    }
  };

  const applyFilters = (
    filters: ActiveFilters,
    additionalSearchParams: FilterSearchParams = {},
  ) => {
    const excludeChildrenSet: Set<string> = new Set();
    const filterParams = mapValues(filters, (filtersList) =>
      isEmpty(filtersList)
        ? []
        : filtersList
            .map((filter) => {
              if (filter.excludeChildren) {
                excludeChildrenSet.add(filter.id);
              }
              return filter.id;
            })
            .join(','),
    );

    const newSearchParams = cloneDeep(filterParams);

    if (excludeChildrenSet.size > 0) {
      set(newSearchParams, 'excludeChildren', Array.from(excludeChildrenSet).join(','));
    }

    ADDITIONAL_SEARCH_PARAM_KEYS.forEach((additionalSearchParamKey) => {
      const additionalSearchParam = searchParams.get(additionalSearchParamKey);
      if (additionalSearchParam) {
        set(newSearchParams, additionalSearchParamKey, additionalSearchParam);
      }
    });

    /**
     * Loop does following:
     * Iterate over META_FILTERS_MAPPING : which represents meta filters.
     * Then we find each meta filters values either in additionalSearchParams or in newSearchParams.
     * Once we have meta filters value, we determine if its active filter is present or not.
     */
    for (const key in META_FILTERS_MAPPING) {
      const searchParamValue = !_.isNil(additionalSearchParams[key])
        ? additionalSearchParams[key]
        : newSearchParams[key];

      const filterKey = META_FILTERS_MAPPING[key];
      const hasActiveFilters = !isEmpty(filterParams[filterKey]);

      if (!_.isNil(searchParamValue) && hasActiveFilters) {
        set(newSearchParams, key, searchParamValue);
      } else {
        delete newSearchParams[key];
      }
    }

    updateFilters(filters);
    setFilterModalOpen(false);
    setSearchParams(newSearchParams);
  };

  const clearFilters = () => {
    const nonFilterParams = omitFilterParams(searchParams);

    updateFilters({});
    setSearchParams(nonFilterParams);
  };

  const applyHorizontalFilters = (updatedFilter: ActiveFilters) => {
    applyFilters({ ...activeFilters, ...updatedFilter });
  };

  const redirectToExploreTab = (selectedTab: string) => {
    let pathname: string = AppRoutesEnum.EXPLORE;
    if (selectedTab) {
      pathname += `/${selectedTab}`;
    }

    searchParams.delete('eventType');
    searchParams.delete('selectedDate');
    deleteDataGridParams(searchParams);

    goTo({
      pathname,
      search: searchParams,
    });
  };

  const exploreViewToggleButton = selectedTab === ExploreTabEnum.PEOPLE && (
    <PeopleExploreViewToggleButton />
  );

  const Header =
    isHeaderVisible &&
    (isDesktopView ? (
      <DesktopExploreHeaderComponent
        contentType={selectedTab}
        activeFilters={activeFilters}
        isFilterApplied={isFilterApplied}
        onFiltersClick={emitFiltersOpen}
        clearFilters={clearFilters}
        applyFilters={applyHorizontalFilters}
      >
        {exploreViewToggleButton}
      </DesktopExploreHeaderComponent>
    ) : (
      <MobileExploreHeaderComponent
        contentType={selectedTab}
        onFiltersClick={emitFiltersOpen}
        selectedFiltersCount={filtersList.length}
      ></MobileExploreHeaderComponent>
    ));

  const showRelatedPages =
    !collapseLeftPanel &&
    (searchParams.get('query') ||
      BASE_API_PARAM_KEYS_WITH_RELATED_PAGES.find((e) => searchParams.has(e)));
  return (
    <>
      <Grid
        container
        pt={Header ? EXPLORE_PAGE_PADDING : EXPLORE_PAGE_PADDING_WITHOUT_HEADER}
        px={{ md: EXPLORE_PAGE_PADDING }}
        id="explore-container"
      >
        {isDesktopView && (
          <Grid
            item
            md={collapseLeftPanel ? 0.8 : 3}
            paddingRight={2}
            sx={[
              {
                transition: (theme) =>
                  theme.transitions.create('all', {
                    easing: theme.transitions.easing.sharp,
                    duration: 150,
                  }),
              },
            ]}
          >
            <StickyHeaderContainer topOffset={1}>
              <Stack spacing={1}>
                <ExploreTabs
                  title="Search For"
                  tabs={exploreTabs}
                  initialSelectedTab={selectedTab}
                  isConciseView={collapseLeftPanel}
                />
                {showRelatedPages && (
                  <FeatureGuard feature={FeaturesEnum.STATIC_DATA_PAGES}>
                    <SearchRelatedPages sx={{ mt: 2 }} />
                  </FeatureGuard>
                )}
              </Stack>
            </StickyHeaderContainer>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={!!SideBarPanel ? 8.2 : collapseLeftPanel ? 11.2 : 9}
          sx={[
            {
              transition: (theme) =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.sharp,
                  duration: 150,
                }),
            },
          ]}
        >
          {Header && (
            <StickyHeaderContainer
              display="flex"
              bgColor={palette.Background}
              direction="row"
              justifyContent="space-between"
              pt={0.5}
              pb={0.5}
            >
              {Header}
            </StickyHeaderContainer>
          )}
          {!isDesktopView && (
            <>
              <StickyHeaderContainer>
                <ButtonTabsContainer>
                  <ButtonTabs
                    selectedValues={[selectedTab]}
                    tabs={exploreTabs}
                    onChange={redirectToExploreTab}
                    scroll
                    sx={{
                      px: { xs: 1.5, md: 0 },
                      gap: 0.75,
                    }}
                  />
                </ButtonTabsContainer>
              </StickyHeaderContainer>
              <Stack px={1.5}>{exploreViewToggleButton}</Stack>
              {selectedTab !== ExploreTabEnum.ORGANIZATION_EVENTS && !showDataGrid && (
                <FeatureGuard feature={FeaturesEnum.STATIC_DATA_PAGES}>
                  <SearchRelatedPages sx={{ mt: 2 }} />
                </FeatureGuard>
              )}
            </>
          )}
          {children}
        </Grid>
        {!!SideBarPanel && (
          <Grid item xs={12} md={3} paddingLeft={2}>
            <SideBarPanel />
          </Grid>
        )}
      </Grid>
      {hasAccessToFilters && filterModalOpen && (
        <FilterModal
          items={filterMenuItems}
          open={filterModalOpen}
          initialFilters={activeFilters}
          selectedTab={selectedTab}
          onClose={closeFilterModal}
          onApply={applyFilters}
        />
      )}
    </>
  );
};

export default ExploreContainer;
