import { PageParams } from 'common/types/Api.type';
import { getNewJoinersApi } from '../profiles.service';
import { useInfiniteQuery, useSession } from 'common/hooks';
import { getNextPageParam } from 'features/org-root';

export const useGetNewJoiners = (bulkJoinId: string) => {
  const { account: user } = useSession();

  const queryResponse = useInfiniteQuery(
    ['new-joiners', user?.organizationId, bulkJoinId],
    ({ pageParam }: { pageParam?: PageParams }) => getNewJoinersApi(bulkJoinId, pageParam),
    {
      getNextPageParam,
      entityKey: 'data',
    },
  );

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  return {
    ...queryResponse,
    data,
  };
};
