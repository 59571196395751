export const MAX_SKILLS = 10;

export const PRONOUNS = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'She/Her',
    label: 'She/Her',
  },
  {
    value: 'He/Him',
    label: 'He/Him',
  },
  {
    value: 'They/Them',
    label: 'They/Them',
  },
  {
    value: 'Custom',
    label: 'Custom',
  },
];

export const DEGREES = [
  'Associate',
  'Bachelor',
  'Diploma',
  'Master',
  'Juris Doctor',
  'Doctor of Medicine',
  'Doctor of Philosophy',
];

export const MICROSOFT_TEAMS_CHAT_URL = 'https://teams.microsoft.com/l/chat/0/0';
export const PROFILE_ROUTER_PROFILE_ID_KEY = '*';
