import React from 'react';
import { useTheme } from '@mui/material/styles';

import Typography, { TypographyVariant } from 'common/components/material/Typography';
import { Stack } from 'common/components/material';
import { EllipsisLink } from '../ellipsis-text/ellipsis-link.component';
import { CXAvatarSize } from 'common/components/cx-avatar/cx-avatar.types';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useRouter } from 'common/hooks';
import { UserAvatar } from 'features/profiles/components/user-avatar';
import { Person } from 'features/profiles/types/person.type';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';

export const PersonLinkWithLabel: React.FC<{
  label?: string;
  profile: Person;
  withPhoto?: boolean;
  dense?: boolean;
  textVariant?: TypographyVariant;
  iconSize?: CXAvatarSize;
  readonly?: boolean;
}> = ({ label, profile, withPhoto = true, dense, textVariant, iconSize, readonly }) => {
  const { palette } = useTheme();
  const { goTo } = useRouter();

  const { canRead: canNavigateToProfile } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    profile,
  );

  readonly = readonly ?? (!permissions.interact || !canNavigateToProfile);

  const goToProfile = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (readonly) return;

    goTo(AppRoutesEnum.PROFILE, { profileId: profile.id });
  };

  return (
    <Stack direction="row" alignItems="center" spacing={dense ? 0.5 : 1}>
      {label && (
        <Typography variant="body4" fontWeight={500} color={palette.Text.SubHeadline}>
          {label}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" spacing={dense ? 0.5 : 1}>
        {withPhoto && <UserAvatar src={profile?.photo} size={iconSize || 'xsmall'} />}
        <EllipsisLink
          variant={textVariant || 'body4'}
          fontWeight={500}
          lineclamp={1}
          onClick={(e: React.MouseEvent) => goToProfile(e)}
          readonly={readonly}
        >
          {profile.fullName || profile.firstName}
        </EllipsisLink>
      </Stack>
    </Stack>
  );
};
