import axios from 'common/utils/axios';

const reactionApi = axios('reactions');

export const createReactionApi = ({ ...data }: any) => {
  return reactionApi.post('', data);
};

export const getReactionRespondersApi = (reactableId: string, reactionType: string) => {
  return reactionApi.get('responders', { params: { reactableId, reactionType } });
};

export const deleteReactionApi = (reactableId: string) => {
  return reactionApi.delete('', { params: { reactableId } });
};
