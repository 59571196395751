import { styled } from '@mui/material/styles';

import CreateIconImage from 'common/components/image/GlobalCreateIconImage';
import { Button, Drawer } from 'common/components/material';
import IconButton from 'common/components/material/IconButton';
import Popover, { PopoverProps } from 'common/components/material/Popover';
import { usePopoverState, useView } from 'common/hooks';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import CreateEntityList from './CreateEntityList';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureAccessEnum } from 'features/feature-access/enums/feature-access.enum';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.Buttons.Primary.BgDefault,
  height: '3.25rem',
  width: '3.25rem',
  boxShadow: AppBoxShadows.shadow2,
  position: 'relative',
  bottom: '20%',
}));

const StyledButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledPopover = styled(Popover)<PopoverProps>({
  '.MuiPaper-root': {
    width: '18.75rem',
    boxShadow: AppBoxShadows.shadow4,
    borderRadius: '12px',
  },
});

const GlobalCreateButton = () => {
  const { isDesktopView } = useView();

  const { anchorElement, openPopover, closePopover } = usePopoverState();

  const open = !!anchorElement;

  const createEntityListContainerElement = isDesktopView ? (
    <StyledPopover
      data-role="global-create-entity-container"
      open={open}
      anchorEl={anchorElement}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <CreateEntityList handleClick={closePopover} />
    </StyledPopover>
  ) : (
    <Drawer
      data-role="global-create-entity-container"
      showHeader={false}
      anchor="bottom"
      open={open}
      toggleDrawer={closePopover}
    >
      <CreateEntityList handleClick={closePopover} />
    </Drawer>
  );

  return (
    <FeatureGuard
      feature={FeaturesEnum.CONTENT_CREATION_WIDGET}
      permission={FeatureAccessEnum.WRITE}
    >
      {isDesktopView ? (
        <StyledButtonContainer>
          <Button
            id="global-create-button"
            startIcon={<CreateIconImage />}
            onClick={openPopover}
            btntype="primary"
            size="medium"
          >
            Create
          </Button>
        </StyledButtonContainer>
      ) : (
        <div>
          <StyledIconButton onClick={openPopover}>
            <CreateIconImage />
          </StyledIconButton>
        </div>
      )}

      {createEntityListContainerElement}
    </FeatureGuard>
  );
};

export default GlobalCreateButton;
