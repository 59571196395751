import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { CreateProfileForm } from 'features/create-profile';
import { onboardApi } from 'features/profiles/profiles.service';
import { transformCreateProfileData } from '../utils';

export const useCreateProfile = (options?: UseMutationOptions) => {
  const mutation = useMutation(onboardApi, options);

  return {
    ...mutation,
    submit: (data: CreateProfileForm) => {
      const transformedData = transformCreateProfileData(data);
      mutation.submit(transformedData);
    },
  };
};
