import { Stack } from 'common/components/material';
import ReactionButton from './ReactionButton';
import CommentButton from './CommentButton';
import { SocialButtonsProps } from 'features/social';
import { useRouter, useView } from 'common/hooks';
import { ShareButton } from 'features/social/components/share-button.component';
import { useTheme } from '@mui/material';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

const SocialButtons: React.FC<SocialButtonsProps> = ({
  reactableId,
  isReacted = false,
  onLikeButtonAction,
  onCommentButtonAction,
}) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();
  const { getOrgRoute } = useRouter();

  const linkUrl =
    window.location.origin + getOrgRoute(AppRoutesEnum.HOME_FEED, { feedId: reactableId });

  return (
    <Stack direction="row" gap={isDesktopView ? 0 : 1}>
      <ReactionButton
        isReacted={isReacted}
        reactableId={reactableId}
        onLikeClicked={onLikeButtonAction}
      />
      <CommentButton onCommentButtonAction={onCommentButtonAction} />
      <ShareButton
        url={linkUrl}
        disableText={!isDesktopView}
        color={palette.SpecialButtons.Comment.LabelDefault}
      />
    </Stack>
  );
};

export default SocialButtons;
