import { useState } from 'react';

import { Divider, Stack } from 'common/components/material';
import CommentBox from './CommentBox';
import Comment from './Comment';
import SocialButtons from './SocialButtons';
import SocialInfoContainer from './SocialInfoContainer';
import { SocialContainerProps } from 'features/social';
import { CommentableEnum, useGetFeedComments } from 'features/comments';
import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { NavigationLink } from 'features/org-root/org-root.styles';

const SocialContainer: React.FC<SocialContainerProps> = ({
  entityId,
  commentableType = CommentableEnum.FEED,
  totalReaction = 0,
  totalComment = 0,
  isReactedByLoggedInUser = false,
  showCommentsInFeedPage = false,
  comment,
}) => {
  const [isCommentListVisible, setCommentListVisible] = useState<boolean>(!!totalComment);
  const [currentTotalReaction, setCurrentTotalReaction] = useState<number>(totalReaction);
  const [currentTotalComment, setCurrentTotalComment] = useState<number>(totalComment);
  const [visibleComments, setVisibleComments] = useState(comment ? [comment] : []);
  const [isCommentListExpanded, setIsCommentListExpanded] = useState<boolean>(false);

  const { goTo } = useRouter();

  const { fetchAllComments, isLoading } = useGetFeedComments(entityId, 5, {
    enabled: isCommentListExpanded,
    onSuccess: (data) => {
      setVisibleComments(data.pages[0].data.comments);
      setCurrentTotalComment(data.pages[0].data.pagination.total);
    },
  });

  const [reactedByLoggedInUser, setReactedByLoggedInUser] =
    useState<boolean>(isReactedByLoggedInUser);

  const showCommentList = () => {
    fetchAllComments();
    setCommentListVisible(true);
    setIsCommentListExpanded(true);
  };

  const hideCommentList = () => {
    setCommentListVisible(false);
  };

  const openFeedPage = () => {
    goTo(
      AppRoutesEnum.HOME_FEED,
      { feedId: entityId },
      { state: { autoFocusCommentBox: true } },
    );
  };

  const handleCommentAction = () => {
    if (showCommentsInFeedPage) {
      openFeedPage();
    }
    showCommentList();
  };

  const handleLikeButtonAction = (isReacted: boolean) => {
    isReacted
      ? setCurrentTotalReaction((prevCount) => prevCount + 1)
      : setCurrentTotalReaction((prevCount) => prevCount - 1);
    setReactedByLoggedInUser(isReacted);
  };

  const onAction = () => {
    setCommentListVisible(true);
    setIsCommentListExpanded(true);
  };

  const displayShowMoreButton =
    isCommentListVisible && !isLoading && visibleComments.length < currentTotalComment;
  const displayCollapseButton =
    isCommentListVisible &&
    currentTotalComment > 0 &&
    visibleComments.length === currentTotalComment;

  return (
    <Stack gap={1} sx={{ mb: -1 }}>
      <Divider variant="fullWidth" sx={{ mt: 1 }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" py="4px">
        <SocialButtons
          reactableId={entityId}
          isReacted={reactedByLoggedInUser}
          onLikeButtonAction={handleLikeButtonAction}
          onCommentButtonAction={handleCommentAction}
        />
        <SocialInfoContainer
          reactableId={entityId}
          totalReactions={currentTotalReaction}
          totalComment={currentTotalComment}
          isReactedByLoggedInUser={reactedByLoggedInUser}
          onCommentLinkClick={handleCommentAction}
        />
      </Stack>
      <CommentBox
        commentableId={entityId}
        commentableType={commentableType}
        onComment={onAction}
      />
      {isCommentListVisible &&
        visibleComments?.map((comment) => {
          return <Comment {...comment} key={comment.id} commentableId={entityId} onDelete={onAction} />;
        })}
      {displayShowMoreButton && (
        <NavigationLink variant="button" onClick={handleCommentAction}>
          Show More
        </NavigationLink>
      )}

      {displayCollapseButton && (
        <NavigationLink variant="button" onClick={hideCommentList}>
          Collapse
        </NavigationLink>
      )}
    </Stack>
  );
};

export default SocialContainer;
