import { styled } from '@mui/material';
import { Container } from 'common/components/material';
import ColorPalette from 'common/theme/colorPalette';
import {
  REVIEW_RATING_IMAGE_PATH,
  ReviewRatingProps,
  StyledReviewRatingProps,
} from 'features/reviews';

const RatingContainer = styled(Container, {
  shouldForwardProp: (prop: string) => {
    return !['color', 'ratingValue', 'selectedRating', 'readMode'].includes(prop);
  },
})<StyledReviewRatingProps>(({ theme, color, ratingValue, selectedRating, readMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '8.5rem',
  margin: 5,
  borderRadius: '20%',
  border: '2px solid',
  borderColor: ratingValue === selectedRating ? color : ColorPalette.GRAY300,
  color: ratingValue === selectedRating ? ColorPalette.WHITE : ColorPalette.GRAY600,
  cursor: readMode ? 'default' : 'pointer',
  overflow: 'hidden',
  WebkitBackfaceVisibility: 'hidden',
  boxShadow:
    ratingValue === selectedRating && !readMode ? `0px 0px 15px -2px ${color}` : 'none',
  [theme.breakpoints.down('md')]: {
    width: '8rem',
  },
}));

const StyledImage = styled('img', {
  shouldForwardProp: (prop) => {
    return prop !== 'ratingValue' && prop !== 'selectedRating' && prop !== 'readMode';
  },
})<StyledReviewRatingProps>(({ ratingValue, selectedRating, readMode }) => ({
  width: '75%',
  ':hover': {
    transform: !readMode && selectedRating !== ratingValue ? 'scale(1.2)' : '',
    WebkitTransition: 'transform 0.25s',
  },
}));

const RatingTextContainer = styled('div', {
  shouldForwardProp: (prop: string) => {
    return !['color', 'ratingValue', 'selectedRating'].includes(prop);
  },
})<StyledReviewRatingProps>(({ color, ratingValue, selectedRating, theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  boxSizing: 'border-box',
  borderTopRightRadius: '0.75rem',
  borderStyle: 'solid',
  borderWidth: '2px 1px 0 0',
  borderColor: ratingValue === selectedRating ? color : ColorPalette.GRAY300,
  backgroundColor: ratingValue === selectedRating ? color : ColorPalette.WHITE,
  padding: '8%',
  fontSize: theme.typography.body3.fontSize,
  fontWeight: 500,
}));

const ReviewRating: React.FC<ReviewRatingProps> = ({
  image,
  ratingValue,
  displayText,
  color,
  readMode,
  selectedRating,
  onRatingSelection,
}) => {
  const handleRatingClick = (rating: number) => {
    if (readMode) {
      return;
    }
    onRatingSelection(rating);
  };
  const getImageUrl = (value: number) => {
    const url = `${REVIEW_RATING_IMAGE_PATH}${image}${
      value === selectedRating ? 'Enabled' : ''
    }.svg`;
    return url;
  };

  return (
    <RatingContainer
      color={color}
      ratingValue={ratingValue}
      selectedRating={selectedRating}
      readMode={readMode}
      disableGutters
      onClick={() => handleRatingClick(ratingValue)}
    >
      <StyledImage
        src={getImageUrl(ratingValue)}
        ratingValue={ratingValue}
        selectedRating={selectedRating}
        readMode={readMode}
        alt={displayText}
      />
      <RatingTextContainer
        color={color}
        ratingValue={ratingValue}
        selectedRating={selectedRating}
      >
        {displayText}
      </RatingTextContainer>
    </RatingContainer>
  );
};

export default ReviewRating;
