import React from 'react';

import { FeedTypeEnum } from 'features/feeds/enums/feed-type.enum';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { useDrawer } from 'common/hooks';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import FeedCard from 'features/org-root/components/Feed/FeedCard';

import ProjectCardContent from './ProjectCardContent';
import ProjectCardHeader from './ProjectCardHeader';
import ProjectCardOptions from './ProjectCardOptions';
import { Project, ProjectCardProps, ProjectStatusEnum } from 'features/projects';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  disableFeedback = false,
  readOnly = false,
  sx,
}) => {
  const { id, owner, createdDate, editedDate, title, skills, tags } = project;
  const { openDrawer } = useDrawer();
  const { permissions } = useInstancePermissions<Project>(
    InstancePermissionsResourcesEnum.PROJECT,
    project,
  );

  const openProjectDrawer = () => {
    openDrawer(DrawerIdEnum.PROJECT, {
      project,
    });
  };

  return (
    <FeedCard
      key={id}
      /* Last minute changes for Show & Tell */
      // footerUser={assigner}
      headerUser={owner}
      timestamp={createdDate}
      editedTimestamp={editedDate}
      feedType={FeedTypeEnum.PROJECT}
      onClick={
        !readOnly && permissions.edit && project.status === ProjectStatusEnum.OPEN
          ? openProjectDrawer
          : undefined
      }
      headerAction={
        !readOnly && <ProjectCardHeader project={project} disableFeedback={disableFeedback} />
      }
      headerOptions={
        !readOnly &&
        permissions.edit && (
          <ProjectCardOptions project={project} onEdit={openProjectDrawer} />
        )
      }
      fullWidthOnMobile={false}
      sx={sx}
    >
      <ProjectCardContent
        title={title}
        skills={skills}
        tags={tags}
        project={project}
        readOnly={readOnly}
      />
    </FeedCard>
  );
};

export default ProjectCard;
