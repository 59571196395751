export enum ExperimentalFeatureEnum {
  /**
   * the compailer is complaining if we don't define anything (string enum) here
   * since we try to cast a string to this `Feature` type at /experimental-features API.
   * Even if we define any feature we SHOULD NOT remove the dummy feature so that
   * if in future the actual feature is removed the dummy one should be there.
   */
  X_DUMMY_FEATURE = 'x-dummy-feature',
  X_REVIEW = 'x-review',
  X_NLP_SEARCH = 'x-nlp-search',
}

/**
 * Currently, **Feature** = ExperimentalFeature.
 * once we commercialize cnxt, we may add other features that user can access
 * based on their organization subcscriptions
 *
 * ex:
 * type Feature = ExperimentalFeature | SubscriptionPlanFeature
 */

export type ExperimentalFeature = ExperimentalFeatureEnum;
