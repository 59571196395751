import { useMutation } from 'common/hooks';
import { markPrivacyPolicyAsReviewedApi } from '../profiles.service';
import { UseMutationOptions } from 'common/types/Api.type';

export const useMarkPrivacyPolicyAsReviewed = (options: UseMutationOptions = {}) => {
  return useMutation(() => markPrivacyPolicyAsReviewedApi(), {
    showErrorSnackbar: true,
    ...options,
  });
};
