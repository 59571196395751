import * as yup from 'yup';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import { regURL } from 'common/validation-schemas/Common.schema';
import { TagSchema } from 'features/tags/schemas/tag.schema';

const timeHasRightFormat = (value: string) => {
  return moment(value, 'HH:mm').isValid() || moment(value, 'hh:mm a').isValid();
};

const timeBefore = (t1: string, t2: string) => {
  return moment(t1, 'HH:mm').isBefore(moment(t2, 'HH:mm'));
};

export const OrganizationEventFormSchema = (isContentAdmin: boolean) =>
  yup.object().shape(
    {
      onBehalfOf: yup
        .object()
        .label('onBehalfOf')
        .nullable()
        .required('"On behalf of" is required'),
      title: yup.string().required('Title is required'),
      eventType: yup.object().required('Event type is required').nullable(),
      organizer: yup
        .array()
        .label('organizer')
        .min(1, 'Organizer is required')
        .required('Organizer is required'),
      eventLocationType: yup.string().required(),
      location: yup.object().nullable(),
      eventUrl: yup
        .string()
        .transform((value: string) => {
          return value === '' ? null : value;
        })
        .nullable()
        .notRequired()
        .matches(regURL, 'Invalid URL'),
      venue: yup
        .string()
        .transform((value: string) => {
          return value === '' ? null : value;
        })
        .nullable()
        .notRequired(),
      timeZone: yup.object().required('Timezone is required').nullable(),
      startDate: yup
        .string()
        .ensure()
        .label('Start date')
        .required('Start date is required')
        .test(
          'Valid Date',
          'Date format should be yyyy-mm-dd',
          (value) => !!value && moment(value, 'YYYY-MM-DD', true).isValid(),
        ),
      endDate: yup
        .string()
        .ensure()
        .label('End date')
        .required('End date is required')
        .test(
          'Valid Date',
          'Date format should be yyyy-mm-dd',
          (value?: string) => !!value && moment(value, 'YYYY-MM-DD', true).isValid(),
        )
        .when('endTime', {
          is: (value: string) => !!value,
          then: (schema) => schema.required('End Date is required when End Time is set'),
        })
        .when('startDate', (startDate: string, schema: yup.StringSchema) => {
          if (!startDate) {
            return schema;
          }
          return schema.test(
            'Valid End Date',
            'End Date cannot be before Start Date',
            (endDate?: string) => {
              return !endDate || moment(endDate).isSameOrAfter(startDate, 'day');
            },
          );
        }),
      startTime: yup
        .string()
        .nullable()
        .when('endTime', {
          is: (value: string) => !!value,
          then: (schema) =>
            schema
              .test(
                'Valid Time',
                'Time format should be hh:mm a',
                (value?: string | null) => !value || timeHasRightFormat(value),
              )
              .required('Start Time is required when End Time is set'),
          otherwise: (schema) => schema.optional(),
        }),
      endTime: yup
        .string()
        .nullable()
        .test(
          'Valid Time',
          'Time format should be hh:mm a',
          (value?: string | null) => !value || timeHasRightFormat(value),
        )
        .when('startTime', {
          is: (value: string) => !!value,
          then: (schema) =>
            schema
              .required('End Time is required when Start Time is set')
              .test('Valid End Time', (value: string | null | undefined, context) => {
                const startTime = get(context, 'parent.startTime'); // here we know startTime is properly set
                const startDate = get(context, 'parent.startDate');
                const endDate = get(context, 'parent.endDate');

                if (startDate === endDate && !timeBefore(startTime, value || '')) {
                  return context.createError({
                    message: 'End time cannot be before/equal to the start time',
                  });
                }

                return true;
              }),
          otherwise: (schema) => schema.optional(),
        }),
      tags: yup
        .array()
        .of(TagSchema)
        .label('tags')
        .when([], {
          is: () => isContentAdmin,
          then: (schema) =>
            schema.test({
              message: 'At least One Tag is required',
              test: (tags) => !isEmpty(tags),
            }),
        }),
      visibleTo: yup.array().of(
        yup
          .object({
            id: yup.string().label('id').required(),
            name: yup.string().label('name').required(),
          })
          .optional(),
      ),
    },
    // These fields are mutually dependent
    [['startTime', 'endTime']],
  );
