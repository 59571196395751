import React from 'react';
import { useTheme } from '@mui/material/styles';

import { HorizontalScrollableContainer } from 'common/components/container';
import { PersonLinkWithLabel } from 'common/components/person-link/person-link-with-label.component';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { Stack, Typography } from 'common/components/material';
import { useDrawer, useView } from 'common/hooks';
import { Skill } from 'features/skills';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import SkillChip from 'features/org-root/components/SkillChip';
import PraiseProjectCard from './PraiseProjectCard';
import { Praise, StyledPraiseBackground } from 'features/praise';
import { TagChips } from 'features/tags/components/tag-chips.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

const PraiseCardContent: React.FC<{ praise: Praise }> = ({ praise }) => {
  const { description, headline, skills, giver, receiver, relatedProject, tags } = praise;
  const { isMobileView } = useView();
  const { openDrawer } = useDrawer();
  const { palette } = useTheme();

  const handleSkillClick = (skill: Skill) => {
    openDrawer(DrawerIdEnum.SKILLS_ACTION, { skill, person: receiver });
  };

  const { canRead: canReadSkills } = useFeatureAccess(FeaturesEnum.SKILLS);

  const scrolledSkillsElement = (
    <Stack
      py={0.5}
      direction="row"
      spacing={1}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {skills.map(({ isMatch, ...skill }) => (
        <SkillChip
          key={skill.id}
          skill={skill}
          isSelected={isMatch}
          readonly={!canReadSkills}
          onClick={() => handleSkillClick(skill)}
        />
      ))}
    </Stack>
  );

  return (
    <Stack>
      <StyledPraiseBackground py="1rem" px={isMobileView ? '1rem' : '2rem'}>
        <Stack pt={!relatedProject ? 5 : 0} justifySelf="center" pb="1rem" spacing={1}>
          {relatedProject && (
            <Stack pt={2}>
              <PraiseProjectCard project={relatedProject} />
            </Stack>
          )}
          {headline && (
            <EllipsisText
              lineclamp={3}
              variant="h4"
              fontWeight={600}
              color={palette.Text.Headline}
            >
              {headline}
            </EllipsisText>
          )}
          {description && (
            <Typography
              variant="body3"
              color={palette.Text.Paragraph}
              pt={1}
              lineHeight="1.375rem"
            >
              {description}
            </Typography>
          )}
          <Stack pt={2}>
            <HorizontalScrollableContainer scrolledListElement={scrolledSkillsElement} />
          </Stack>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
          <PersonLinkWithLabel profile={giver} iconSize="medium" textVariant="body3" />
        </Stack>
      </StyledPraiseBackground>
      <TagChips tags={tags} />
    </Stack>
  );
};

export default PraiseCardContent;
