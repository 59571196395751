import { get } from 'lodash';

import { CircularProgress, Stack } from 'common/components/material';
import ModalContainer from 'common/components/modals/ModalContainer';
import ReactionIcon from 'common/components/modals/ReactionIcon';
import {
  ReactionEnum,
  ReactionRespondersModalProps,
  useGetReactionResponders,
} from 'features/reactions';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { LinkTileWithAvatar } from '../link-tile-with-avatar/link-tile-with-avatar.component';
import { useRouter } from 'common/hooks';

const titleMapping = {
  [ReactionEnum.LIKE]: 'Liked by',
};

const ReactionRespondersModal: React.FC<ReactionRespondersModalProps> = (props) => {
  const { reactableId, type = ReactionEnum.LIKE, ...otherProps } = props;
  const { getOrgRoute } = useRouter();
  const { data: responders = [], isLoading } = useGetReactionResponders(reactableId, type);
  const title = get(titleMapping, type, '');

  return (
    <ModalContainer title={title} {...otherProps}>
      <>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          responders.map((responder) => {
            const route = getOrgRoute(AppRoutesEnum.PROFILE, {
              profileId: responder.id,
            });
            return (
              <LinkTileWithAvatar
                key={responder.id}
                route={route}
                title={responder.fullName}
                subtitle={responder.profileHeadline}
                photo={responder.photo}
                showBorder={false}
                endAdornment={<ReactionIcon reactionType={type} />}
              />
            );
          })
        )}
      </>
    </ModalContainer>
  );
};
export default ReactionRespondersModal;
