import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography } from 'common/components/material';
import { ReviewSubmitFormLabelProps } from 'features/reviews';

const StyledContainer = styled(Container)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.Container.Stroke}`,
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.Container.Stroke}`,
  },
  '&.MuiContainer-root': {
    padding: '0.8125rem 1.125rem',
  },
}));

const ReviewSubmitFormLabel: React.FC<ReviewSubmitFormLabelProps> = ({ label }) => {
  const { palette } = useTheme();

  return (
    <StyledContainer>
      <Typography variant="body1" sx={{ color: palette.Text.Headline, fontWeight: 'bolder' }}>
        {label}
      </Typography>
    </StyledContainer>
  );
};

export default ReviewSubmitFormLabel;
