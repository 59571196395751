import { CXTabItem } from 'common/components/cx-tabs';
import { useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import {
  OrganizationalStaticDataTypeEnum,
  OrganizationalTagPagesTypeEnum,
  WorkLocationSubdivisionEnum,
  useGetVisibleOrganizationalStaticData,
} from 'features/static-data';
import { useParams } from 'react-router-dom';
import { ProfileEnum } from '../org-chart.enum';
import { titleCase } from 'common/utils/app.utils';
import { useGetIsTagVisible } from 'features/static-data/hooks/useGetIsTagVisible';
import { OrgChartType } from '../types/org-chart-service-request.type';
import { useGetL0ManagerId } from 'features/profiles/hooks/useGetL0ManagerId';

const staticDataTypeLabel = (type: string) => {
  if (type === OrganizationalStaticDataTypeEnum.WORK_LOCATION) {
    return 'Locations';
  }
  return titleCase((OrganizationalStaticDataTypeEnum as any)[type]) + 's';
};

const isStaticDataVisible = (
  isGuestProfile: boolean,
  isEntityVisible: boolean,
  isProfileEntityRequested: boolean,
  isProfileEntityVisible: boolean,
  type: OrganizationalStaticDataTypeEnum,
  requestedType: string,
) => {
  if (isGuestProfile) return true;

  if (type === requestedType && !isProfileEntityRequested) {
    return isEntityVisible;
  }

  return isProfileEntityVisible;
};

const getOrgStaticDataTab = (
  isGuestProfile: boolean,
  staticDataId: string,
  organizationId: string,
  type: OrgChartType,
  getOrgRoute: Function,
) => {
  // onClick of tab is routed to topLevelNodeId in case user is Guest User, or if they do not have any entityId.
  // else we route it to entityId
  const routeId = isGuestProfile || !staticDataId ? 'all' : staticDataId;

  return {
    label: staticDataTypeLabel(type),
    value: (OrganizationalStaticDataTypeEnum as any)[type].toLowerCase(),
    to: getOrgRoute(AppRoutesEnum.ORG_CHART_STATIC_DATA, {
      id: routeId,
      type: (OrganizationalStaticDataTypeEnum as any)[type].toLowerCase(),
    }),
    state: {},
    disabled: false,
  };
};

const getSelectedTab = (type: string) => {
  if (Object.keys(OrganizationalStaticDataTypeEnum).includes(type)) {
    return (OrganizationalStaticDataTypeEnum as any)[type].toLowerCase();
  }

  if (Object.keys(WorkLocationSubdivisionEnum).includes(type)) {
    return OrganizationalStaticDataTypeEnum.WORK_LOCATION.toLowerCase();
  }

  return ProfileEnum.PROFILE.toLowerCase();
};

/**
 * we check the following for making a tab visible:
 * - People tab is always visible.
 * - For Static data tab to be visible, we run the following checks:
 *   -- Static data should not be _disabled_
 *   -- if staticDataId from URL are not related to loggedInUser, make an api call to check whether the entity requested is visible.
 *   -- if not, then we can rely on the isVisible flag for the staticData entity coming from loggedInUser's session.
 */
const useGetOrgChartTabs = () => {
  const {
    profile,
    organizationProfile,
    organizationIdOnSession: organizationId,
  } = useSession();
  let { type, id } = useParams();

  const isParentOrg = id === 'all';

  type = `${type}`.toUpperCase();
  const { getOrgRoute } = useRouter();

  const orgChartTabs: CXTabItem[] = [];

  const selectedTab = getSelectedTab(`${type}`);

  const isGuestProfile = !!organizationProfile?.isGuestProfile;

  const departmentId = organizationProfile?.department?.id;
  const divisionId = organizationProfile?.division?.id;
  const productId = organizationProfile?.product?.id;
  const teamId = organizationProfile?.team?.id;
  const workLocationId = organizationProfile?.workLocation?.id;

  const { data: enabledStaticDataTypes, isLoading: isGetVisibleStaticDataTypesLoading } =
    useGetVisibleOrganizationalStaticData();

  const enabledStaticDataTypesSet = new Set(enabledStaticDataTypes || []);

  const { data: isTagVisible, isLoading } = useGetIsTagVisible(
    { type: type as OrganizationalTagPagesTypeEnum, id: `${id}` },
    {
      enabled:
        !isParentOrg &&
        !!type &&
        !!id &&
        !isGetVisibleStaticDataTypesLoading &&
        enabledStaticDataTypesSet.has(type) &&
        ![departmentId, divisionId, productId, teamId, workLocationId].includes(id),
    },
  );
  const isEntityVisible = isParentOrg || isTagVisible;

  const { data: l0ManagerId, isLoading: isGetL0ManagerIdLoading } = useGetL0ManagerId({
    enabled: isGuestProfile,
  });

  if (isLoading || isGetVisibleStaticDataTypesLoading || isGetL0ManagerIdLoading)
    return { orgChartTabs, selectedTab };

  orgChartTabs.push({
    label: 'People',
    value: ProfileEnum.PROFILE.toLowerCase(),
    to: getOrgRoute(AppRoutesEnum.ORG_CHART_PROFILE, {
      id: isGuestProfile ? l0ManagerId : profile?.id,
    }),
    state: {},
  });

  const isDivisionVisible = isStaticDataVisible(
    isGuestProfile,
    isEntityVisible,
    id === organizationProfile?.division?.id,
    !!organizationProfile?.division?.isVisible,
    OrganizationalStaticDataTypeEnum.DIVISION,
    type,
  );

  const isDivisionEnabled = enabledStaticDataTypesSet.has(
    OrganizationalStaticDataTypeEnum.DIVISION,
  );

  isDivisionVisible &&
    isDivisionEnabled &&
    orgChartTabs.push(
      getOrgStaticDataTab(
        isGuestProfile,
        organizationProfile?.division?.id as string,
        organizationId,
        OrganizationalStaticDataTypeEnum.DIVISION, // type
        getOrgRoute,
      ),
    );

  const isDepartmentVisible = isStaticDataVisible(
    isGuestProfile,
    isEntityVisible,
    id === organizationProfile?.department?.id,
    !!organizationProfile?.department?.isVisible,
    OrganizationalStaticDataTypeEnum.DEPARTMENT,
    type,
  );

  const isDepartmentEnabled = enabledStaticDataTypesSet.has(
    OrganizationalStaticDataTypeEnum.DEPARTMENT,
  );

  isDepartmentVisible &&
    isDepartmentEnabled &&
    orgChartTabs.push(
      getOrgStaticDataTab(
        isGuestProfile,
        organizationProfile?.department?.id as string,
        organizationId,
        OrganizationalStaticDataTypeEnum.DEPARTMENT, // type
        getOrgRoute,
      ),
    );

  const isProductVisible = isStaticDataVisible(
    isGuestProfile,
    isEntityVisible,
    id === organizationProfile?.product?.id,
    !!organizationProfile?.product?.isVisible,
    OrganizationalStaticDataTypeEnum.PRODUCT,
    type,
  );

  const isProductEnabled = enabledStaticDataTypesSet.has(
    OrganizationalStaticDataTypeEnum.PRODUCT,
  );

  isProductVisible &&
    isProductEnabled &&
    orgChartTabs.push(
      getOrgStaticDataTab(
        isGuestProfile,
        organizationProfile?.product?.id as string,
        organizationId,
        OrganizationalStaticDataTypeEnum.PRODUCT, // type
        getOrgRoute,
      ),
    );

  const isTeamVisible = isStaticDataVisible(
    isGuestProfile,
    isEntityVisible,
    id === organizationProfile?.team?.id,
    !!organizationProfile?.team?.isVisible,
    OrganizationalStaticDataTypeEnum.TEAM,
    type,
  );

  const isTeamEnabled = enabledStaticDataTypesSet.has(OrganizationalStaticDataTypeEnum.TEAM);

  isTeamVisible &&
    isTeamEnabled &&
    orgChartTabs.push(
      getOrgStaticDataTab(
        isGuestProfile,
        organizationProfile?.team?.id as string,
        organizationId,
        OrganizationalStaticDataTypeEnum.TEAM, // type
        getOrgRoute,
      ),
    );

  const isWorkLocationVisible = isStaticDataVisible(
    isGuestProfile,
    isEntityVisible,
    id === organizationProfile?.workLocation?.id,
    !!organizationProfile?.workLocation?.isVisible,
    OrganizationalStaticDataTypeEnum.WORK_LOCATION,
    type,
  );

  const isWorkLocationEnabled = enabledStaticDataTypesSet.has(
    OrganizationalStaticDataTypeEnum.WORK_LOCATION,
  );

  isWorkLocationVisible &&
    isWorkLocationEnabled &&
    orgChartTabs.push(
      getOrgStaticDataTab(
        isGuestProfile,
        organizationProfile?.workLocation?.id as string,
        organizationId,
        OrganizationalStaticDataTypeEnum.WORK_LOCATION, // type
        getOrgRoute,
      ),
    );

  return { orgChartTabs, selectedTab };
};

export default useGetOrgChartTabs;
