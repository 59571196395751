import { toUpper } from 'lodash';
import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { GetStaticDataDetailsParams } from '../types';
import { OrganizationalTagPagesTypeEnum } from '../static-data.enum';
import { getIsTagVisible } from 'features/tags/services/taggings.service';

export const useGetIsTagVisible = (
  params: GetStaticDataDetailsParams,
  options: UseQueryOptions = {},
) => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';
  const { type, id } = params;
  const staticDataType = type && (toUpper(type) as OrganizationalTagPagesTypeEnum);

  const isValidStaticDataType =
    !!staticDataType &&
    Object.values(OrganizationalTagPagesTypeEnum).includes(staticDataType);

  const queryResponse = useQuery(
    [organizationId, 'static-data', staticDataType, id, 'isVisible'],
    () => isValidStaticDataType && getIsTagVisible({ type: staticDataType, id }),
    {
      enabled: !!organizationId && isValidStaticDataType,
      showErrorSnackbar: true,
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data?.isVisible,
  };
};
