import React, { useRef, ChangeEvent, useState, useEffect } from 'react';
import { styled } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';

import { UserAvatar } from 'features/profiles/components/user-avatar';
import { Stack, TextField, IconButton } from 'common/components/material';
import { useSession } from 'common/hooks';
import { KeyboardEventKeyEnums } from 'common/enum/KeyboardEventKey.enum';
import { CommentBoxProps, useCreateComment } from 'features/comments';
import queryClient from 'common/utils/QueryClient';

const StyledUserAvatar = styled(UserAvatar)({
  marginTop: 4,
});

const StyledTextField = styled(TextField)({
  /*
    To adjust the perceived height to 40px
    without affecting the permanent height, since
    this TextField would have default padding just
    make it smaller.
  */
  '& .MuiInputBase-root': {
    padding: '0.25rem',
  },
  '& .MuiInputBase-inputMultiline': {
    overflow: 'hidden',
  },
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 5,
  backgroundColor: theme.palette.Icons.Primary.BgDefault,
  color: theme.palette.Icons.Primary.IconFill,
  '&:hover': {
    backgroundColor: theme.palette.Icons.Primary.BgHover,
  },
  '&:active': {
    backgroundColor: theme.palette.Icons.Primary.BgDefault,
  },
  '&:disabled': {
    backgroundColor: theme.palette.Buttons.Primary.BgDisabled,
  },
}));

const customTextFieldStyle = {
  fontSize: 14,
  paddingRight: '0.75rem',
};

const CommentBox: React.FC<CommentBoxProps> = ({
  commentableId,
  commentableType,
  onComment,
}) => {
  const { profile } = useSession();
  const location = useLocation();
  const { account: user } = useSession();

  const { submit: createComment, isLoading } = useCreateComment({
    onSuccess: () => {
      queryClient.invalidateQueries([
        user.organizationId,
        'comments',
        commentableId,
      ]);
      onComment();
      setCommentText('');
    },
  });
  const [commentText, setCommentText] = useState<string>('');
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const state = location.state as any;

  const autoFocusCommentBox = state?.autoFocusCommentBox;

  useEffect(() => {
    if (autoFocusCommentBox && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [autoFocusCommentBox]);

  const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCommentText(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardEventKeyEnums.ENTER && e.ctrlKey) {
      e.preventDefault();
      submitComment();
    }
  };

  const submitComment = async () => {
    if (!commentText) {
      return;
    }
    createComment({ commentableId, commentableType, content: commentText });
  };

  return (
    <Stack direction="row" spacing={1} sx={{ mb: 1 }} alignItems="center">
      <StyledUserAvatar src={profile?.photo} size="medium" sx={{ marginTop: 0 }} />
      <StyledTextField
        inputRef={textFieldRef}
        placeholder="Write your comment"
        size="small"
        fullWidth
        multiline
        autoFocus={autoFocusCommentBox}
        value={commentText}
        onKeyDown={handleKeyDown}
        onChange={handleCommentChange}
        maxRows={6}
        disabled={isLoading}
        InputProps={{
          endAdornment: (
            <StyledIconButton disabled={isLoading || !commentText} onClick={submitComment}>
              <SendIcon sx={{ fontSize: 12 }} />
            </StyledIconButton>
          ),
          style: customTextFieldStyle,
        }}
        InputLabelProps={{
          style: { fontSize: 14 },
        }}
      />
    </Stack>
  );
};

export default CommentBox;
