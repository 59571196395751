import React from 'react';

import Card, { CardContent } from 'common/components/material/Card';
import { PreviewCardProps } from './preview-card-props.type';
import { BannerBlock, OverlappingElementContainer } from './preview-card.styles';

export const PreviewCard: React.FC<PreviewCardProps> = ({
  children,
  overlappingElement,
  bannerColor,
  ...props
}) => {
  return (
    <Card noElevation={true} {...props}>
      <CardContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0.375rem',
          borderColor: theme.palette.Stroke.Default,
          [theme.breakpoints.down('md')]: {
            padding: '0.5rem',
          },
        })}
      >
        <BannerBlock bannerColor={bannerColor as string} />
        <OverlappingElementContainer>{overlappingElement}</OverlappingElementContainer>
        {children}
      </CardContent>
    </Card>
  );
};
