import { styled, useTheme } from '@mui/material';
import { useState } from 'react';

import { Button, Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { CommentableEnum } from 'features/comments';
import {
  ReactionButtonProps,
  ReactionEnum,
  StyledReactionButtonTextProps,
  useCreateSocialReaction,
  useDeleteSocialReaction,
} from 'features/reactions';
import { ReactComponent as LikeDefaultIcon } from 'common/svg-icons/likeDefault.svg';

const StyledReactionButtonText = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== 'isReacted';
  },
})<StyledReactionButtonTextProps>(({ isReacted, theme }) => ({
  color: isReacted
    ? theme.palette.SpecialButtons.Like.LabelActive
    : theme.palette.SpecialButtons.Like.LabelDefault,
  marginLeft: 5,
  fontWeight: 500,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
}));

const ReactionButton: React.FC<ReactionButtonProps> = ({
  reactableId,
  isReacted = false,
  onLikeClicked,
  disableText = false,
}) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();
  const { mutateAsync: addReaction, isLoading: isReacting } = useCreateSocialReaction();
  const { mutateAsync: removeReaction, isLoading: isUnreacting } =
    useDeleteSocialReaction(reactableId);
  const [isCurrentLiked, setCurrentLiked] = useState<boolean>(isReacted);

  const createReaction = () => {
    return addReaction({
      type: ReactionEnum.LIKE,
      reactableId,
      reactableType: CommentableEnum.FEED,
    });
  };

  const handleReactionButtonClicked = async (e: React.MouseEvent) => {
    e.stopPropagation();
    isCurrentLiked ? await removeReaction({}) : await createReaction();
    onLikeClicked && onLikeClicked(!isCurrentLiked);
    setCurrentLiked((prevLiked) => !prevLiked);
  };

  const likeIcon = isReacted ? (
    <img width={16} src="/images/likeActive.svg" alt="Like" />
  ) : (
    <LikeDefaultIcon width={16} color={palette.SpecialButtons.Like.LabelDefault} />
  );

  return (
    <Button
      variant="text"
      size="small"
      disabled={isReacting || isUnreacting}
      onClick={handleReactionButtonClicked}
      sx={{
        borderRadius: '10px',
        minWidth: !isDesktopView ? '20px' : 'initial',
        '&:hover': {
          backgroundColor: palette.SpecialButtons.Like.BgHover,
        },
      }}
    >
      {likeIcon}
      {isDesktopView && !disableText && (
        <StyledReactionButtonText isReacted={isCurrentLiked} variant="body3">
          {isCurrentLiked ? 'Liked' : 'Like'}
        </StyledReactionButtonText>
      )}
    </Button>
  );
};

export default ReactionButton;
