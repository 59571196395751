import { styled } from '@mui/material';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import { VerticalScrollableContainer } from 'common/components/container';
import { CrossBar } from 'common/components/header';
import { CircularProgress, Stack } from 'common/components/material';
import { CloseButton } from 'common/components/navigation';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { useDrawer, useRouter, useView } from 'common/hooks';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { DetailedReview, Review } from 'features/reviews/types/review.type';

import { useState } from 'react';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import EmptyAttachedEntities from './EmptyAttachedEntities';
import ReviewsAttachedEntitiesList from './ReviewAttachedEntitiesList';
import ReviewDetailsHeader from './ReviewDetailsHeader';
import ReviewStep from './ReviewStep';
import ReviewSubmitForm from './ReviewSubmitForm';
import { useGetReviewDetails } from 'features/reviews';

const LEAVE_FORM_TITLE = 'Are you sure?';
const LEAVE_FORM_SUBTITLE =
  'You will lose all your saved data, proceed if you want to discard.';

const StyledReviewContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.Background,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  overflow: 'hidden',
}));

const StyledReviewStepsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100vw',
});

const ReviewContainer: React.FC = () => {
  const { reviewId } = useParams();
  const { isDesktopView } = useView();
  const { goBack } = useRouter();
  const { openDrawer } = useDrawer();
  const { isLoading, data: review } = useGetReviewDetails(reviewId);

  const [hasFormChanged, setFormChanged] = useState<boolean>(false);

  const headerData = {
    revieweeName: get(review, 'reviewee.fullName', ''),
    reviewerName: get(review, 'reviewer.fullName', ''),
    periodEnd: get(review, 'periodEnd'),
    periodStart: get(review, 'periodStart'),
  };

  const { permissions: reviewPermissions } = useInstancePermissions<Review>(
    InstancePermissionsResourcesEnum.REVIEW,
    review,
  );

  const isReviewReadOnly = !reviewPermissions.edit;
  const getRevieweeFirstName = (review: DetailedReview) => {
    return get(review, 'reviewee.firstName') || '';
  };

  const handleFormChanged = (formChanged: boolean) => {
    setFormChanged(formChanged);
  };

  const toggleConfirmationDrawer = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      headerTitle: LEAVE_FORM_TITLE,
      subTitle: LEAVE_FORM_SUBTITLE,
      confirmButtonHandler: goBack,
    });
  };

  const closeButton =
    hasFormChanged && !isReviewReadOnly ? (
      <CloseButton clickHandler={toggleConfirmationDrawer} />
    ) : (
      <CloseButton clickHandler={goBack} />
    );
  return (
    <StyledReviewContainer>
      <CrossBar
        closeButton={closeButton}
        showBackButton={false}
        customHeight="5rem"
        Header={<ReviewDetailsHeader isLoading={isLoading} {...headerData} />}
      ></CrossBar>
      {isLoading && <CircularProgress sx={{ marginTop: 40 }} size={75} />}
      {!isLoading && (
        <VerticalScrollableContainer
          sx={{ overflowX: 'hidden' }}
          disableGutters
          maxWidth={false}
          maxHeight="100vh"
        >
          <StyledReviewStepsContainer
            style={{ flexDirection: isDesktopView ? 'row' : 'column' }}
          >
            <ReviewStep flex="1 12%" stepNumber={1} stepHeader="Review Projects and Feedback">
              <ReviewsAttachedEntitiesList reviewId={reviewId} readOnly={isReviewReadOnly} />
              {review?.attachedEntities?.length === 0 && <EmptyAttachedEntities />}
            </ReviewStep>
            <ReviewStep
              flex="1"
              stepNumber={2}
              stepHeader={`Evaluate ${getRevieweeFirstName(review)}'s Performance`}
              isFixed={isDesktopView}
            >
              <ReviewSubmitForm
                reviewId={reviewId}
                readOnly={isReviewReadOnly}
                formChanged={handleFormChanged}
              />
            </ReviewStep>
          </StyledReviewStepsContainer>
        </VerticalScrollableContainer>
      )}
    </StyledReviewContainer>
  );
};

export default ReviewContainer;
