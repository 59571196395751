import { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';

import { useSession } from 'common/hooks';
import { Link, Stack, Typography } from 'common/components/material';
import { validateUrlProtocol } from 'common/utils/app.utils';
import { useMarkPrivacyPolicyAsReviewed } from 'features/profiles/hooks/useMarkPrivacyPolicyAsReviewed';
import { useOrganizationPrivacyPolicy } from './hooks/useGetOrganizationPrivacyPolicy';
import CloseButton from 'common/components/navigation/CloseButton';

const PrivacyPolicyBanner = () => {
  const { organizationProfile } = useSession();
  const { palette } = useTheme();

  const isGuestProfile = organizationProfile.isGuestProfile;

  const { data: organizationPrivacyPolicy, isSuccess } = useOrganizationPrivacyPolicy({
    enabled: !isGuestProfile,
  });

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!isSuccess) return;

    const hasReviewedLatestPrivacyPolicy =
      organizationPrivacyPolicy?.id === organizationProfile.reviewedPrivacyPolicyId;

    setShowBanner(!hasReviewedLatestPrivacyPolicy && !!organizationPrivacyPolicy?.url);
  }, [organizationPrivacyPolicy, isSuccess, organizationProfile, setShowBanner]);

  const { mutate: markPrivacyPolicyAsReviewed, isError } = useMarkPrivacyPolicyAsReviewed();

  useEffect(() => {
    isError && setShowBanner(isError);
  }, [isError]);

  if (!showBanner || isGuestProfile) return <></>;

  const getBannerLabel = () => {
    const reviewLink = (
      <Link
        variant="inherit"
        sx={{ paddingX: '3px', color: palette.LinkText.Active }}
        href="#"
        onClick={reviewPolicy}
        color="inherit"
        underline="always"
      >
        here
      </Link>
    );

    if (!organizationProfile.reviewedPrivacyPolicyId) {
      return (
        <Typography variant="body4" fontWeight={500}>
          Curious how user information is handled? Click {reviewLink} to review the Privacy
          Policy statement.
        </Typography>
      );
    }

    return (
      <Typography variant="body4" fontWeight={500}>
        The Privacy Policy statement has been updated. Click {reviewLink} to review it.
      </Typography>
    );
  };

  const handleClose = () => {
    setShowBanner(false);
    markPrivacyPolicyAsReviewed(null);
  };

  const reviewPolicy = () => {
    const validatedUrl = validateUrlProtocol(organizationPrivacyPolicy?.url);

    window.open(validatedUrl, '_blank');
    setShowBanner(false);

    markPrivacyPolicyAsReviewed(null);
  };

  return (
    <Stack
      py="0.7rem"
      justifyContent="center"
      alignItems="center"
      sx={{
        color: palette.LinkText.Default,
        backgroundColor: palette.Extras.Extra10,
      }}
    >
      <Stack maxWidth="lg" direction="row" alignItems="center" gap="1rem">
        <Stack direction="row" justifyContent="center" alignItems="center" flexWrap="wrap">
          <InfoOutlinedIcon
            sx={{ marginRight: '5px', color: palette.Icons.Secondary.IconFill }}
          />
          {getBannerLabel()}
        </Stack>
        <CloseButton size="small" clickHandler={handleClose}></CloseButton>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicyBanner;
