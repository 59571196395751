import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { DesktopApplicationBar, MobileApplicationBar } from 'common/components/header';
import { Stack } from 'common/components/material';
import { useView } from 'common/hooks';
import { MobileChildContainer } from '../org-root.styles';
import MobileBottomNavBar from './MobileBottomNavBar';
import PrivacyPolicyBanner from '../../organization-privacy-policy/privacy-policy-banner.component';
import { WinExpirationBanner } from 'features/wins-expiration/components/win-expiration-banner.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';

const ResponsiveAppBarContainer: React.FC = () => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();

  if (isDesktopView) {
    return (
      <Stack sx={{ backgroundColor: palette.Background }} minHeight="100vh">
        <DesktopApplicationBar />
        <PrivacyPolicyBanner />
        <FeatureGuard feature={FeaturesEnum.WINS}>
          <WinExpirationBanner />
        </FeatureGuard>
        <Stack maxWidth="lg" alignSelf="center" width="100%">
          <Outlet></Outlet>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ backgroundColor: palette.Background }} minHeight="100vh">
      <MobileApplicationBar />
      <PrivacyPolicyBanner />
      <FeatureGuard feature={FeaturesEnum.WINS}>
        <WinExpirationBanner />
      </FeatureGuard>
      <MobileChildContainer>
        <Outlet></Outlet>
      </MobileChildContainer>
      <MobileBottomNavBar />
    </Stack>
  );
};

export default ResponsiveAppBarContainer;
