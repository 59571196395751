import React from 'react';

import { Stack } from 'common/components/material';
import Card, { CardContent } from 'common/components/material/Card';

import SocialContainer from '../Social/SocialContainer';
import FeedCardFooter from './FeedCardFooter';
import FeedCardHeader from './FeedCardHeader';
import { FeedCardProps } from 'features/feeds';
import { CommentableEnum } from 'features/comments';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';

const FeedCard: React.FC<FeedCardProps> = ({
  headerUser,
  footerUser,
  headerContent,
  feedType,
  timestamp,
  editedTimestamp,
  feedId,
  commentableType = CommentableEnum.FEED,
  totalComment = 0,
  totalReaction = 0,
  recentComment,
  isReactedByLoggedInUser = false,
  displaySocialContent = false,
  hoverShadow,
  raiseOnHover = false,
  headerAction,
  headerOptions,
  onClick,
  children,
  hideEditedMarker,
  hideTopDivider,
  fullWidthOnMobile = true,
  showCommentsInFeedPage = false,
  sx,
  publishedBy,
  visibleTo,
}) => {
  return (
    <Card
      noElevation={true}
      sx={{ cursor: onClick ? 'pointer' : 'default', overflow: 'visible', ...sx }}
      className={fullWidthOnMobile ? 'full-width' : ''}
      hoverShadow={hoverShadow}
      raiseOnHover={raiseOnHover}
      onClick={onClick}
    >
      <CardContent data-analytics-tag="feed_item" id={feedId}>
        <Stack gap={1}>
          <FeedCardHeader
            user={headerUser}
            headerContent={headerContent}
            timestamp={timestamp}
            editedTimestamp={editedTimestamp}
            feedId={feedId!}
            feedType={feedType}
            headerAction={headerAction}
            headerOptions={headerOptions}
            hideEditedMarker={hideEditedMarker}
            hideTopDivider={hideTopDivider}
            visibleTo={visibleTo}
            publishedBy={publishedBy}
          />
          {children}
          {footerUser && <FeedCardFooter user={footerUser} feedType={feedType} />}
          {feedId && displaySocialContent && (
            <FeatureGuard feature={FeaturesEnum.COMMENTS_AND_REACTIONS}>
              <SocialContainer
                entityId={feedId}
                commentableType={commentableType}
                totalComment={totalComment}
                totalReaction={totalReaction}
                comment={recentComment}
                isReactedByLoggedInUser={isReactedByLoggedInUser}
                showCommentsInFeedPage={showCommentsInFeedPage}
              />
            </FeatureGuard>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FeedCard;
