import React from 'react';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { ThreeDotMenu } from 'common/components/core';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import { useDrawer } from 'common/hooks';
import { PraiseCardOptionsProps, useDeletePraise } from 'features/praise';
import { DEFAULT_FEED_CARD_OPTIONS } from 'features/feeds';

const PraiseCardOptions: React.FC<PraiseCardOptionsProps> = ({ praise }) => {
  const { openDrawer, closeDrawer } = useDrawer();
  const { mutate: deletePraise, isLoading } = useDeletePraise();

  const openPraiseDrawer = () => {
    openDrawer(DrawerIdEnum.PRAISE, {
      praise,
    });
  };

  const openDeleteConfirmation = () => {
    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      headerTitle: 'Delete Praise?',
      subTitle: "This will be removed from both the receiver's profile and your profile",
      confirmButtonText: 'Delete',
      confirmButtonHandler: () => {
        deletePraise(praise);
        closeDrawer(DrawerIdEnum.LEAVE_FORM);
      },
    });
  };

  const handleSelect = (item: FloatingMenuItem) => {
    switch (item.value) {
      case 'edit':
        openPraiseDrawer();
        break;
      case 'delete':
        openDeleteConfirmation();
        break;
    }
  };

  return (
    <ThreeDotMenu
      data-role="praise-feed-card-options"
      items={DEFAULT_FEED_CARD_OPTIONS}
      disabled={isLoading}
      onSelect={handleSelect}
    />
  );
};

export default PraiseCardOptions;
