import axios from 'common/utils/axios';
import { PriorExperience } from './types/prior-experience.type';
import {
  CreatePriorExperienceRequest,
  UpdatePriorExperienceRequest,
} from './types/prior-experience-server-request.type';

const priorExperienceApi = axios('prior-experiences');

export const createPriorExperienceApi = ({
  profileId,
  ...data
}: CreatePriorExperienceRequest) => {
  return priorExperienceApi.post('', { profileId, ...data });
};

export const updatePriorExperienceApi = ({ id, ...data }: UpdatePriorExperienceRequest) => {
  return priorExperienceApi.put(id, data);
};

export const deletePriorExperienceApi = ({ id }: PriorExperience) => {
  return priorExperienceApi.delete(id);
};

export const getPriorExperienceApi = (profileId: string) => {
  return priorExperienceApi.get('', { params: { profileId } });
};
