import React from 'react';
import { useFormContext } from 'react-hook-form';

import { StepContainer } from 'common/components/container';
import { StaticDataSelector } from 'common/components/input';
import { StaticDataTypeEnum } from 'features/static-data';

export const OrganizationRoleJobFunctionStep: React.FC = () => {
  const { getValues } = useFormContext();
  return (
    <StepContainer
      heading={<>"Let's get started. What is your job function?"</>}
      subheading="This is used to show you the most relevant content in ConnectX"
      minHeight={{ lg: '26rem' }}
    >
      <StaticDataSelector
        name={'jobFunction'}
        label="Job function"
        value={getValues('jobFunction')}
        type={StaticDataTypeEnum.JOB_TITLES}
        formFieldProps={{
          autoFocus: true,
          required: true,
        }}
        helperText="Select the option that best describes your role. You’ll be able to customize this later."
      />
    </StepContainer>
  );
};
