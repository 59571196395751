import { pick, get, compact, values, map } from 'lodash';
import { CreateProfileForm } from '../types';
import { Skill } from 'features/skills';
import { OrgStaticData } from 'features/static-data';

const PROFILE_PROPS = ['firstName', 'lastName', 'endorsements', 'aspirations'];
const COMPANY_PROFILE_PROPS = ['visibleEmail', 'companyId'];
const ORGANIZATION_ROLE_PROPS = [
  'jobFunction',
  'jobRole',
  'jobDescription',
  'skills',
  'startMonth',
  'jobTitle',
  'startYear',
  'department',
  'division',
  'products',
  'location',
];
export const WIN_PROPS = ['win1', 'win2', 'win3'];

export const transformCreateProfileData = (profile: CreateProfileForm) => {
  const profileData = pick(profile, PROFILE_PROPS);
  const companyProfileData = pick(profile, COMPANY_PROFILE_PROPS);
  const organizationRoleData = pick(profile, ORGANIZATION_ROLE_PROPS);
  const latestOrganizationRoleData = pick(
    profile,
    map(ORGANIZATION_ROLE_PROPS, (field) => 'latest' + field),
  );
  const winData = pick(profile, WIN_PROPS);

  const hasNewRole = get(profile, 'hasNewRole', false);

  return {
    ...profileData,
    id: profile.profileId,
    endorsements: (profile.endorsements || []).map((skill: Skill) => skill.id),
    aspirations: (profile.aspirations || []).map((skill: Skill) => skill.id),
    companyProfile: {
      ...companyProfileData,
      id: profile.organizationProfileId,
      organizationRole: {
        id: profile.organizationRoleId,
        ...organizationRoleData,
        jobFunctionId: organizationRoleData?.jobFunction?.id,
        skillIds: organizationRoleData?.skills?.map((skill: Skill) => skill.id),
        departmentId: get(organizationRoleData, 'department.id', null),
        divisionId: get(organizationRoleData, 'division.id', null),
        productIds: organizationRoleData.products?.map(
          (product: OrgStaticData) => product.id,
        ),
        isCurrentRole: !hasNewRole,
      },
      latestOrganizationRole: hasNewRole
        ? {
            jobDescription: latestOrganizationRoleData.latestjobDescription,
            startMonth: latestOrganizationRoleData.lateststartMonth,
            jobTitle: latestOrganizationRoleData.latestjobTitle,
            startYear: latestOrganizationRoleData.lateststartYear,
            location: latestOrganizationRoleData.latestlocation,
            jobFunctionId: organizationRoleData?.jobFunction?.id,
            skillIds: latestOrganizationRoleData?.latestskills?.map(
              (skill: Skill) => skill.id,
            ),
            departmentId: get(latestOrganizationRoleData, 'latestdepartment.id', null),
            divisionId: get(latestOrganizationRoleData, 'latestdivision.id', null),
            productId: get(latestOrganizationRoleData, 'latestproduct.id', null),
            productIds: latestOrganizationRoleData?.latestproducts?.map(
              (product: OrgStaticData) => product.id,
            ),
            isCurrentRole: true,
          }
        : null,
      wins: compact(values(winData)),
    },
  };
};
