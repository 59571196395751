import { useState } from 'react';

import { HomeIcon, ProfileIcon, OrgChartIcon } from 'common/components/image';
import { Paper } from 'common/components/material';
import BottomNavigation, {
  BottomNavigationAction,
} from 'common/components/material/BottomNavigation';
import { NavigationTabEnum } from 'common/enum/NavigationTab.enum';
import { useRouter, useVirtualKeyboardVisibility, useSession } from 'common/hooks';
import useSetActiveNavigationTab, {
  NavigationState,
} from 'common/hooks/useSetActiveNavigationTab';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import GlobalCreateButton from 'features/org-root/components/GlobalCreateButton';
import NotificationIcon from './Notifications/NotificationIcon';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useGetL0ManagerId } from 'features/profiles/hooks/useGetL0ManagerId';

const CREATE_BUTTON_VALUE = 'CREATE';

const MobileBottomNavBar = () => {
  const { goTo, goHome } = useRouter();
  const { profile, organizationProfile } = useSession();

  const isGuestProfile = !!organizationProfile?.isGuestProfile;

  const { data: l0ManagerId } = useGetL0ManagerId({
    enabled: isGuestProfile,
  });

  const [activeTab, setActiveTab] = useState<NavigationState>({});
  useSetActiveNavigationTab(setActiveTab);

  const isVirtualKeyboardOpen = useVirtualKeyboardVisibility();

  const goToHome = () => {
    goHome({}, { state: { resetHistoryIndex: true } });
  };

  const goToOrgChart = () => {
    setActiveTab((navigationState: NavigationState) => ({
      currentTab: NavigationTabEnum.ORG_CHART,
      previousTab: navigationState.currentTab,
    }));
    goTo(
      AppRoutesEnum.ORG_CHART_PROFILE,
      {
        id: isGuestProfile ? l0ManagerId : profile.id,
      },
      { state: { resetHistoryIndex: true } },
    );
  };

  const goToProfile = () => {
    goTo(
      AppRoutesEnum.PROFILE,
      { profileId: profile.id },
      { state: { resetHistoryIndex: true } },
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex: 100,
        position: isVirtualKeyboardOpen ? 'relative' : 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: AppBoxShadows.shadow8,
      }}
    >
      <BottomNavigation
        showLabels
        value={activeTab.currentTab}
        onChange={(event, newTab) => {
          if (newTab === CREATE_BUTTON_VALUE || NavigationTabEnum.ORG_CHART) return;
          event.stopPropagation();
          setActiveTab((navigationState: NavigationState) => {
            return {
              currentTab: newTab,
              previousTab: navigationState.currentTab,
            };
          });
        }}
      >
        <BottomNavigationAction
          value={NavigationTabEnum.HOME}
          onClick={goToHome}
          icon={<HomeIcon isActive={activeTab.currentTab === NavigationTabEnum.HOME} />}
        />
        <FeatureGuard feature={FeaturesEnum.ORG_CHART}>
          <BottomNavigationAction
            value={NavigationTabEnum.ORG_CHART}
            onClick={goToOrgChart}
            icon={
              <OrgChartIcon isActive={activeTab.currentTab === NavigationTabEnum.ORG_CHART} />
            }
          />
        </FeatureGuard>
        <GlobalCreateButton />
        <BottomNavigationAction
          value={NavigationTabEnum.NOTIFICATIONS}
          icon={<NotificationIcon />}
        />
        <FeatureGuard feature={FeaturesEnum.OWN_PROFILE}>
          <BottomNavigationAction
            value={NavigationTabEnum.PROFILE}
            onClick={goToProfile}
            icon={
              <ProfileIcon
                photo={profile?.photo}
                isActive={activeTab.currentTab === NavigationTabEnum.PROFILE}
              />
            }
          />
        </FeatureGuard>
      </BottomNavigation>
    </Paper>
  );
};

export default MobileBottomNavBar;
